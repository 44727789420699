import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CategoriesState {
	addData: {
		template_id: number | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	editData: {
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	deleteData: {
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	getAllData: {
		rows: object | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	getData: {
		template_id: number | null
		name: string
		body: string
		type: number | null
		async: number | null
		active: number | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}
}

const initialState: CategoriesState = {
	addData: {
		template_id: null,
		isLoading: false,
		success: false,
		error: false,
	},

	editData: {
		success: false,
		isLoading: false,
		error: false,
	},

	deleteData: {
		success: false,
		isLoading: false,
		error: false,
	},

	getData: {
		template_id: null,
		name: '',
		body: '',
		type: null,
		async: null,
		active: null,
		isLoading: false,
		success: false,
		error: false,
	},

	getAllData: {
		rows: null,
		success: false,
		isLoading: false,
		error: false,
	},
}

export const reducer = createSlice({
	name: 'templates',
	initialState,
	reducers: {
		// Add template
		templateAddStart: (state): CategoriesState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		templateAddSuccess: (
			state,
			action: PayloadAction<any>
		): CategoriesState => ({
			...state,
			addData: {
				...state.addData,
				template_id: action.payload.template_id,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		templateAddFailure: (
			state,
			action: PayloadAction<string>
		): CategoriesState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Edit template
		templateEditStart: (state): CategoriesState => ({
			...state,
			editData: {
				...state.editData,
				success: false,
				error: false,
				isLoading: true,
			},
		}),
		templateEditSuccess: (
			state,
			action: PayloadAction<void>
		): CategoriesState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		templateEditFailure: (
			state,
			action: PayloadAction<string>
		): CategoriesState => ({
			...state,
			editData: {
				...state.editData,
				success: false,
				isLoading: false,
				error: action.payload,
			},
		}),

		// Delete template
		templateDeleteStart: (state): CategoriesState => ({
			...state,
			deleteData: {
				...state.deleteData,
				success: false,
				error: false,
				isLoading: true,
			},
		}),
		templateDeleteSuccess: (
			state,
			action: PayloadAction<void>
		): CategoriesState => ({
			...state,
			deleteData: {
				...state.deleteData,
				success: true,
				error: false,
				isLoading: false,
			},
		}),
		templateDeleteFailure: (
			state,
			action: PayloadAction<string>
		): CategoriesState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Get all templates
		templateGetAllStart: (state): CategoriesState => ({
			...state,
			getAllData: {
				...state.getAllData,
				success: false,
				error: false,
				isLoading: true,
			},
		}),

		templateGetAllSuccess: (
			state,
			action: PayloadAction<any>
		): CategoriesState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: true,
				error: false,
				rows: action.payload.result.rows,
			},
		}),

		templateGetAllFailure: (
			state,
			action: PayloadAction<string>
		): CategoriesState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		templateGetStart: (state): CategoriesState => ({
			...state,
			getData: {
				...state.getData,
				success: false,
				error: false,
				isLoading: true,
			},
		}),
		templateGetSuccess: (
			state,
			action: PayloadAction<any>
		): CategoriesState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: true,
				error: false,
				...action.payload.result.rows,
			},
		}),
		templateGetFailure: (
			state,
			action: PayloadAction<string>
		): CategoriesState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),
	},
})

export const {
	templateAddStart,
	templateAddSuccess,
	templateAddFailure,
	templateGetAllStart,
	templateGetAllSuccess,
	templateGetAllFailure,
	templateEditStart,
	templateEditSuccess,
	templateEditFailure,
	templateDeleteStart,
	templateDeleteSuccess,
	templateDeleteFailure,
	templateGetStart,
	templateGetSuccess,
	templateGetFailure,
} = reducer.actions

export default reducer.reducer
