import React from 'react'

import { Button } from '@mui/joy'
import { CopyAllRounded } from '@mui/icons-material'

const Duplicate = ({ opacity, duplicate, content_id }) =>
	duplicate &&
	typeof duplicate === 'function' && (
		<Button
			variant='plain'
			color='neutral'
			size='sm'
			sx={{ mr: '-4px', p: '3px', borderRadius: 0 }}
			onClick={e => {
				duplicate(content_id)
			}}
		>
			<CopyAllRounded
				sx={{
					mx: '6px',
					fontSize: '15px',
					opacity: opacity,
				}}
			/>
		</Button>
	)

export default Duplicate
