import React, { createContext, useContext, useState } from 'react'
export const ReportContext = createContext()

export function ReportProvider({ children }) {
	const [report, setReport] = useState({})

	const updateReport = (key, newValue) => {
		setReport(current => ({ ...current, [key]: newValue }))
	}

	const deleteOption = ({ name, key, value }) => {
		const current = report[name].options[key]

		let newValue = current

		if (Array.isArray(current)) {
			newValue = current
				.map(item => {
					if (item.value !== value.value) {
						return item
					}
					return false
				})
				.filter(i => !!i)
		} else {
			if (key.toString().toLowerCase() === value.toString().toLowerCase()) {
				newValue = ''
			}
		}

		replaceOption({ name, key, value: newValue })
	}

	const replaceOption = ({ name, key, value = false }) => {
		const newValue = typeof key === 'object' ? { ...key } : { [key]: value }

		updateReport(name, {
			...report[name],
			...{
				options: {
					...report[name].options,
					...newValue,
				},
			},
		})
	}

	const replaceReport = newValue => {
		setReport(newValue)
	}

	return (
		<ReportContext.Provider
			value={{
				report,
				updateReport,
				replaceReport,
				replaceOption,
				deleteOption,
			}}
		>
			{children}
		</ReportContext.Provider>
	)
}

export function useReport() {
	const context = useContext(ReportContext)
	if (!context) {
		throw new Error('useReport must be used within a Report')
	}
	return context
}
