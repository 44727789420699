import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	IZonesAddRequest,
	IZonesAddResponse,
	IZonesPricesAddRequest,
	IZonesPricesAddResponse,
	IZonesTrackerAddRequest,
	IZonesTrackerAddResponse,
} from './types'

export const create = (
	params: IZonesAddRequest
): AxiosPromise<IZonesAddResponse> =>
	axiosInstance.post(Endpoints.ZONES.ADD, params)

export const pricesCreate = (
	params: IZonesPricesAddRequest
): AxiosPromise<IZonesPricesAddResponse> =>
	axiosInstance.post(Endpoints.ZONES.PRICES_ADD, params)

export const trackerCreate = (
	params: IZonesTrackerAddRequest
): AxiosPromise<IZonesTrackerAddResponse> =>
	axiosInstance.post(Endpoints.ZONES.TRACKER_ADD, params)
