import * as React from 'react'

import {
	WebRounded,
	TranslateRounded,
	ResetTvRounded,
	DevicesOtherRounded,
	ImportantDevicesRounded,
	ShareRounded,
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { Accord, AccordItem } from '../../Accord'

import Browser from './Browser'
import OperatingSystem from './OperatingSystem'
import Language from './Language'
import DeviceFormat from './DeviceFormat'
import DeviceVendor from './DeviceVendor'
import Referer from './Referer'

const UserAgent = () => {
	const { t } = useTranslation()

	return (
		<>
			<Accord
				items={
					<>
						<AccordItem
							text={t('target_1_browser')}
							icon={<WebRounded />}
							items={<Browser />}
						/>
						<AccordItem
							text={t('target_1_os')}
							icon={<ResetTvRounded />}
							items={<OperatingSystem />}
						/>
						<AccordItem
							text={t('target_1_browser_lang')}
							icon={<TranslateRounded />}
							items={<Language />}
						/>
						<AccordItem
							text={t('target_1_device')}
							icon={<DevicesOtherRounded />}
							items={<DeviceFormat />}
						/>
						<AccordItem
							text={t('target_1_device_vendor')}
							icon={<ImportantDevicesRounded />}
							items={<DeviceVendor />}
						/>
						<AccordItem
							text={t('target_1_referer')}
							icon={<ShareRounded />}
							// last={1}
							items={<Referer />}
						/>
					</>
				}
			/>
		</>
	)
}

export default UserAgent
