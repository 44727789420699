import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import {
	IZonesAddRequest,
	IZonesPricesAddRequest,
	IZonesTrackerAddRequest,
} from '../../api/zones/add/types'

import {
	IZonesRemoveRequest,
	IZonesPricesRemoveRequest,
	IZonesTrackerRemoveRequest,
} from '../../api/zones/delete/types'

import {
	IZonesGetAllRequest,
	IZonesGetRequest,
	IZonesPricesGetAllRequest,
	IZonesTrackerGetAllRequest,
} from '../../api/zones/get/types'

import {
	IZonesSetActiveRequest,
	IZonesEditRequest,
} from '../../api/zones/edit/types'

import {
	zoneAddStart,
	zoneAddSuccess,
	zoneAddFailure,
	zoneEditStart,
	zoneEditSuccess,
	zoneEditFailure,
	zoneDeleteStart,
	zoneDeleteSuccess,
	zoneDeleteFailure,
	zoneGetAllSuccess,
	zoneGetAllFailure,
	zoneGetAllStart,
	zoneGetStart,
	zoneGetSuccess,
	zoneGetFailure,
	zoneSetActiveStart,
	zoneSetActiveSuccess,
	zoneSetActiveFailure,
	zonePricesAddStart,
	zonePricesAddSuccess,
	zonePricesAddFailure,
	zonePricesDeleteStart,
	zonePricesDeleteSuccess,
	zonePricesDeleteFailure,
	zonePricesGetAllStart,
	zonePricesGetAllSuccess,
	zonePricesGetAllFailure,
	zoneTrackerAddStart,
	zoneTrackerAddSuccess,
	zoneTrackerAddFailure,
	zoneTrackerDeleteStart,
	zoneTrackerDeleteSuccess,
	zoneTrackerDeleteFailure,
	zoneTrackerGetAllStart,
	zoneTrackerGetAllSuccess,
	zoneTrackerGetAllFailure,
} from './reducer'

export const zoneAdd =
	(data: IZonesAddRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(zoneAddStart())
			const result = await api.zonesAdd.create(data)
			dispatch(zoneAddSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(zoneAddFailure(e.message))
			return false
		}
	}

export const zoneDel =
	(data: IZonesRemoveRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(zoneDeleteStart())
			await api.zonesDel.remove(data)
			dispatch(zoneDeleteSuccess())
			return true
		} catch (e: any) {
			dispatch(zoneDeleteFailure(e.message))
			return false
		}
	}

export const zoneEdit =
	(data: IZonesEditRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(zoneEditStart())
			await api.zonesEdit.update(data)
			dispatch(zoneEditSuccess())
			return true
		} catch (e: any) {
			dispatch(zoneEditFailure(e.message))
			return false
		}
	}

export const zoneGet =
	(data: IZonesGetRequest) =>
	async (dispatch: Dispatch<any>): Promise<any> => {
		try {
			dispatch(zoneGetStart())
			const result = await api.zonesGet.get(data)
			dispatch(zoneGetSuccess({ result: result.data }))
			return result.data
		} catch (e: any) {
			dispatch(zoneGetFailure(e.message))
			return false
		}
	}

export const zoneGetAll =
	(data: IZonesGetAllRequest) =>
	async (dispatch: Dispatch<any>): Promise<Object | boolean> => {
		try {
			dispatch(zoneGetAllStart())
			const result = await api.zonesGet.getAll(data)
			dispatch(zoneGetAllSuccess({ result: result.data }))
			return result
		} catch (e: any) {
			dispatch(zoneGetAllFailure(e.message))
			return false
		}
	}

export const setActive =
	(data: IZonesSetActiveRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(zoneSetActiveStart())
			await api.zonesEdit.setActive(data)
			dispatch(zoneSetActiveSuccess())
			return true
		} catch (e: any) {
			dispatch(zoneSetActiveFailure(e.message))
			return false
		}
	}

export const zonePricesAdd =
	(data: IZonesPricesAddRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(zonePricesAddStart())
			const result = await api.zonesAdd.pricesCreate(data)
			dispatch(zonePricesAddSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(zonePricesAddFailure(e.message))
			return false
		}
	}

export const zonePricesDel =
	(data: IZonesPricesRemoveRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(zonePricesDeleteStart())
			await api.zonesDel.pricesRemove(data)
			dispatch(zonePricesDeleteSuccess())
			return true
		} catch (e: any) {
			dispatch(zonePricesDeleteFailure(e.message))
			return false
		}
	}

export const zonePricesGetAll =
	(data: IZonesPricesGetAllRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(zonePricesGetAllStart())
			const result = await api.zonesGet.pricesGetAll(data)
			dispatch(zonePricesGetAllSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(zonePricesGetAllFailure(e.message))
			return false
		}
	}

export const zoneTrackerAdd =
	(data: IZonesTrackerAddRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(zoneTrackerAddStart())
			const result = await api.zonesAdd.trackerCreate(data)
			dispatch(zoneTrackerAddSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(zoneTrackerAddFailure(e.message))
			return false
		}
	}

export const zoneTrackerDel =
	(data: IZonesTrackerRemoveRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(zoneTrackerDeleteStart())
			await api.zonesDel.trackerRemove(data)
			dispatch(zoneTrackerDeleteSuccess())
			return true
		} catch (e: any) {
			dispatch(zoneTrackerDeleteFailure(e.message))
			return false
		}
	}

export const zoneTrackerGetAll =
	(data: IZonesTrackerGetAllRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(zoneTrackerGetAllStart())
			const result = await api.zonesGet.trackerGetAll(data)
			dispatch(zoneTrackerGetAllSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(zoneTrackerGetAllFailure(e.message))
			return false
		}
	}
