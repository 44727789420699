import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	ICustomFieldsRemoveRequest,
	ICustomFieldsRemoveResponse,
	ICustomParamsRemoveRequest,
	ICustomParamsRemoveResponse,
} from './types'

export const removeField = (
	params: ICustomFieldsRemoveRequest
): AxiosPromise<ICustomFieldsRemoveResponse> =>
	axiosInstance.delete(Endpoints.CUSTOM.FIELDS_DELETE, { data: { ...params } })

export const removeParam = (
	params: ICustomParamsRemoveRequest
): AxiosPromise<ICustomParamsRemoveResponse> =>
	axiosInstance.delete(Endpoints.CUSTOM.PARAMS_DELETE, { data: { ...params } })
