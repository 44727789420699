import React from 'react'
import { Tabs, TabList, TabPanel, Divider } from '@mui/joy'

import { tabClasses } from '@mui/joy/Tab'
import { useTranslation } from 'react-i18next'
import Options from '../Options'
import CustomTab from './CustomTab'
import Report from '../Report'

const tabListStyles = {
	fontSize: 12,
	overflow: 'auto',
	scrollSnapType: 'x mandatory',
	'&::-webkit-scrollbar': { display: 'none' },
	[`&& .${tabClasses.root}`]: {
		bgcolor: 'transparent',
		'&:hover': { bgcolor: 'transparent' },
		[`&.${tabClasses.selected}`]: {
			color: 'primary.plainColor',
			'&::after': { height: '1px', bgcolor: 'primary.500' },
		},
	},
}

const tabPanelStyles = {
	overflowY: 'auto',
	p: 0,
	height: 'calc(calc(100vh - var(--Topbar-height)) - 40px)',
}

const FilterTabs = () => {
	const { t } = useTranslation()
	const [currentTabValue, setCurrentTabValue] = React.useState('options')
	const { Label, handleChangeTab, tabs } = CustomTab({ setCurrentTabValue })
	const text = t('sidebar_navigation', { returnObjects: true })

	return (
		<>
			<Tabs
				sx={{ backgroundColor: 'transparent' }}
				value={currentTabValue}
				onChange={(e, number) => {
					if (e.target.tagName !== 'svg') {
						handleChangeTab(number)
					}
				}}
			>
				<TabList underlinePlacement='bottom' sx={tabListStyles}>
					<Label name={text['filter']} value='options' />
					<Divider orientation='vertical' />
					{Object.entries(tabs).map(([key, value], index, array) => {
						const prevKey = index > 0 ? array[index - 1][0] : null
						const nextKey =
							index < array.length - 1 ? array[index + 1][0] : null

						return (
							<Label
								key={value[0].name}
								name={value[0].name}
								value={key}
								prevKey={prevKey}
								nextKey={nextKey}
							/>
						)
					})}
				</TabList>
				<TabPanel value='options' sx={tabPanelStyles}>
					<Options />
				</TabPanel>
				{Object.entries(tabs).map(([key, value]) => (
					<TabPanel value={key} key={`tab-${key}`} sx={{ p: 0 }}>
						<Report itemValue={value} itemKey={key} key={`report-${key}`} />
					</TabPanel>
				))}
			</Tabs>
		</>
	)
}

// export default React.memo(FilterTabs)
export default FilterTabs
