import React from 'react'

import { Input, Select, Space } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'

const Search = ({ setSearchCandidate }) => {
	const { t } = useTranslation()
	const [cookie, setCookie] = useCookies(['reload', 'advertisersListCategory'])
	const categories = useSelector(state => state.categories?.getAllData?.rows)
	const options = [{ label: '---', value: 0 }]

	const onChangeAdvertCategory = value => {
		setCookie('advertisersListCategory', value ? parseInt(value) : 0, {
			path: '/',
		})
	}
	const handleSearchChange = (event) => {
		setSearchCandidate(event.target.value);
	};

	categories &&
		Object.values(categories).forEach(selectItem => {
			options.push({
				label: selectItem.name,
				value: parseInt(selectItem.category_id),
			})
		})

	return (
		<Space.Compact style={{ padding: '10px', paddingTop: '10px' }}>
			<Input
				size={'small'}
				style={{ width: '60%' }}
				placeholder={t('search')}
				onChange={searchCandidate => {
					handleSearchChange(searchCandidate)
				}}
			/>
			<Select
				size={'small'}
				style={{ width: '40%' }}
				options={options}
				value={parseInt(cookie.advertisersListCategory)}
				onChange={val => {
					onChangeAdvertCategory(val)
					setCookie('reload', new Date().getTime(), { path: '/' })
				}}
			/>
		</Space.Compact>
	)
}

export default Search
