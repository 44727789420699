import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	IZonesRemoveRequest,
	IZonesRemoveResponse,
	IZonesPricesRemoveRequest,
	IZonesPricesRemoveResponse,
	IZonesTrackerRemoveRequest,
	IZonesTrackerRemoveResponse,
} from './types'

export const remove = (
	params: IZonesRemoveRequest
): AxiosPromise<IZonesRemoveResponse> =>
	axiosInstance.delete(Endpoints.ZONES.DELETE, { data: { ...params } })

export const pricesRemove = (
	params: IZonesPricesRemoveRequest
): AxiosPromise<IZonesPricesRemoveResponse> =>
	axiosInstance.delete(Endpoints.ZONES.PRICES_DELETE, { data: { ...params } })

export const trackerRemove = (
	params: IZonesTrackerRemoveRequest
): AxiosPromise<IZonesTrackerRemoveResponse> =>
	axiosInstance.delete(Endpoints.ZONES.TRACKER_DELETE, { data: { ...params } })
