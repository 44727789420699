import React, { useEffect, useState } from 'react'

import { Select } from 'antd'
import { useFilter } from '../../../../../hooks/filter'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../../../store'
import { customParamGetAll } from '../../../../../../store/custom'

const GroupBy = () => {
	const { t } = useTranslation()
	const groupByText = t('group_by', { returnObjects: true })
	const dispatch = useAppDispatch()
	const { filter, replaceItemFromFilter } = useFilter()
	const [groupBy, setGroupBy] = useState(filter.group_by || [])
	const role_id = useSelector(state => state.auth.profileData.profile?.role_id)
	const cp = useSelector(state => state.custom.customParamsGetAllData?.rows)

	useEffect(() => {
		if (!cp.length) {
			dispatch(customParamGetAll())
		}
	}, [dispatch, cp])

	const validGroup =
		role_id === 1
			? Object.keys(groupByText)
			: [
					'date',
					'advertiser',
					'campaign',
					'banner',
					'browser',
					'operating',
					'format',
					'vendor',
			  ]

	const options = Object.entries(groupByText)
		.map(([key, value]) =>
			validGroup.includes(key) ? { value: key, label: value } : null
		)
		.filter(i => !!i)

	cp &&
		cp.forEach(i => {
			options.push({ value: `CP${i.param_key}`, label: i.name })
		})

	return (
		<>
			<Select
				defaultValue={groupBy}
				maxTagCount={'responsive'}
				allowClear
				mode='multiple'
				style={{ flex: 1 }}
				loading={!options.length}
				options={options}
				onChange={newValue => {
					replaceItemFromFilter('group_by', newValue)
					setGroupBy(newValue)
				}}
			/>
		</>
	)
}

export default GroupBy
