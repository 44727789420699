import React from 'react'

import FormContainer from './FormContainer'

import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { toast } from '../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../store'
import { advertisersEdit } from '../../../../../store/advertisers'
import { useAdv } from '../../../../hooks/advertisers'

const Form = () => {
	const { t } = useTranslation()
	const { adv } = useAdv()
	const dispatch = useAppDispatch()
	const [, setCookie] = useCookies(['reload'])

	const handlerForm = async event => {
		event.preventDefault()
		dispatch(advertisersEdit(adv)).then(result => {
			result
				? toast.success(t('edit_advertiser_successfully'))
				: toast.error(t('error'))

			setCookie('reload', new Date().getTime(), { path: '/' })
		})
	}

	return (
		<form onSubmit={handlerForm}>
			<FormContainer />
		</form>
	)
}

export default Form
