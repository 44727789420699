import React from 'react'

import Sidebar from './Sidebar'
import Routers from './Routers'
import Splitter from '../../layout/Splitter'
import { AdvertiserProvider } from '../../hooks/advertisers'

const Advertisers = () => (
	<AdvertiserProvider>
		<Splitter>
			<Sidebar />
			<Routers />
		</Splitter>
	</AdvertiserProvider>
)

export default Advertisers
