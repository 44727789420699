import React from 'react'

import { Box, Tooltip, IconButton } from '@mui/joy'
import { HelpRounded } from '@mui/icons-material'
import { Chips } from '../../../handlers/Chips'
import { useTranslation } from 'react-i18next'
import { random } from './Modal/utils'

const CurrentTargetsTooltip = ({ item }) => {
	const { t } = useTranslation()

	const { locations, renderCategory, vpnTor, limitsViews, capping } = Chips({
		item,
	})

	const tooltipElements = [
		locations(),
		renderCategory('country'),
		renderCategory('ip_range'),
		renderCategory('isp'),
		vpnTor(),
		renderCategory('user_type'),
		renderCategory('connection_type'),
		renderCategory('browser'),
		renderCategory('language'),
		renderCategory('os'),
		renderCategory('device_format'),
		renderCategory('vendor'),
		renderCategory('referer'),
		renderCategory('custom'),
		renderCategory('date'),
		renderCategory('week'),
		limitsViews('impressions'),
		limitsViews('clicks'),
		limitsViews('budget'),
		renderCategory('zones'),
		capping(),
	]

	const elements = tooltipElements.filter(
		e => !!e && <React.Fragment key={random()}>{e}</React.Fragment>
	)
	const { length } = elements

	return (
		<Tooltip
			placement='top'
			variant='outlined'
			arrow
			title={
				<Box
					key={random()}
					sx={{
						display: 'flex',
						flexDirection: 'column',
						maxWidth: 750,
						mr: length ? '-7px' : '0',
						mt: length ? '-7px' : '-4px',
						justifyContent: 'center',
						p: 1,
					}}
				>
					<Box sx={{ display: 'flex', gap: 1, mt: 0.5, width: '100%' }}>
						<Box>{length ? elements : t('not_targets')}</Box>
					</Box>
				</Box>
			}
		>
			<IconButton
				size='sm'
				className={'case-add-btn'}
				variant='plain'
				sx={{
					minHeight: '25px',
					minWidth: '25px',
					paddingLeft: '1.5px',
					paddingRight: '1.5px',
					borderRadius: '50%',
				}}
			>
				<HelpRounded sx={{ fontSize: 15 }} />
			</IconButton>
		</Tooltip>
	)
}

export default CurrentTargetsTooltip
