import React, { useEffect, useState } from 'react'

import { Box, Sheet } from '@mui/joy'
import { Input, Select, Space } from 'antd'
import { renderChip } from '../../../../../handlers/Chips'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { updateObjToArrItem } from '../utils'
import { useAppDispatch } from '../../../../../../store'
import { customParamGetAll } from '../../../../../../store/custom'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const { Option } = Select

const CustomParams = ({ rotation, current }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const [condition, setCondition] = useState('=')
	const [customParamValue, setCustomParamValue] = useState('')
	const [customParamName, setCustomParamName] = useState('')
	const customParams = useSelector(
		state => state.custom.customParamsGetAllData?.rows
	)
	const targets = current.val.targets

	useEffect(() => {
		if (!customParams.length) dispatch(customParamGetAll())
	}, [customParams, dispatch])

	const arrayUniq = (def, arr1) => {
		const defaultMap = new Map(
			def.map(item => [`${item.name}-${item.condition}-${item.version}`, item])
		)

		const uniqueMap = new Map(
			arr1
				.map(item => [`${item.name}-${item.condition}-${item.version}`, item])
				.filter(item => !defaultMap.has(item[0]))
		)

		return Array.from(uniqueMap.values())
	}

	const addCustomParam = (
		paramName,
		paramValue,
		condition,
		targets,
		targetName
	) => {
		if (!paramName || !paramValue) return

		const newParam = { name: paramName, condition, value: paramValue }
		const existingParams =
			targets && targetName in targets ? targets[targetName] : []
		const value = arrayUniq(existingParams, [newParam])

		return updateObjToArrItem({
			rotation,
			current,
			value,
			target_name: targetName,
		})
	}

	const handleIncludeExclude = name => {
		addCustomParam(customParamName, customParamValue, condition, targets, name)
	}

	const customFields = (
		<Select
			key={'customFields'}
			style={{ minWidth: '100px' }}
			defaultValue={customParamName}
			onChange={setCustomParamName}
		>
			<Option value=''> --- </Option>
			{customParams &&
				customParams.map(e => (
					<Option key={e.param_id} value={e.param_key}>
						{e.name}
					</Option>
				))}
		</Select>
	)

	const conditions = (
		<Select
			key={'conditions'}
			style={{ minWidth: '120px' }}
			defaultValue={condition}
			onChange={setCondition}
			options={[
				{ label: t('referer_eq'), value: '=' },
				{ label: t('referer_contains'), value: '%' },
			]}
		/>
	)

	return (
		<>
			<Sheet
				sx={theme => ({
					mt: '0',
					borderRadius: '8px',
					p: 2,
					display: 'flex',
					[theme.getColorSchemeSelector('light')]: {
						backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
					},
					[theme.getColorSchemeSelector('dark')]: {
						backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
					},
				})}
			>
				<Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							flex: 1,
							gridGap: 10,
							alignItems: 'center',
						}}
					>
						<Space.Compact block>
							{customFields}
							{conditions}
							<Input
								onChange={e => setCustomParamValue(e.target.value)}
								placeholder={t('value')}
								defaultValue={customParamValue}
							/>
						</Space.Compact>
					</Box>

					<IncludeExcludeBtn
						include={() => {
							handleIncludeExclude('custom')
						}}
						exclude={() => {
							handleIncludeExclude('ex_custom')
						}}
					/>
				</Box>
			</Sheet>
			{renderChip({
				current: current,
				rotation: rotation,
				type: 'custom',
				label: i =>
					`${i.name} ${
						i.condition === '='
							? `${i.condition} '${i.value}'`
							: `ILIKE  '%${i.value}%'`
					}`,
				ex_label: i =>
					`${i.name} ${
						i.condition === '='
							? `!${i.condition} '${i.value}'`
							: `ILIKE  '%${i.value}%'`
					}`,
			})}
		</>
	)
}

export default CustomParams
