import React from 'react'

import Form from './Form'
import Submit from './Submit'

import { Box } from '@mui/joy'
import { toast } from '../../../layout/JoyToaster'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../store'
import { advertiserAdd } from '../../../../store/advertisers'
import { useCookies } from 'react-cookie'

const Add = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const defaultFormValues = {
		name: '',
		active: false,
		contact_name: '',
		email: '',
		description: '',
		category: 0,
	}

	const [, setCookie] = useCookies(['reload'])
	const [formValues, setFormValues] = React.useState(defaultFormValues)

	const handlerForm = event => {
		event.preventDefault()

		dispatch(advertiserAdd(formValues)).then(result => {
			result
				? toast.success(t('add_advertiser_added'))
				: toast.error(t('error'))
			setCookie('reload', new Date().getTime(), { path: '/' })
		})
	}

	return (
		<Box
			sx={{
				maxWidth: 'clamp(450px, 100dvw, 900px)',
			}}
		>
			<form onSubmit={handlerForm}>
				<Form values={{ set: setFormValues, arr: formValues }} />
				<Submit />
			</form>
		</Box>
	)
}

export default Add
