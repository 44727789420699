import React from 'react'
import {
	Select,
	Typography,
	Input,
	InputNumber,
	Space,
	Button,
	ConfigProvider,
	DatePicker,
} from 'antd'

import { useTranslation } from 'react-i18next'
import { useRetarget } from '../../../../../hooks/retarget'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'

import localeRu from 'antd/locale/ru_RU'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

const AndContainer = ({ itemKey, fieldKey }) => {
	const { t, i18n } = useTranslation()
	const { retarget, addNewAndItem, updateAndItem, removeAndItem } =
		useRetarget()

	const dateFormat = 'YYYY-MM-DD'

	const Text = ({ text }) =>
		fieldKey === 0 && (
			<Typography style={{ fontSize: 12, height: '20px' }} type='secondary'>
				{t(text)}
			</Typography>
		)

	const theme = {
		components: {
			Select: {
				optionFontSize: 12,
				optionHeight: 28,
			},
			Input: {
				inputFontSize: 12,
			},
		},
		token: {
			fontSize: 12,
		},
	}

	const CpSelect = () => {
		const list = Array(5)
			.fill(1)
			.map((_, key) =>
				key > 0
					? {
							label: `CP${key}`,
							value: `pr${key}`,
					  }
					: null
			)
			.filter(i => !!i)

		const handlerOnChange = newValue =>
			updateAndItem({
				itemKey,
				fieldKey,
				fieldName: 'pr',
				newValue,
			})

		return (
			<Space direction='vertical' style={{ flex: 1, gap: 0 }}>
				<Text text={'custom_field'} />
				<ConfigProvider theme={theme}>
					<Select
						size='small'
						options={list}
						defaultValue={retarget[itemKey].items[fieldKey].pr}
						onChange={handlerOnChange}
						style={{ display: 'flex', flex: 1, fontSize: 12 }}
					/>
				</ConfigProvider>
			</Space>
		)
	}

	const PeriodSelect = () => {
		const items = Array(21)
			.fill(1)
			.map((_, key) => ({
				label: t(`period_${key}`),
				value: key,
			}))
			.filter(i => !!i)

		const handlerOnChange = newValue => {
			updateAndItem({
				itemKey,
				fieldKey,
				fieldName: 'period',
				newValue,
			})
		}

		return (
			<Space direction='vertical' style={{ flex: 1, gap: 0 }}>
				<Text text={'capping_period'} />
				<ConfigProvider theme={theme}>
					<Select
						style={{ display: 'flex', flex: 1 }}
						size='small'
						maxTagCount={'responsive'}
						defaultValue={retarget[itemKey].items[fieldKey].period}
						options={items}
						onChange={handlerOnChange}
					/>
				</ConfigProvider>
			</Space>
		)
	}

	const ValueInput = () => {
		const handlerOnChange = ({ target: { value } }) =>
			updateAndItem({
				itemKey,
				fieldKey,
				fieldName: 'value',
				newValue: value,
			})

		return (
			<Space
				direction='vertical'
				style={{ flex: 1, gap: 0, marginRight: '-1px' }}
			>
				<ConfigProvider theme={theme}>
					<Text text={'value'} />
					<Input
						size='small'
						allowClear
						defaultValue={retarget[itemKey].items[fieldKey].value}
						style={{ display: 'flex', flex: 1 }}
						onChange={handlerOnChange}
					/>
				</ConfigProvider>
			</Space>
		)
	}

	const CounterInput = () => {
		const handlerOnChange = newValue =>
			updateAndItem({
				itemKey,
				fieldKey,
				fieldName: 'count',
				newValue,
			})

		const handlerOnChangeCondition = newValue =>
			updateAndItem({
				itemKey,
				fieldKey,
				fieldName: 'condition',
				newValue,
			})
		return (
			<Space direction='vertical' style={{ flex: 1, gap: 0 }}>
				<ConfigProvider
					theme={theme}
					style={{ display: 'flex', flexDirection: 'column' }}
				>
					<Text text={'count'} />
					<Space.Compact style={{ flex: 1, gap: 0, display: 'flex' }}>
						<Select
							size='small'
							defaultValue={retarget[itemKey].items[fieldKey].condition}
							options={[
								{ label: '=', value: '=' },
								{ label: '<', value: '<' },
								{ label: '>', value: '>' },
							]}
							onChange={handlerOnChangeCondition}
						/>
						<InputNumber
							min={0}
							size='small'
							defaultValue={retarget[itemKey].items[fieldKey].count}
							style={{ display: 'flex', flex: 1, width: '100%' }}
							onChange={handlerOnChange}
						/>
					</Space.Compact>
				</ConfigProvider>
			</Space>
		)
	}

	const FirstView = () => {
		return (
			<Space direction='vertical' style={{ flex: 1 }}>
				<ConfigProvider
					locale={i18n.language === 'ru' ? localeRu : {}}
					theme={theme}
				>
					<Text text={'retarget_first_view'} />

					<DatePicker
						size={'small'}
						defaultValue={[
							retarget[itemKey].items[fieldKey].first !== ''
								? dayjs(retarget[itemKey].items[fieldKey].first, dateFormat)
								: undefined,
						]}
						style={{ display: 'flex', flex: 1 }}
						format={dateFormat}
						onChange={(_, newValue) =>
							updateAndItem({
								itemKey,
								fieldKey,
								fieldName: 'first',
								newValue,
							})
						}
					/>
				</ConfigProvider>
			</Space>
		)
	}

	const LastView = () => {
		return (
			<Space direction='vertical' style={{ flex: 1 }}>
				<ConfigProvider
					locale={i18n.language === 'ru' ? localeRu : {}}
					theme={theme}
				>
					<Text text={'retarget_last_view'} />
					<DatePicker
						size={'small'}
						defaultValue={[
							retarget[itemKey].items[fieldKey].last !== ''
								? dayjs(retarget[itemKey].items[fieldKey].last, dateFormat)
								: undefined,
						]}
						style={{ display: 'flex', flex: 1 }}
						format={dateFormat}
						onChange={(_, newValue) =>
							updateAndItem({
								itemKey,
								fieldKey,
								fieldName: 'last',
								newValue,
							})
						}
					/>
				</ConfigProvider>
			</Space>
		)
	}

	const handlerOnRemove = () => {
		removeAndItem({ itemKey, fieldKey })
	}

	const handlerOnAdd = ({ fieldKey }) => {
		addNewAndItem({ itemKey, fieldKey })
	}

	return (
		<>
			<Space.Compact block>
				<CpSelect />

				<ValueInput />
				<CounterInput />

				<PeriodSelect />
				<FirstView />
				<LastView />

				<Space.Compact style={{ justifyContent: 'end', alignItems: 'end' }}>
					<Button
						size={'small'}
						onClick={handlerOnRemove}
						type={'primary'}
						danger
					>
						<DeleteOutlined />
					</Button>
					<Button size={'small'} onClick={handlerOnAdd} type={'primary'}>
						<PlusOutlined />
					</Button>
				</Space.Compact>
			</Space.Compact>
		</>
	)
}

export default AndContainer
