import React, { useState } from 'react'
import { Box } from '@mui/joy'
import { InputNumber } from 'antd'
import FormItem from '../../../../../layout/FormItem'

const LimitComponent = ({ rotation, current, name }) => {
	const handleChange = (val, key) => {
		const updateTargetType = (rotation, item_id, val, key) =>
			rotation.map(i => {
				if (i.item_id === item_id) i.limits[key] = parseInt(val, 10)
				if (i.items) i.items = updateTargetType(i.items, item_id, val, key)
				return i
			})

		updateTargetType(rotation.val, current.val.item_id, val, key)
		current.val.limits[key] = parseInt(val, 10)
		key === `${name}_total` ? setTotal(val) : setDaily(val)
	}

	const [total, setTotal] = useState(current.val.limits[`${name}_total`] || 0)
	const [daily, setDaily] = useState(current.val.limits[`${name}_daily`] || 0)

	return (
		<>
			<Box sx={{ my: 1, width: '70%' }}>
				<FormItem
					text={`${name}_total`}
					sx={{ maxWidth: '220px' }}
					component={
						<InputNumber
							style={{ width: '100px' }}
							value={total}
							onChange={value => handleChange(value, `${name}_total`)}
							min={0}
						/>
					}
				/>
				<FormItem
					text={`${name}_daily`}
					sx={{ maxWidth: '220px' }}
					component={
						<InputNumber
							style={{ width: '100px' }}
							value={daily}
							onChange={value => handleChange(value, `${name}_daily`)}
							min={0}
						/>
					}
				/>
			</Box>
		</>
	)
}

export default LimitComponent
