import React, { useState, useMemo } from 'react'

import { Input, Select, Button, Space } from 'antd'
import { DollarOutlined } from '@ant-design/icons'
import { PlusOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { toast } from '../../../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../../../store'
import { campaignPricesAdd } from '../../../../../../../store/campaigns'

import countryList from 'react-select-country-list'

const SearchAndAddPrice = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const advertiser_id = parseInt(useParams().pageId)
	const campaign_id = parseInt(useParams().campaignId)
	const [, setCookie] = useCookies(['reload'])
	const countries = useMemo(() => countryList().getData(), [])
	const [type, setType] = useState(1)
	const [price, setPrice] = useState(0)
	const [country, setCountry] = useState('')

	const handlerAddUser = () => {
		const params = {
			country,
			campaign_id,
			advertiser_id,
			pricing_type: type,
			price: parseFloat(price),
		}

		dispatch(campaignPricesAdd(params)).then(result => {
			if (result) {
				result
					? toast.success(t('add_campaign_prices_added'))
					: toast.error(t('error'))

				setCookie('reload', new Date().getTime(), { path: '/' })
			}
		})
	}

	return (
		<Space.Compact>
			<Input
				placeholder={t('price')}
				prefix={<DollarOutlined />}
				onChange={el => setPrice(el.target.value)}
			/>
			<Select
				style={{ minWidth: '220px' }}
				value={country}
				onChange={setCountry}
				options={[...[{ value: '', label: 'Global' }], ...countries]}
			/>
			<Select
				value={type}
				options={[
					{ label: 'CPM', value: 1 },
					{ label: 'CPC', value: 2 },
					{ label: 'CPA', value: 3 },
				]}
				onChange={setType}
			/>
			<Button onClick={handlerAddUser}>
				<PlusOutlined /> {t('user_sharing_setting_submit')}
			</Button>
		</Space.Compact>
	)
}

export default SearchAndAddPrice
