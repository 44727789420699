import React from 'react'
import { Box } from '@mui/joy'
import { NavLink } from 'react-router-dom'

function Main() {
	return (
		<Box
			component='main'
			className='MainContent'
			sx={{
				// p: { xs: 2, lg: 3 },
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
				minWidth: 0,
				minHeight: 'calc(100vh - var(--Topbar-height))',
				gap: 1,
				overflowY: 'auto',
			}}
		>
			<NavLink to={'/statistics/filter'}>filter</NavLink>
		</Box>
	)
}

export default Main
