import React from 'react'

import { Box } from '@mui/joy'
import { Button, Popconfirm } from 'antd'
import { DeleteRounded } from '@mui/icons-material'
import { toast } from '../../../../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../../../../store'
import { zoneTrackerDel } from '../../../../../../../../store/zones'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const events = require('../../../../../../../configs/events.json')

const Body = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const publisher_id = parseInt(useParams().pageId)
	const [, setCookie] = useCookies(['reload'])

	const deleteTracker = (deleteId, deleteTrackerType) => {
		if (deleteId) {
			dispatch(zoneTrackerDel({ tracking_id: deleteId, publisher_id })).then(
				result => {
					result
						? deleteTrackerType === 1
							? toast.success(t('delete_campaign_pixel_successfully'))
							: toast.success(t('delete_campaign_tracker_successfully'))
						: toast.error(t('error'))

					setCookie('reload', new Date().getTime(), { path: '/' })
				}
			)
		}
	}

	const rows = useSelector(state => state.zones.trackerGetAllData?.rows)
	const del = useSelector(state => state.zones.trackerGetAllData?.delete)

	return (
		<>
			<tbody>
				{rows &&
					rows.map(item => {
						return (
							<tr key={item.tracking_id} className='delete-container-retarget'>
								<td>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											gridGap: 5,
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											wordBreak: 'break-all',
										}}
									>
										{item.code}
									</Box>
								</td>
								<td>{item.type === 1 ? t('pixel') : t('tracker')}</td>
								<td>
									{item.event === 4
										? events.video[item.video_event]
										: events.types[item.event]}
								</td>
								<td style={{ paddingLeft: 0, paddingRight: 0 }}>
									{del && (
										<Popconfirm
											placement='topRight'
											style={{ maxWidth: '300px' }}
											title={t('delete_dialog_title')}
											description={
												item.type === 1
													? t('delete_dialog_pixel')
													: t('delete_dialog_tracker')
											}
											okText={t('delete_dialog_confirm')}
											cancelText={t('delete_dialog_cancel')}
											onConfirm={() => {
												deleteTracker(item.tracking_id, item.type)
											}}
										>
											<Button
												size='small'
												className='delete-btn-retarget'
												danger
												style={{ padding: 3 }}
											>
												<DeleteRounded
													style={{ fontSize: 16, color: '#dc4446' }}
												/>
											</Button>
										</Popconfirm>
									)}
								</td>
							</tr>
						)
					})}
			</tbody>
		</>
	)
}

export default Body
