import React from 'react'
import { Box, Typography } from '@mui/joy'
import { Pagination } from 'antd'

import { useTab } from '../../../../../../hooks/tab'
import { useReport } from '../../../../../../hooks/report'

import Settings from './Settings'
import DownloadButtons from './DownloadButtons'
import Search from './Search'
import Print from './Print'
import SaveReport from './SaveReport'
import Options from './Options'

const ControlBar = ({ name, tableRef }) => {
	const { tabs, currentTab } = useTab()
	const isSplit = (tabs[currentTab] && tabs[currentTab].length > 1) || 0

	const { report, replaceOption } = useReport()
	const { reports: current = [], options: { page = 0, onPage = 10 } = {} } =
		report[name] || {}

	const handleChangePage = (newPage, newValue) => {
		newValue = newValue ? newValue.toString() : onPage

		replaceOption({
			name,
			key: {
				page: newPage - 1,
				onPage: parseInt(newValue, 10),
				pages: Math.ceil(current.length / onPage),
			},
		})
	}

	return (
		<Box
			sx={{
				width: '100%',
				borderRadius: '8px',
				mb: 1,
				px: 2,
				display: 'flex',
				flexDirection: 'row',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-start',
					gap: 0.5,
					flex: 1,
				}}
			>
				<Search name={name} />
				<Settings name={name} />
				<DownloadButtons name={name} tableRef={tableRef} />
				<Print tableRef={tableRef} />
				<SaveReport tableRef={tableRef} />
				<Options name={name} />
			</Box>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: 0.5,
					justifyContent: 'flex-end',
				}}
			>
				<Box
					sx={{ display: 'flex', gap: isSplit ? 0.5 : 1, alignItems: 'center' }}
				>
					<Pagination
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							// fontSize: 12,
						}}
						className='custom-pagination-font-size'
						size={'small'}
						current={page + 1}
						onChange={handleChangePage}
						defaultPageSize={onPage}
						showSizeChanger={true}
						pageSize={onPage}
						pageSizeOptions={[5, 10, 30, 50, 100, 500, 1000]}
						total={current.length}
						showTotal={(total, range) => (
							<Typography
								level='body-xs'
								sx={{
									display: 'flex',
									height: 'inherit',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{range[0]}-{range[1]} of {total}
							</Typography>
						)}
					/>
				</Box>
				<Box />
			</Box>
		</Box>
	)
}

export default ControlBar
