import React from 'react'

import { Box } from '@mui/joy'
import { useParams } from 'react-router-dom'

import Add from './Add'
import Main from './Main'
import Edit from './Edit'

import ErrorPage from '../../ErrorPage'

const Routers = () => {
	const { event } = useParams()

	if (!event) {
		return <ErrorPage />
	}

	const pages = {
		add: Add,
		edit: Edit,
		main: Main,
	}

	const CurrentPage = pages[event] ?? pages.main

	return (
		<Box
			component='main'
			className='MainContent'
			sx={() => ({
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
				minWidth: 0,
				gap: 1,
				overflowY: 'auto',
			})}
		>
			<CurrentPage />
		</Box>
	)
}

export default Routers
