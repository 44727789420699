import React from 'react'

import { Button } from '@mui/joy'
import { NavLink } from 'react-router-dom'
import { Add } from '@mui/icons-material'

const AddButton = ({ opacity, addPath }) => {
	const btnSx = {
		mx: '6px',
		fontSize: '18px',
		opacity: opacity,
	}

	return (
		addPath && (
			<Button
				variant='plain'
				color='neutral'
				size='sm'
				sx={{ mr: '-4px', p: '2px', borderRadius: 0 }}
				component={NavLink}
				to={addPath}
			>
				<Add sx={btnSx} />
			</Button>
		)
	)
}

export default AddButton
