import React, { useState } from 'react'

import { useFilter } from '../../../../../hooks/filter'
import { useTranslation } from 'react-i18next'
import { ConfigProvider, DatePicker } from 'antd'
import localeRu from 'antd/locale/ru_RU'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
const { RangePicker } = DatePicker

const DateInputs = () => {
	const { i18n } = useTranslation()
	const { filter, updateFilter } = useFilter()

	const dateFormat = 'YYYY-MM-DD'

	const [dateFrom, setDateFrom] = useState(
		filter.date_from ? dayjs(filter.date_from, dateFormat) : dayjs()
	)
	const [dateTo, setDateTo] = useState(
		filter.date_to ? dayjs(filter.date_to, dateFormat) : dayjs()
	)

	const onDatesChange = (_, dateString) => {
		setDateFrom(dateString[0])
		setDateTo(dateString[1])
		updateFilter('date_from', dateString[0])
		updateFilter('date_to', dateString[1])
	}

	return (
		<>
			<ConfigProvider locale={i18n.language === 'ru' ? localeRu : {}}>
				<RangePicker
					style={{ flex: 1 }}
					maxDate={dayjs()}
					defaultValue={[dateFrom, dateTo]}
					format={dateFormat}
					onChange={onDatesChange}
				/>
			</ConfigProvider>
		</>
	)
}

export default DateInputs
