import React, { useState } from 'react'

import { List, Box } from '@mui/joy'
import { listItemButtonClasses } from '@mui/joy/ListItemButton'
import { useAppDispatch } from '../../../../../store'
import { setActive } from '../../../../../store/advertisers'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'


import Campaigns from './Campaigns'
import Container from './Container'

const Advertisers = ({ items, searchCandidate }) => {
	const dispatch = useAppDispatch()
	const [, setCookie] = useCookies(['reload'])
	const [isOpen, setIsOpen] = useState({})
	const { t } = useTranslation()
	const text = t('not_found_search')

	const filteredItems = items.filter(item =>
		item.name.toLowerCase().includes(searchCandidate.toLowerCase())
	);

	const listSx = () => ({
		'.advertiser_item_name': {
			'--ListItem-plainActiveBg': 'transparent',
			[`& .${listItemButtonClasses.root}`]: {
				borderLeftColor: 'transparent',
			},
		},
		[`& .${listItemButtonClasses.root}`]: {
			borderRadius: 'var(--Button-radius, var(--joy-radius-sm))',
		},
		[`& .${listItemButtonClasses.root}.active`]: {
			backgroundColor:
				'var(--variant-plainActiveBg, var(--joy-palette-neutral-plainActiveBg, var(--joy-palette-neutral-200, #DDE7EE)))',
		},
		'--ListItem-paddingLeft': '16px',
		'--ListItem-startActionWidth': '0px',
		'--ListItem-startActionTranslateX': '-50%',
	})

	const boxSx = () => ({
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	})

	const onSetActive = (id, active) => {
		dispatch(setActive({ active, advertiser_id: id })).then(result => {
			if (result) setCookie('reload', new Date().getTime(), { path: '/' })
		})
	}

	const cmpg = Campaigns()

	return (
		<List size='sm' sx={listSx}>
			{filteredItems.length === 0 && (
				<Box sx={boxSx}> {text}</Box>
			)}

			{filteredItems &&
				filteredItems.map(advert => {
					return (
						<Container
							key={advert.advertiser_id}
							title={advert.name}
							path={`/advertisers/edit/${advert.advertiser_id}`}
							addPath={`/advertisers/campaigns/add/${advert.advertiser_id}`}
							content_id={advert.advertiser_id}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							active={advert.active}
							activeBtnOnClick={onSetActive}
							templateItem={cmpg.campaignTemplate}
							getItems={cmpg.campaignGetter}
						/>
					)
				})}
		</List>
	)
}

export default Advertisers
