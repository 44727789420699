import * as React from 'react'
import { useTranslation } from 'react-i18next'

const Head = () => {
	const { t } = useTranslation()

	return (
		<thead>
			<tr>
				<th>{t('prices_country')}</th>
				<th style={{ width: '100px' }}>{t('prices_price')}</th>
				<th style={{ width: '120px' }}>{t('prices_price_type')}</th>
				<th style={{ width: '30px' }}></th>
			</tr>
		</thead>
	)
}

export default Head
