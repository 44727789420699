import React, { useState } from 'react'

import { UserOutlined, PlusOutlined } from '@ant-design/icons'
import { Space, Input, Select, Button } from 'antd'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { toast } from '../../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../../store'
import { publisherAddSharing } from '../../../../../../store/publishers'

const SearchAndCreateUser = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const pageId = parseInt(useParams().pageId)
	const [, setCookie] = useCookies(['reload'])
	const [user, setUser] = useState('')
	const [level, setLevel] = useState(2)

	const handlerAddUser = () => {
		dispatch(
			publisherAddSharing({
				email: user,
				level: parseInt(level),
				publisher_id: pageId,
			})
		).then(result => {
			result
				? toast.success(t('add_advertiser_sharing_added'))
				: toast.error(t('error'))

			setCookie('reload', new Date().getTime(), { path: '/' })
		})
	}

	return (
		<Space.Compact>
			<Input
				placeholder={t('user_sharing_setting_user')}
				onChange={el => setUser(el.target.value)}
				prefix={<UserOutlined />}
			/>
			<Select
				style={{ width: '210px' }}
				value={level}
				onChange={val => {
					setLevel(val)
				}}
				options={[
					{ value: 1, label: t('user_sharing_setting_is_owner') },
					{ value: 2, label: t('user_sharing_setting_can_edit') },
					{ value: 3, label: t('user_sharing_setting_can_view') },
				]}
			/>
			<Button onClick={handlerAddUser}>
				<PlusOutlined /> {t('user_sharing_setting_submit')}
			</Button>
		</Space.Compact>
	)
}

export default SearchAndCreateUser
