import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	IReTargetGetAllResponse,
	IReTargetGetPixelsRequest,
	IReTargetGetPixelsResponse,
} from './types'

export const getAll = (): AxiosPromise<IReTargetGetAllResponse> =>
	axiosInstance.get(`${Endpoints.RETARGET.GET_ALL}`)

export const pixels = (
	params: IReTargetGetPixelsRequest
): AxiosPromise<IReTargetGetPixelsResponse> =>
	axiosInstance.get(`${Endpoints.RETARGET.PIXELS}/${params.target_id}`)
