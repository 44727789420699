import React, { useEffect, useState } from 'react'
import {
	Box,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	CircularProgress,
} from '@mui/joy'
import { Input, Select, Switch } from 'antd'
import { Add, PermMediaRounded } from '@mui/icons-material'

import FormItem from '../../../../../layout/FormItem'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../../../store'
import { templateGetAll } from '../../../../../../store/templates'
import { useRtt } from '../../../../../hooks/rotations'

const Loader = () => (
	<Box
		sx={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			minHeight: '146px',
		}}
	>
		<CircularProgress size='sm' />
	</Box>
)

const Basic = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [index, setIndex] = useState(0)
	const { rtt, updateRotation } = useRtt()
	const { pageId } = useParams()
	const parsedPageId = parseInt(pageId, 10)

	const active = !!rtt ? parseInt(rtt.active) : 0
	const templateTypes = useSelector(state => state.templates.getAllData?.rows)

	useEffect(() => {
		if (!templateTypes) dispatch(templateGetAll())
	}, [dispatch, templateTypes])

	return (
		<Accordion
			sx={{ borderBottom: 0 }}
			expanded={index === 0}
			onChange={(_, expanded) => {
				setIndex(expanded ? 0 : null)
			}}
		>
			<AccordionSummary indicator={<Add />} onClick={e => e.preventDefault()}>
				<PermMediaRounded />
				<Box sx={{ flex: 1, fontSize: '13px' }}>
					{t('add_advertiser_basic')}
				</Box>
			</AccordionSummary>

			<AccordionDetails>
				{rtt.id === parsedPageId ? (
					<>
						<FormItem
							text={'name'}
							component={
								<Input
									style={{ width: '100%' }}
									value={rtt.name}
									max={180}
									onChange={e => {
										updateRotation('name', e.target.value)
									}}
								/>
							}
						/>

						<FormItem
							text={'add_templates'}
							component={
								<Select
									style={{ width: '100%' }}
									value={rtt.template}
									options={templateTypes}
									loading={!templateTypes}
									fieldNames={{ label: 'name', value: 'id' }}
									onChange={val => updateRotation('template', val)}
								/>
							}
						/>

						{[1, 0].includes(active) && (
							<FormItem
								text={'add_advertiser_basic_active'}
								component={
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Switch
											size='small'
											checked={active === 1}
											onChange={e => {
												updateRotation('active', e ? 1 : 0)
											}}
										/>
									</Box>
								}
							/>
						)}
					</>
				) : (
					<Loader />
				)}
			</AccordionDetails>
		</Accordion>
	)
}

export default Basic
