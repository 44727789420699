import React, { useEffect } from 'react'

import { useCookies } from 'react-cookie'
import { useAppDispatch } from '../../../../../store'
import { getAllUsers } from '../../../../../store/users'

import Container from './Container'

const Table = props => {
	const dispatch = useAppDispatch()
	const [cookie] = useCookies(['reload'])

	useEffect(() => {
		dispatch(
			getAllUsers({
				active: props.search.value.active,
				role_id: props.search.value.role_id,
				search: props.search.value.search,
			})
		)
	}, [props.search.value, cookie.reload, dispatch])

	return <Container />
}

export default Table
