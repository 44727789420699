import React from 'react'
import { useParams } from 'react-router-dom'
import Main from './Main'
import Filter from './Filter'

const Routers = () => {
	const { page } = useParams()

	const pages = {
		filter: Filter,
		main: Main,
	}

	const CurrentPage = pages[page] ?? pages.main
	return <CurrentPage />
}

export default Routers
