import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	IAdvertisersAddRequest,
	IAdvertisersAddResponse,
	IAdvertisersAddSharingRequest,
	IAdvertisersAddSharingResponse,
} from './types'

export const create = (
	params: IAdvertisersAddRequest
): AxiosPromise<IAdvertisersAddResponse> =>
	axiosInstance.post(Endpoints.ADVERTISERS.ADD, params)

export const sharing = (
	params: IAdvertisersAddSharingRequest
): AxiosPromise<IAdvertisersAddSharingResponse> =>
	axiosInstance.post(Endpoints.ADVERTISERS.SHARING_ADD, params)
