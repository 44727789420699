import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	IBannersRemoveRequest,
	IBannersRemoveResponse,
	IBannersTrackerRemoveRequest,
	IBannersTrackerRemoveResponse,
} from './types'

export const remove = (
	params: IBannersRemoveRequest
): AxiosPromise<IBannersRemoveResponse> =>
	axiosInstance.delete(Endpoints.BANNERS.DELETE, { data: { ...params } })

export const trackerRemove = (
	params: IBannersTrackerRemoveRequest
): AxiosPromise<IBannersTrackerRemoveResponse> =>
	axiosInstance.delete(Endpoints.BANNERS.TRACKER_DELETE, {
		data: { ...params },
	})
