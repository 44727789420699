import React, { useState } from 'react'

import { Box, Sheet } from '@mui/joy'
import { Select, Space } from 'antd'
import { renderChip } from '../../../../../handlers/Chips'
import { useTranslation } from 'react-i18next'
import { updateObjToArrItem } from '../utils'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const WeenAndTime = ({ rotation, current }) => {
	const { t } = useTranslation()

	const [week, setWeek] = useState(1)
	const [start, setStart] = useState('')
	const [end, setEnd] = useState('')
	const targets = current.val.targets

	const arrayUniq = arr1 => {
		const uniqueMap = new Map()

		arr1.forEach(item => {
			const key = `${item.week}-${item.start}-${item.end}`
			if (!uniqueMap.has(key)) {
				uniqueMap.set(key, item)
			}
		})

		return Array.from(uniqueMap.values())
	}

	const handleIncludeExclude = target_name => {
		const value = arrayUniq(
			targets && target_name in targets
				? [...targets[target_name], ...[{ week, start, end }]]
				: [...[{ week, start, end }]]
		)
		return updateObjToArrItem({ rotation, current, value, target_name })
	}

	return (
		<>
			<Sheet
				sx={theme => ({
					mt: '0',
					borderRadius: '8px',
					p: 2,
					mb: 2.5,
					display: 'flex',
					[theme.getColorSchemeSelector('light')]: {
						backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
					},
					[theme.getColorSchemeSelector('dark')]: {
						backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
					},
				})}
			>
				<Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							flex: 1,
							gridGap: 10,
							alignItems: 'center',
						}}
					>
						<Space.Compact style={{ width: '100%' }}>
							<Select
								style={{ width: '100%' }}
								placeholder={t('day_of_week')}
								defaultValue={week}
								onChange={setWeek}
								options={Array(8)
									.fill(0)
									.map((_, k) => k > 0 && { label: t(`week_${k}`), value: k })
									.filter(i => !!i)}
							/>
							<Select
								style={{ width: '100%' }}
								placeholder={t('from')}
								defaultValue={start}
								onChange={setStart}
								options={Array(24)
									.fill(0)
									.map((_, k) => ({
										label: `${k < 10 ? `0${k}` : k}:00`,
										value: `${k < 10 ? `0${k}` : k}:00`,
									}))
									.filter(i => !!i)}
							/>
							<Select
								style={{ width: '100%' }}
								placeholder={t('to')}
								defaultValue={end}
								onChange={setEnd}
								options={Array(24)
									.fill(0)
									.map((_, k) => ({
										label: `${k < 10 ? `0${k}` : k}:00`,
										value: `${k < 10 ? `0${k}` : k}:00`,
									}))
									.filter(i => !!i)}
							/>
						</Space.Compact>
					</Box>

					<IncludeExcludeBtn
						include={() => {
							handleIncludeExclude('week')
						}}
						exclude={() => {
							handleIncludeExclude('ex_week')
						}}
					/>
				</Box>
			</Sheet>
			{renderChip({
				current: current,
				rotation: rotation,
				type: 'week',
				label: i =>
					`${t(`week_${i.week}`)} ${
						i.start ? `, ${i.start}${i.end ? `-${i.end}` : ''}` : ''
					}`,
			})}
		</>
	)
}

export default WeenAndTime
