const Endpoints = {
	AUTH: {
		LOGIN: '/api/auth/login',
		REFRESH: '/api/auth/refresh',
		LOGOUT: '/api/auth/logout',
		PROFILE: '/api/auth/profile',
	},

	ADVERTISERS: {
		ADD: '/api/advertisers/add',
		EDIT: '/api/advertisers/edit',
		GET: '/api/advertisers/get',
		GET_ALL: '/api/advertisers/get/all',
		SET_ACTIVE: '/api/advertisers/active',
		DELETE: '/api/advertisers/remove',
		SHARING_ADD: '/api/advertisers/sharing/add',
		SHARING_GET: '/api/advertisers/sharing/get',
		SHARING_DEL: '/api/advertisers/sharing/remove',
	},

	CATEGORIES: {
		ADD: '/api/categories/add',
		EDIT: '/api/categories/edit',
		GET: '/api/categories/get',
		GET_ALL: '/api/categories/get/all',
		DELETE: '/api/categories/remove',
	},

	TEMPLATES: {
		ADD: '/api/templates/add',
		EDIT: '/api/templates/edit',
		GET: '/api/templates/get',
		GET_ALL: '/api/templates/get/all',
		DELETE: '/api/templates/remove',
	},

	ROTATIONS: {
		ADD: '/api/rotations/add',
		EDIT: '/api/rotations/edit',
		GET: '/api/rotations/get',
		GET_ALL: '/api/rotations/get/all',
		SET_ACTIVE: '/api/rotations/active',
		DELETE: '/api/rotations/remove',
		TARGET: '/api/rotations/target',
		DUPLICATE: '/api/rotations/duplicate',
	},

	RETARGET: {
		ADD: '/api/retarget/add',
		EDIT: '/api/retarget/edit',
		GET_ALL: '/api/retarget/get/all',
		DELETE: '/api/retarget/remove',
		PIXELS: '/api/retarget/pixels',
	},

	USERS: {
		ADD: '/api/users/add',
		EDIT: '/api/users/edit',
		GET: '/api/users/get',
		GET_ALL: '/api/users/get/all',
		SET_ACTIVE: '/api/users/active',
		DELETE: '/api/users/remove',
	},

	CAMPAIGNS: {
		ADD: '/api/campaigns/add',
		EDIT: '/api/campaigns/edit',
		GET: '/api/campaigns/get',
		GET_ALL: '/api/campaigns/get/all',
		SET_ACTIVE: '/api/campaigns/active',
		DELETE: '/api/campaigns/remove',
		PRICES_ADD: '/api/campaigns/prices/add',
		PRICES_DELETE: '/api/campaigns/prices/remove',
		PRICES_GET_ALL: '/api/campaigns/prices/get/all',
		TRACKER_ADD: '/api/campaigns/tracker/add',
		TRACKER_DELETE: '/api/campaigns/tracker/remove',
		TRACKER_GET_ALL: '/api/campaigns/tracker/get/all',
	},

	BANNERS: {
		ADD: '/api/banners/add',
		EDIT: '/api/banners/edit',
		GET: '/api/banners/get',
		GET_ALL: '/api/banners/get/all',
		SET_ACTIVE: '/api/banners/active',
		DELETE: '/api/banners/remove',
		TRACKER_ADD: '/api/banners/tracker/add',
		TRACKER_DELETE: '/api/banners/tracker/remove',
		TRACKER_GET_ALL: '/api/banners/tracker/get/all',
		CLOUD: '/api/banners/cloud',
		UPLOAD: '/api/banners/upload',
	},

	CUSTOM: {
		FIELDS_ADD: '/api/custom/fields/add',
		FIELDS_GET: '/api/custom/fields/get',
		FIELDS_EDIT: '/api/custom/fields/edit',
		FIELDS_DELETE: '/api/custom/fields/remove',
		FIELDS_GET_ALL: '/api/custom/fields/get/all',
		PARAMS_ADD: '/api/custom/params/add',
		PARAMS_GET: '/api/custom/params/get',
		PARAMS_EDIT: '/api/custom/params/edit',
		PARAMS_DELETE: '/api/custom/params/remove',
		PARAMS_GET_ALL: '/api/custom/params/get/all',
	},

	ZONES: {
		ADD: '/api/zones/add',
		EDIT: '/api/zones/edit',
		GET: '/api/zones/get',
		GET_ALL: '/api/zones/get/all',
		SET_ACTIVE: '/api/zones/active',
		DELETE: '/api/zones/remove',
		PRICES_ADD: '/api/zones/prices/add',
		PRICES_DELETE: '/api/zones/prices/remove',
		PRICES_GET_ALL: '/api/zones/prices/get/all',
		TRACKER_ADD: '/api/zones/tracker/add',
		TRACKER_DELETE: '/api/zones/tracker/remove',
		TRACKER_GET_ALL: '/api/zones/tracker/get/all',
	},

	PUBLISHERS: {
		ADD: '/api/publishers/add',
		EDIT: '/api/publishers/edit',
		GET: '/api/publishers/get',
		GET_ALL: '/api/publishers/get/all',
		SET_ACTIVE: '/api/publishers/active',
		DELETE: '/api/publishers/remove',

		SHARING_ADD: '/api/publishers/sharing/add',
		SHARING_GET: '/api/publishers/sharing/get',
		SHARING_DEL: '/api/publishers/sharing/remove',
	},

	STATISTIC: {
		ADD: '/api/statistic/add',
		GET: '/api/statistic/get',
		FILTER: '/api/statistic/filter',
	},
}

export default Endpoints
