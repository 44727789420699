import React from 'react'
import Input from '@mui/joy/Input'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'

import { useTranslation } from 'react-i18next'

const PasswordInput = () => {
	const { t } = useTranslation()

	return (
		<FormControl>
			<FormLabel>{t('password')}</FormLabel>

			<Input
				autoComplete='current-password'
				placeholder='•••••••'
				type='password'
				name='password'
			/>
		</FormControl>
	)
}

export default PasswordInput
