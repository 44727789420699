import * as React from 'react'

import {
	LocationOnRounded,
	ModeOfTravelRounded,
	SignalCellularAltRounded,
	RouterRounded,
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Accord, AccordItem } from '../../Accord'

import Isp from './Isp'
import IpRange from './IpRange'
import Locations from './Locations'
import ConnectionType from './ConnectionType'

const Ip = () => {
	const { t } = useTranslation()
	const role_id = useSelector(state => state.auth.profileData.profile?.role_id)

	return (
		<>
			<Accord
				items={
					<>
						{role_id === 1 && (
							<AccordItem
								text={t('target_1_location')}
								icon={<LocationOnRounded />}
								items={<Locations />}
							/>
						)}
						<AccordItem
							text={t('target_1_ip_range')}
							icon={<ModeOfTravelRounded />}
							items={<IpRange />}
						/>
						<AccordItem
							text={t('target_1_operator')}
							icon={<SignalCellularAltRounded />}
							items={<Isp />}
						/>
						<AccordItem
							text={t('target_1_connection_type')}
							icon={<RouterRounded />}
							last={1}
							items={<ConnectionType />}
						/>
					</>
				}
			/>
		</>
	)
}

export default Ip
