import React from 'react'

import Container from './Container'
import { Box, CircularProgress } from '@mui/joy'
import { useAppDispatch } from '../../../../../store'
import { setActive } from '../../../../../store/banners'

const Banners = ({ banners, getCampaigns }) => {
	const dispatch = useAppDispatch()

	const bannerGetter = (setter, campaign_id, advertiser_id) => {
		setter([{ advertiser_id, campaign_id }])
	}

	const onChangeActiveBanner = (banner_id, active, advertiser_id) => {
		dispatch(setActive({ active, advertiser_id, banner_id })).then(result => {
			if (result) {
				getCampaigns(advertiser_id)
			}
		})
	}

	const bannerTemplate = (adverts, campaign_id, advertiser_id) => {
		if (!banners[campaign_id]) {
			return (
				<Box
					sx={{
						display: 'flex',
						height: '30px',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress size='sm' />
				</Box>
			)
		}

		if (
			!(
				adverts[0].advertiser_id === advertiser_id &&
				adverts[0].campaign_id === campaign_id &&
				banners[campaign_id] &&
				!!banners[campaign_id].length
			)
		)
			return <></>

		return banners[campaign_id].map(
			item =>
				(
					<Container
						key={item.banner_id}
						title={item.name}
						path={`/advertisers/banner/edit/${advertiser_id}/${item.banner_id}`}
						content_id={item.banner_id}
						active={item.active}
						activeBtnOnClick={(id, active) =>
							onChangeActiveBanner(id, active, advertiser_id)
						}
					/>
				) || ''
		)
	}

	return { bannerGetter, bannerTemplate }
}

export default Banners
