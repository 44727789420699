import React, { useState, useEffect, useCallback } from 'react'
import { Box, Typography, Divider, CircularProgress } from '@mui/joy'

import {
	CheckBoxOutlineBlankRounded,
	CheckBoxRounded,
	IndeterminateCheckBoxRounded,
	ArrowForwardIosRounded,
	DoneAllRounded,
	RemoveDoneRounded,
} from '@mui/icons-material/'

import { Input, Button } from 'antd'
import TreeView, { flattenTree } from 'react-accessible-treeview'
import { useTranslation } from 'react-i18next'
import { Resizable } from 'react-resizable'

const CustomTreeView = ({
	multiSelect,
	items,
	onSelect,
	selectedKey,
	selectedBranch = false,
}) => {
	const { t } = useTranslation()

	const [data, setData] = useState(
		flattenTree(
			items
				? {
						name: '',
						children: [...items],
				  }
				: {
						name: '',
						children: [],
				  }
		)
	)

	const [treeState, setTreeState] = useState(
		data.map(i => !i.children.length && i.id).filter(i => !!i)
	)

	const getAllSelectedKeyId = i => {
		const keys = new Set()
		const dataMap = new Map(data.map(item => [item.id, item]))

		dataMap.forEach(item => {
			if (
				item.metadata &&
				item.metadata.key &&
				i.includes(item.metadata.key) &&
				!keys.has(item.id)
			) {
				keys.add(item.id)
			}
		})

		return [...keys]
	}
	const [resize, setResize] = useState({
		width: 200,
		height: 210,
	})

	const [selectedMeta, setSelectedMeta] = useState(selectedBranch ? [] : {})
	const [selected, setSelected] = useState(selectedKey ? selectedKey : [])
	const [treeData, setTreeData] = useState(data)

	const [expandedIds, setExpandedIds] = useState(
		selectedKey ? getAllSelectedKeyId(selectedKey) : []
	)
	const [filterValue, setFilterValue] = useState('')

	const onChange = useCallback(async () => {
		onSelect(selected, selectedMeta)
	}, [selected, onSelect, selectedMeta])

	useEffect(() => {
		onChange(selected)
	}, [selected, onChange])

	const filter = useCallback(
		value => {
			const filtered = new Map()
			const parentsToAdd = new Set()
			const dataMap = new Map(data.map(item => [item.id, item]))

			const includeParent = id => {
				if (!dataMap.has(id) || filtered.has(id) || parentsToAdd.has(id)) return

				const parentItem = dataMap.get(id)
				parentsToAdd.add(id)
				if (parentItem.parent !== null) {
					includeParent(parentItem.parent)
				}
			}

			const includeChildren = id => {
				data.forEach(item => {
					if (item.parent === id && !filtered.has(item.id)) {
						filtered.set(item.id, item)
						includeParent(item.parent)
						if (item.children.length) {
							item.children.forEach(includeChildren)
						}
					}
				})
			}

			data.forEach(item => {
				if (
					item.name !== '' &&
					item.name.toUpperCase().includes(value.toUpperCase())
				) {
					filtered.set(item.id, item)
					includeParent(item.parent)
					includeChildren(item.id)
				}
			})

			parentsToAdd.forEach(id => {
				const parentItem = dataMap.get(id)
				if (parentItem) filtered.set(id, parentItem)
			})

			let result = Array.from(filtered.values()).filter(i => i.name !== '')

			result.forEach(item => {
				if (item.children.length) {
					item.children = item.children.filter(childId => filtered.has(childId))
				}
			})

			const root = data.find(item => item.name === '')
			if (root) {
				result.unshift({
					...root,
					children: root.children.filter(id => filtered.has(id)),
				})
			}

			const id_list = result.map(item => item.id).filter(item => item > 0)

			setTreeState(id_list)
			setTreeData(result)
		},
		[data]
	)

	useEffect(() => {
		if (filterValue) {
			filter(filterValue)
		} else {
			setTreeData(data)
			setTreeState(data.map(i => !i.children.length && i.id).filter(i => !!i))
		}
	}, [filter, data, filterValue])

	useEffect(() => {
		if (items) {
			const d = flattenTree({
				name: '',
				children: [...items],
			})
			setData(d)
			setTreeData(d)
			setTreeState(d.map(i => !i.children.length && i.id).filter(i => !!i))
		}
	}, [items])

	const CheckBoxIcon = ({ variant, ...rest }) => {
		switch (variant) {
			case 'all':
				return <CheckBoxRounded color='success' {...rest} />
			case 'none':
				return <CheckBoxOutlineBlankRounded {...rest} />
			case 'some':
				return <IndeterminateCheckBoxRounded color='primary' {...rest} />
			default:
				return null
		}
	}

	const ArrowIcon = ({ isOpen }) => (
		<ArrowForwardIosRounded
			sx={{
				fontSize: 12,
				cursor: 'pointer',
				...(isOpen ? { transform: 'rotate(90deg)' } : {}),
			}}
		/>
	)

	const SelectAll = () => (
		<Button
			size={'small'}
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				paddingLeft: '5px',
				paddingRight: '5px',
			}}
			onClick={() => {
				setExpandedIds(treeState.length ? [...treeState] : [])
			}}
		>
			<DoneAllRounded sx={{ fontSize: 13 }} />
		</Button>
	)

	const DeSelectAll = () => (
		<Button
			size={'small'}
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				paddingLeft: '5px',
				paddingRight: '5px',
			}}
			onClick={() => {
				setExpandedIds([])
			}}
		>
			<RemoveDoneRounded sx={{ fontSize: 13 }} />
		</Button>
	)

	const handlerOnSelect = e => {
		if (e.isBranch === false || selectedBranch === true) {
			const { key } = e.element.metadata

			setSelectedMeta(prev => {
				if (e.isSelected) {
					if (selectedBranch === true) {
						const isExist = prev.some(prevMeta => {
							const isPub =
								parseInt(prevMeta.publisherId) ===
								parseInt(e.element.metadata.publisherId)

							const isZone =
								parseInt(prevMeta.zoneId) ===
								parseInt(e.element.metadata.zoneId)

							const isAdvert =
								parseInt(prevMeta.advertiserId) ===
									parseInt(e.element.metadata.advertiserId) &&
								e.element.metadata === 'advertisers'

							const isCamp =
								parseInt(prevMeta.key) === parseInt(e.element.metadata.key) &&
								e.element.metadata === 'campaigns'

							const isBanner =
								parseInt(prevMeta.key) === parseInt(e.element.metadata.key) &&
								e.element.metadata === 'banners'

							const type = prevMeta.type === e.element.metadata.type

							return (isPub || isZone || isAdvert || isCamp || isBanner) && type
						})

						if (!isExist) {
							return [...prev, e.element.metadata]
						}
					}

					return { ...prev, ...e.element.metadata }
				} else {
					if (Array.isArray(prev)) {
						return prev.filter(
							prevMeta =>
								prevMeta.publisherId !== e.element.metadata.publisherId ||
								prevMeta.zoneId !== e.element.metadata.zoneId ||
								(prevMeta.advertiserId !== e.element.metadata.advertiserId &&
									e.element.metadata.type === 'advertisers') ||
								(prevMeta.key !== e.element.metadata.key &&
									['banners', 'campaigns'].includes(e.element.metadata.type))
						)
					}
				}

				return prev
			})

			setSelected(prev =>
				e.isSelected
					? prev.includes(key)
						? prev
						: [...prev, key]
					: prev.filter(item => item !== key)
			)
		}
	}

	const nodeRenderer = ({
		element,
		isBranch,
		isExpanded,
		isSelected,
		isHalfSelected,
		getNodeProps,
		level,
		handleSelect,
		handleExpand,
	}) => {
		return (
			<Box
				{...getNodeProps({
					onClick: handleExpand,
				})}
				sx={{
					display: 'flex',
					alignItems: 'center',
					gridGap: '5px',
					height: 30,
					borderRadius: '4px',
					padding: '0 10px',

					[`&:before`]: {
						borderBottomWidth: '1px',
						borderBottomStyle: 'dashed',
						borderColor: 'var(--joy-palette-divider)',
						left: '-12px',
						top: '14px',
						width: '23px',
						content: "''",
						position: 'absolute',
					},
					[`&:hover svg`]: {
						opacity: 1,
					},
					[`&:hover:before`]: {
						borderColor:
							'var(--variant-solidBg, var(--joy-palette-primary-solidBg, var(--joy-palette-primary-500, #0B6BCB)))',
					},
					[`&:hover`]: {
						backgroundColor:
							'var(--variant-plainHoverBg, var(--joy-palette-neutral-plainHoverBg, var(--joy-palette-neutral-100, #F0F4F8)))',
						color:
							'var(--variant-plainHoverColor, var(--joy-palette-neutral-plainHoverColor, var(--joy-palette-neutral-900, #0B0D0E)))',
					},
				}}
			>
				{isBranch && <ArrowIcon isOpen={isExpanded} />}

				{((!multiSelect && !isBranch) || !!multiSelect) && (
					<CheckBoxIcon
						sx={{
							fontSize: 15,
							opacity: isSelected ? 0.7 : isExpanded ? 0.7 : 0.4,
							cursor: 'pointer',
						}}
						onClick={e => {
							handleSelect(e)
							e.stopPropagation()
						}}
						variant={isHalfSelected ? 'some' : isSelected ? 'all' : 'none'}
					/>
				)}
				<Typography sx={{ fontSize: 13 }}>{element.name}</Typography>
			</Box>
		)
	}

	return (
		<Resizable
			height={resize.height}
			width={resize.width}
			minConstraints={[100, 210]}
			onResize={(event, { size }) => {
				setResize({ width: size.width, height: size.height })
			}}
		>
			<Box
				sx={{
					height: `${resize.height}px`,
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					backgroundColor: 'var(--joy-palette-background-surface)',
					border: '1px solid',
					borderRadius: '8px',
					borderColor:
						'var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))',
				}}
			>
				<Box
					sx={{
						p: '10px',
						display: 'flex',
						flexDirection: 'row',
						// minHeight: '52px',
						overflowY: 'auto',
					}}
				>
					<Input
						placeholder={t('filter')}
						defaultValue={filterValue}
						allowClear
						style={{ maxWidth: '200px' }}
						onChange={e => {
							setFilterValue(e.target.value)
						}}
						size='small'
					/>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'end',
							flex: 1,
							ml: 1.5,
							gridGap: '10px',
						}}
					>
						{!!multiSelect && (
							<>
								<SelectAll />
								<DeSelectAll />
							</>
						)}
					</Box>
				</Box>
				<Divider />
				{treeData.length > 1 ? (
					<>
						<TreeView
							data={treeData}
							selectedIds={expandedIds}
							multiSelect={!!multiSelect}
							propagateSelect
							propagateSelectUpwards
							togglableSelect
							onSelect={handlerOnSelect}
							nodeRenderer={nodeRenderer}
						/>
					</>
				) : (
					<Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
						<CircularProgress size='sm' />
					</Box>
				)}
			</Box>
		</Resizable>
	)
}

export default CustomTreeView
