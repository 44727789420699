import React from 'react'
import { Box, Sheet, Table, CircularProgress } from '@mui/joy'
import { useSelector } from 'react-redux'

import Body from './Body'
import Head from './Head'

const sheetSx = {
	border: 'none',
	transition: 'transform 0.4s',
	backgroundColor: 'transparent',
	borderRadius: 0,
	overflow: 'hidden',
}

const tableSx = {
	'--Table-headerUnderlineThickness': '1px',
	'--TableCell-height': '33px',
	'--TableCell-paddingX': '15px',
	'--TableCell-paddingY': '3px',
	[`& thead th`]: {
		verticalAlign: 'inherit',
	},
}

const Container = () => {
	const isLoading = useSelector(state => state.templates.getAllData?.isLoading)

	return (
		<Box sx={{ maxWidth: 'clamp(450px, 100dvw, 900px)' }}>
			<Sheet variant='outlined' sx={sheetSx}>
				{isLoading ? (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							minHeight: '20dvh',
						}}
					>
						<CircularProgress size='sm' />
					</Box>
				) : (
					<Table
						borderAxis={'x'}
						stickyHeader
						hoverRow
						size={'sm'}
						sx={tableSx}
					>
						<Head />
						<Body />
					</Table>
				)}
			</Sheet>
		</Box>
	)
}
export default Container
