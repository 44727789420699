import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CampaignsState {
	addData: {
		id: number | null
		uuid: string | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	editData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	deleteData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	duplicateData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	getData: {
		id: number | null
		uuid: string | null
		name: string | null
		active: number | null
		template: number | null
		rotation: object | null

		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	getAllData: {
		rows: object | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	target: {
		rows: object | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	activeData: {
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}
}

const initialState: CampaignsState = {
	addData: {
		id: null,
		uuid: null,
		isLoading: false,
		success: false,
		error: null,
	},

	editData: {
		success: false,
		isLoading: false,
		error: null,
	},

	deleteData: {
		success: false,
		isLoading: false,
		error: null,
	},

	duplicateData: {
		success: false,
		isLoading: false,
		error: null,
	},

	getData: {
		id: null,
		uuid: null,
		name: null,
		active: null,
		template: null,
		rotation: [],

		isLoading: false,
		success: false,
		error: null,
	},

	getAllData: {
		rows: [],
		isLoading: false,
		success: false,
		error: null,
	},

	target: {
		rows: [],
		isLoading: false,
		success: false,
		error: null,
	},

	activeData: {
		isLoading: false,
		success: false,
		error: null,
	},
}

export const reducer = createSlice({
	name: 'rotations',
	initialState,
	reducers: {
		rotationAddStart: (state): CampaignsState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		rotationAddSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: false,
				success: true,
				error: false,
				id: action.payload.id,
				uuid: action.payload.uuid,
			},
		}),
		rotationAddFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		rotationEditStart: (state): CampaignsState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: true,
				success: false,
				error: null,
			},
		}),
		rotationEditSuccess: (
			state,
			action: PayloadAction<void>
		): CampaignsState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		rotationEditFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		rotationDeleteStart: (state): CampaignsState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		rotationDeleteSuccess: (
			state,
			action: PayloadAction<void>
		): CampaignsState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		rotationDeleteFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		rotationDuplicateStart: (state): CampaignsState => ({
			...state,
			duplicateData: {
				...state.duplicateData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		rotationDuplicateSuccess: (
			state,
			action: PayloadAction<void>
		): CampaignsState => ({
			...state,
			duplicateData: {
				...state.duplicateData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		rotationDuplicateFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			duplicateData: {
				...state.duplicateData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		rotationGetAllStart: (state): CampaignsState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		rotationGetAllSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: true,
				error: false,
				rows: action.payload.result.rows,
			},
		}),
		rotationGetAllFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: false,
				error: action.payload,
				rows: [],
			},
		}),

		rotationTargetStart: (state): CampaignsState => ({
			...state,
			target: {
				...state.target,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		rotationTargetSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			target: {
				...state.target,
				isLoading: false,
				success: true,
				error: false,
				rows: action.payload.result.rows,
			},
		}),
		rotationTargetFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			target: {
				...state.target,
				isLoading: false,
				success: false,
				error: action.payload,
				rows: [],
			},
		}),

		rotationGetStart: (state): CampaignsState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		rotationGetSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: true,
				error: false,
				...action.payload.result.rows,
			},
		}),
		rotationGetFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Set active
		rotationSetActiveStart: (state): CampaignsState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		rotationSetActiveSuccess: (
			state,
			action: PayloadAction<void>
		): CampaignsState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		rotationSetActiveFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),
	},
})

export const {
	rotationAddStart,
	rotationAddSuccess,
	rotationAddFailure,
	rotationEditStart,
	rotationEditSuccess,
	rotationEditFailure,
	rotationDeleteStart,
	rotationDeleteSuccess,
	rotationDeleteFailure,
	rotationDuplicateStart,
	rotationDuplicateSuccess,
	rotationDuplicateFailure,
	rotationGetAllSuccess,
	rotationGetAllFailure,
	rotationGetAllStart,
	rotationGetStart,
	rotationGetSuccess,
	rotationGetFailure,
	rotationTargetStart,
	rotationTargetSuccess,
	rotationTargetFailure,
	rotationSetActiveStart,
	rotationSetActiveSuccess,
	rotationSetActiveFailure,
} = reducer.actions

export default reducer.reducer
