import * as React from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/joy/Box'
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import { useSelector } from 'react-redux'
import LinearProgress from '@mui/joy/LinearProgress'

const Content = () => {
	const isLoggedIn = useSelector(state => !!state.auth.authData.accessToken)
	const isLoading = useSelector(state => {
		let loading = false

		Object.keys(state).map(i1 =>
			Object.keys(state[i1]).map(i2 => {
				if ('isLoading' in state[i1][i2] && state[i1][i2].isLoading === true) {
					loading = true
				}
				return i2
			})
		)

		return loading
	})

	return (
		<Box sx={{ display: 'flex', minHeight: '100dvh' }}>
			<Sidebar />

			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				{isLoggedIn && <Topbar />}

				<LinearProgress
					thickness={1.5}
					sx={{
						...(!isLoading ? { display: 'none' } : {}),
						...{ mt: '-1.5px' },
						...{ maxHeight: 0 },
					}}
				/>

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						flex: 1,
						width: 'calc(100dvw - var(--Sidebar-width))',
					}}
				>
					<Outlet />
				</Box>
			</Box>
		</Box>
	)
}

export default Content
