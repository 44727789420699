import * as React from 'react'

import { useTheme } from '@mui/material/styles'
import AspectRatio from '@mui/joy/AspectRatio'

const AdAngleLogo = () => {
	const theme = useTheme()
	const themeMode = theme.palette.mode
	return (
		<AspectRatio ratio='1' variant='plain' sx={{ width: 24 }}>
			<div>
				{themeMode === 'dark' ? (
					<svg
						width='656'
						height='639'
						viewBox='0 0 656 639'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M356.366 516.115H338.616C219.471 516.115 122.885 419.529 122.885 300.385V122.885L0.000163078 0V300.385C0.000163078 487.397 151.603 639 338.616 639H477.885L356.366 516.115Z'
							fill='white'
						/>
						<path
							d='M316.769 122.885H122.885L0.000167847 0H316.769C503.781 0 655.385 151.603 655.385 338.615V639L532.5 516.115V338.615C532.5 219.471 435.914 122.885 316.769 122.885Z'
							fill='white'
						/>
					</svg>
				) : (
					<svg
						width='656'
						height='639'
						viewBox='0 0 656 639'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M356.366 516.115H338.616C219.471 516.115 122.885 419.529 122.885 300.385V122.885L0.000163078 0V300.385C0.000163078 487.397 151.603 639 338.616 639H477.885L356.366 516.115Z'
							fill='#0a7ffe'
						/>
						<path
							d='M316.769 122.885H122.885L0.000167847 0H316.769C503.781 0 655.385 151.603 655.385 338.615V639L532.5 516.115V338.615C532.5 219.471 435.914 122.885 316.769 122.885Z'
							fill='#0a7ffe'
						/>
					</svg>
				)}
			</div>
		</AspectRatio>
	)
}

export default AdAngleLogo
