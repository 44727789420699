import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	ICampaignsAddRequest,
	ICampaignsAddResponse,
	ICampaignsPricesAddRequest,
	ICampaignsPricesAddResponse,
	ICampaignsTrackerAddRequest,
	ICampaignsTrackerAddResponse,
} from './types'

export const create = (
	params: ICampaignsAddRequest
): AxiosPromise<ICampaignsAddResponse> =>
	axiosInstance.post(Endpoints.CAMPAIGNS.ADD, params)

export const pricesCreate = (
	params: ICampaignsPricesAddRequest
): AxiosPromise<ICampaignsPricesAddResponse> =>
	axiosInstance.post(Endpoints.CAMPAIGNS.PRICES_ADD, params)

export const trackerCreate = (
	params: ICampaignsTrackerAddRequest
): AxiosPromise<ICampaignsTrackerAddResponse> =>
	axiosInstance.post(Endpoints.CAMPAIGNS.TRACKER_ADD, params)
