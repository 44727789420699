import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	ITemplatesGetAllResponse,
	ITemplatesGetRequest,
	ITemplatesGetResponse,
} from './types'

export const getAll = (): AxiosPromise<ITemplatesGetAllResponse> =>
	axiosInstance.get(Endpoints.TEMPLATES.GET_ALL)

export const get = (
	params: ITemplatesGetRequest
): AxiosPromise<ITemplatesGetResponse> =>
	axiosInstance.get(`${Endpoints.TEMPLATES.GET}/${params.template_id}`)
