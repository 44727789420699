import React from 'react'

import { Button, Popconfirm } from 'antd'
import { DeleteRounded, ModeEditRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from '../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../store'
import { customParamDel } from '../../../../../store/custom'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const Body = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const rows = useSelector(state => state.custom.customParamsGetAllData?.rows)
	const [, setCookie] = useCookies(['reload'])

	const deleteField = deleteId => {
		if (deleteId) {
			dispatch(customParamDel({ param_id: deleteId })).then(result => {
				result
					? toast.success(t('delete_custom_param_successfully'))
					: toast.error(t('error'))

				setCookie('reload', new Date().getTime(), { path: '/' })
			})
		}
	}

	return (
		<>
			<tbody>
				{rows &&
					rows.map(row => (
						<tr key={row.param_id} className='delete-container-retarget'>
							<td>{row.name}</td>
							<td>{row.param_value}</td>
							<td>
								<CopyToClipboard
									text={`$$CF[${row.param_key}]$$`}
									onCopy={(_, result) => {
										if (result) {
											toast.success(t('copied'))
										}
									}}
								>
									<code style={{ cursor: 'pointer' }}>
										$$CP[{row.param_key}]$$
									</code>
								</CopyToClipboard>
							</td>

							<td style={{ paddingLeft: 0, paddingRight: 0 }}>
								<Popconfirm
									placement='topRight'
									style={{ maxWidth: '300px' }}
									title={t('delete_dialog_title')}
									description={t('delete_dialog_remove_custom_param')}
									okText={t('delete_dialog_confirm')}
									cancelText={t('delete_dialog_cancel')}
									onConfirm={() => {
										deleteField(row.param_id)
									}}
								>
									<Button
										size='small'
										className='delete-btn-retarget'
										danger
										style={{ padding: 3 }}
									>
										<DeleteRounded style={{ fontSize: 16, color: '#dc4446' }} />
									</Button>
								</Popconfirm>
							</td>

							<td style={{ paddingLeft: 0, paddingRight: 0 }}>
								<NavLink to={`/params/edit/${row.param_id}`}>
									<Button size='small' style={{ padding: 3 }}>
										<ModeEditRounded style={{ fontSize: 16 }} />
									</Button>
								</NavLink>
							</td>
						</tr>
					))}
			</tbody>
		</>
	)
}

export default Body
