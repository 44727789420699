import React from 'react'
import { Button } from 'antd'
import { LocalPrintshopRounded } from '@mui/icons-material'

const Print = ({ tableRef }) => {
	const handlePrint = () => {
		const printWindow = window.open('', '', 'height=600,width=800')
		Array.from(document.styleSheets).forEach(styleSheet => {
			try {
				if (styleSheet.cssRules) {
					const newStyleEl = printWindow.document.createElement('style')

					Array.from(styleSheet.cssRules).forEach(cssRule => {
						newStyleEl.appendChild(
							printWindow.document.createTextNode(cssRule.cssText)
						)
					})

					printWindow.document.head.appendChild(newStyleEl)
				} else if (styleSheet.href) {
					const newLinkEl = printWindow.document.createElement('link')
					newLinkEl.rel = 'stylesheet'
					newLinkEl.href = styleSheet.href
					printWindow.document.head.appendChild(newLinkEl)
				}
			} catch (e) {
				console.warn('Невозможно обработать некоторые стили:', e)
			}
		})

		const printContent = tableRef.outerHTML
		printWindow.document.body.innerHTML = printContent

		printWindow.document.close()
		printWindow.focus()
		printWindow.print()
	}

	return (
		<Button
			onClick={handlePrint}
			size='small'
			ghost
			style={{
				paddingLeft: '4px',
				paddingRight: '4px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<LocalPrintshopRounded sx={{ fontSize: 15 }} />
		</Button>
	)
}

export default Print
