import React, { useEffect } from 'react'

import { Box, CircularProgress } from '@mui/joy'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import { getAdvertiser } from '../../../../store/advertisers'
import { useAdv } from '../../../hooks/advertisers'

import ControlBar from './ControlBar'
import Form from './Form'

const Edit = () => {
	const dispatch = useAppDispatch()
	const { adv, replaceAdvertiser } = useAdv()
	const { pageId } = useParams()
	const parsedPageId = parseInt(pageId, 10)

	useEffect(() => {
		const isValid = !!adv
			? !adv.advertiser_id || adv.advertiser_id !== parsedPageId
			: true

		if (isValid && parsedPageId > 0) {
			dispatch(getAdvertiser({ advertiser_id: parsedPageId })).then(result => {
				if (!result.error) {
					replaceAdvertiser(result.rows)
				}
			})
		}
	}, [parsedPageId, adv, replaceAdvertiser, dispatch])

	return !!adv &&
		Object.keys(adv).length &&
		adv.advertiser_id === parsedPageId ? (
		<Box sx={{ width: '100%' }}>
			<ControlBar />
			<Form />
		</Box>
	) : (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				minHeight: '20dvh',
			}}
		>
			<CircularProgress size='sm' />
		</Box>
	)
}

export default Edit
