import React, { useState } from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Add, EmojiObjectsRounded } from '@mui/icons-material'
import { Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { useBnr } from '../../../../../../hooks/banners'
import FormItem from '../../../../../../layout/FormItem'
import Uploader from '../../../../../../layout/Uploader'

const { TextArea } = Input

const Creative = () => {
	const { t } = useTranslation()
	const { bnr, updateBanner } = useBnr()
	const [index, setIndex] = useState(0)

	return (
		<Accordion
			sx={{ border: 'none' }}
			expanded={index === 0}
			onChange={(_, expanded) => {
				setIndex(expanded ? 0 : null)
			}}
		>
			<AccordionSummary indicator={<Add />} onClick={e => e.preventDefault()}>
				<EmojiObjectsRounded />
				<Box sx={{ flex: 1, fontSize: '13px' }}>
					{t('add_advertiser_creative')}
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<FormItem
					text={'creative'}
					component={
						<Uploader
							value={bnr.creative || ''}
							onChange={e => {
								updateBanner('creative', e.target.value)
								updateBanner('metadata', bnr.metadata)
							}}
						/>
					}
				/>

				<FormItem
					text={'add_advertiser_banner_link'}
					component={
						<TextArea
							style={{ width: '100%' }}
							value={bnr.url || ''}
							onChange={e => {
								updateBanner('url', e.target.value)
							}}
						/>
					}
				/>
			</AccordionDetails>
		</Accordion>
	)
}

export default Creative
