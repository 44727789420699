import * as React from 'react'

import { Box } from '@mui/joy'
import { Select, Space, Tag } from 'antd'

import countryList from 'react-select-country-list'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

import { FlagImg } from '../../../../../handlers/Chips'
import { useFilter } from '../../../../../hooks/filter'
import { useAppDispatch } from '../../../../../../store'
import { rotationTarget } from '../../../../../../store/rotations'
import { useTranslation } from 'react-i18next'

const Locations = () => {
	const { t } = useTranslation()
	const { updateFilter } = useFilter()

	const dispatch = useAppDispatch()
	const countries = React.useMemo(() => countryList().getData(), [])

	const [regions, setRegions] = React.useState({})
	const [value, setValue] = React.useState([])
	const [value1, setValue1] = React.useState([])
	const [value2, setValue2] = React.useState([])

	const getRows = async val => {
		const country = val.map(i => i.value)
		await dispatch(rotationTarget({ country })).then(result => {
			setRegions(result.data || [])
		})
	}

	const include = () => {
		updateFilter('in_country', value)
		updateFilter('in_region', value1)
		updateFilter('in_city', value2)
	}

	const exclude = () => {
		updateFilter('ex_country', value)
		updateFilter('ex_region', value1)
		updateFilter('ex_city', value2)
	}

	const regionsSelect = arr => {
		let result = []

		if (Object.keys(arr).length && 'rows' in arr && 'regions' in arr.rows) {
			Object.keys(arr.rows.regions).map(i =>
				Object.keys(arr.rows.regions[i]).map(ii => {
					if (!!ii) {
						result.push({ label: ii, value: ii, country: i })
					}
					return ii
				})
			)
		}

		return result
	}

	const citiesSelect = (arr, ct) => {
		let result = []
		ct = ct.map(i => i.value)

		if (Object.keys(arr).length && 'rows' in arr && 'regions' in arr.rows) {
			Object.keys(arr.rows.regions).map(i =>
				Object.keys(arr.rows.regions[i]).map(ii => {
					return arr.rows.regions[i][ii].map(iii => {
						if (!!ii && ct.includes(ii) && !!iii.value) {
							result.push({
								label: iii.value,
								value: iii.value,
								key: iii.key,
								region: ii,
								country: i,
							})
						}
						return iii
					})
				})
			)
		}

		return result
	}

	const select1 = regionsSelect(regions)
	const select2 = citiesSelect(regions, value1)

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					gridGap: '10px',
					flexDirection: 'column',
					flexWrap: 'wrap',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
						gridGap: 10,
						flex: 1,
					}}
				>
					<Space.Compact block>
						<Select
							placeholder={t('country')}
							maxTagCount={'responsive'}
							allowClear
							mode='multiple'
							style={{ width: '100%' }}
							onChange={async (_, val) => {
								if (val.length) await getRows(val)
								setValue(val)
							}}
							defaultValue={value1}
							tagRender={({ label, value, closable, onClose }) => {
								const onPreventMouseDown = event => {
									event.preventDefault()
									event.stopPropagation()
								}

								return (
									<Tag
										key={`${label}-${value}`}
										onMouseDown={onPreventMouseDown}
										closable={closable}
										onClose={onClose}
										style={{
											marginInlineEnd: 4,
											display: 'flex',
										}}
									>
										<Space style={{ gridGap: '4px', mr: '4px' }}>
											<FlagImg sx={{ display: 'flex' }} countryCode={value} />
											{label}
										</Space>
									</Tag>
								)
							}}
							options={countries}
							filterOption={(input, option) =>
								option.label.toLowerCase().includes(input.toLowerCase())
							}
							optionLabelProp='label'
							optionRender={option => (
								<Space>
									<FlagImg
										sx={{ display: 'flex' }}
										countryCode={option.data.value}
									/>
									{option.data.label}
								</Space>
							)}
						/>

						<Select
							placeholder={t('region')}
							maxTagCount={'responsive'}
							allowClear
							mode='multiple'
							style={{ width: '100%' }}
							onChange={(_, val) => {
								setValue1(val)
							}}
							defaultValue={value1}
							options={select1}
							optionLabelProp='label'
							optionRender={option => (
								<Space style={{ display: 'flex' }}>
									{!!option.data.country && (
										<FlagImg
											sx={{ display: 'flex' }}
											countryCode={option.data.country}
										/>
									)}
									{option.data.label}
								</Space>
							)}
						/>

						<Select
							placeholder={t('city')}
							maxTagCount={'responsive'}
							allowClear
							mode='multiple'
							style={{ width: '100%' }}
							onChange={(_, val) => {
								setValue2(val)
							}}
							defaultValue={value2}
							options={select2}
							optionLabelProp='label'
							optionRender={option => (
								<Space style={{ display: 'flex' }}>
									{!!option.data.country && (
										<FlagImg
											sx={{ display: 'flex' }}
											countryCode={option.data.country}
										/>
									)}
									{option.data.label}
								</Space>
							)}
						/>
					</Space.Compact>

					<IncludeExcludeBtn include={include} exclude={exclude} />
				</Box>
			</Box>
		</>
	)
}

export default Locations
