import React, { useEffect } from 'react'

import { Box, CircularProgress } from '@mui/joy'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../../store'
import { campaignGet } from '../../../../../store/campaigns'
import { useCmp } from '../../../../hooks/campaigns'

import ControlBar from './ControlBar'
import Form from './Form'

const Edit = () => {
	const dispatch = useAppDispatch()
	const { cmp, replaceCampaign } = useCmp()
	const advertiserId = parseInt(useParams().pageId)
	const campaignId = parseInt(useParams().campaignId)

	useEffect(() => {
		const isValid = !!cmp
			? !cmp.advertiser_id ||
			  !cmp.campaign_id ||
			  cmp.advertiser_id !== advertiserId ||
			  cmp.campaign_id !== campaignId
			: false

		if (isValid && advertiserId > 0 && campaignId > 0) {
			dispatch(
				campaignGet({
					campaign_id: campaignId,
					advertiser_id: advertiserId,
				})
			).then(result => {
				if (!result.error) {
					replaceCampaign(result.rows)
				}
			})
		}
	}, [replaceCampaign, campaignId, advertiserId, cmp, dispatch])

	return !!cmp &&
		Object.keys(cmp).length &&
		cmp.advertiser_id === advertiserId &&
		cmp.campaign_id === campaignId ? (
		<Box sx={{ width: '100%' }}>
			<ControlBar />
			<Form />
		</Box>
	) : (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				minHeight: '20dvh',
			}}
		>
			<CircularProgress size='sm' />
		</Box>
	)
}

export default Edit
