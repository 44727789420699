import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CampaignsState {
	addData: {
		campaign_id: number | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	editData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	deleteData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	getData: {
		advertiser_id: number | null
		campaign_id: number | null
		template: number | null
		user_id: number | null
		active: number | null
		name: string | null

		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	getAllData: {
		rows: object | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	activeData: {
		isLoading: boolean
		campaign_id: number | null
		active: number | null
		success: boolean | null
		error: string | boolean | null
	}

	pricesAddData: {
		campaign_price_id: number | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	pricesDeleteData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	pricesGetAllData: {
		rows: object | null
		delete: boolean
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	trackerAddData: {
		campaign_tracking_id: number | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	trackerDeleteData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	trackerGetAllData: {
		rows: object | null
		delete: boolean
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}
}

const initialState: CampaignsState = {
	addData: {
		campaign_id: null,
		isLoading: false,
		success: false,
		error: null,
	},

	editData: {
		success: false,
		isLoading: false,
		error: null,
	},

	deleteData: {
		success: false,
		isLoading: false,
		error: null,
	},

	getData: {
		advertiser_id: null,
		campaign_id: null,
		template: null,
		user_id: null,
		active: null,
		name: null,

		isLoading: false,
		success: false,
		error: null,
	},

	getAllData: {
		rows: [],
		isLoading: false,
		success: false,
		error: null,
	},

	activeData: {
		isLoading: false,
		campaign_id: null,
		active: null,
		success: false,
		error: null,
	},

	pricesAddData: {
		campaign_price_id: null,
		isLoading: false,
		success: false,
		error: null,
	},

	pricesDeleteData: {
		success: false,
		isLoading: false,
		error: null,
	},

	pricesGetAllData: {
		rows: [],
		delete: false,
		isLoading: false,
		success: false,
		error: null,
	},

	trackerAddData: {
		campaign_tracking_id: null,
		isLoading: false,
		success: false,
		error: null,
	},

	trackerDeleteData: {
		success: false,
		isLoading: false,
		error: null,
	},

	trackerGetAllData: {
		rows: [],
		delete: false,
		isLoading: false,
		success: false,
		error: null,
	},
}

export const reducer = createSlice({
	name: 'campaigns',
	initialState,
	reducers: {
		// Add advertiser
		campaignAddStart: (state): CampaignsState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		campaignAddSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: false,
				success: true,
				error: false,
				campaign_id: action.payload.campaign_id,
			},
		}),
		campaignAddFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Edit advertiser
		campaignEditStart: (state): CampaignsState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: true,
				success: false,
				error: null,
			},
		}),
		campaignEditSuccess: (
			state,
			action: PayloadAction<void>
		): CampaignsState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		campaignEditFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Delete advertiser
		campaignDeleteStart: (state): CampaignsState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		campaignDeleteSuccess: (
			state,
			action: PayloadAction<void>
		): CampaignsState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		campaignDeleteFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Get All advertiser
		campaignGetAllStart: (state): CampaignsState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		campaignGetAllSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: true,
				error: false,
				rows: action.payload.result.rows,
			},
		}),
		campaignGetAllFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: false,
				error: action.payload,
				rows: [],
			},
		}),

		// Get advertiser by id
		campaignGetStart: (state): CampaignsState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		campaignGetSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: true,
				error: false,
				...action.payload.result.rows,
			},
		}),
		campaignGetFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Set active
		campaignSetActiveStart: (state): CampaignsState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		campaignSetActiveSuccess: (
			state,
			action: PayloadAction<void>
		): CampaignsState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		campaignSetActiveFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Add campaign prices
		campaignPricesAddStart: (state): CampaignsState => ({
			...state,
			pricesAddData: {
				...state.pricesAddData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		campaignPricesAddSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			pricesAddData: {
				...state.pricesAddData,
				isLoading: false,
				success: true,
				error: false,
				campaign_price_id: action.payload.campaign_price_id,
			},
		}),
		campaignPricesAddFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			pricesAddData: {
				...state.pricesAddData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Delete campaign prices
		campaignPricesDeleteStart: (state): CampaignsState => ({
			...state,
			pricesDeleteData: {
				...state.pricesDeleteData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		campaignPricesDeleteSuccess: (
			state,
			action: PayloadAction<void>
		): CampaignsState => ({
			...state,
			pricesDeleteData: {
				...state.pricesDeleteData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		campaignPricesDeleteFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			pricesDeleteData: {
				...state.pricesDeleteData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Get All advertiser
		campaignPricesGetAllStart: (state): CampaignsState => ({
			...state,
			pricesGetAllData: {
				...state.pricesGetAllData,
				isLoading: true,
				success: false,
				delete: false,
				error: false,
			},
		}),
		campaignPricesGetAllSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			pricesGetAllData: {
				...state.pricesGetAllData,
				isLoading: false,
				success: true,
				error: false,
				delete: action.payload.result.delete,
				rows: action.payload.result.rows,
			},
		}),
		campaignPricesGetAllFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			pricesGetAllData: {
				...state.pricesGetAllData,
				isLoading: false,
				success: false,
				delete: false,
				error: action.payload,
				rows: [],
			},
		}),

		// Add campaign prices
		campaignTrackerAddStart: (state): CampaignsState => ({
			...state,
			trackerAddData: {
				...state.trackerAddData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		campaignTrackerAddSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			trackerAddData: {
				...state.trackerAddData,
				isLoading: false,
				success: true,
				error: false,
				campaign_tracking_id: action.payload.campaign_tracking_id,
			},
		}),
		campaignTrackerAddFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			trackerAddData: {
				...state.trackerAddData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Delete campaign prices
		campaignTrackerDeleteStart: (state): CampaignsState => ({
			...state,
			trackerDeleteData: {
				...state.trackerDeleteData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		campaignTrackerDeleteSuccess: (
			state,
			action: PayloadAction<void>
		): CampaignsState => ({
			...state,
			trackerDeleteData: {
				...state.trackerDeleteData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		campaignTrackerDeleteFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			trackerDeleteData: {
				...state.trackerDeleteData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Get All advertiser
		campaignTrackerGetAllStart: (state): CampaignsState => ({
			...state,
			trackerGetAllData: {
				...state.trackerGetAllData,
				isLoading: true,
				success: false,
				delete: false,
				error: false,
			},
		}),
		campaignTrackerGetAllSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			trackerGetAllData: {
				...state.trackerGetAllData,
				isLoading: false,
				success: true,
				error: false,
				delete: action.payload.result.delete,
				rows: action.payload.result.rows,
			},
		}),
		campaignTrackerGetAllFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			trackerGetAllData: {
				...state.trackerGetAllData,
				isLoading: false,
				success: false,
				delete: false,
				error: action.payload,
				rows: [],
			},
		}),
	},
})

export const {
	campaignAddStart,
	campaignAddSuccess,
	campaignAddFailure,
	campaignEditStart,
	campaignEditSuccess,
	campaignEditFailure,
	campaignDeleteStart,
	campaignDeleteSuccess,
	campaignDeleteFailure,
	campaignGetAllSuccess,
	campaignGetAllFailure,
	campaignGetAllStart,
	campaignGetStart,
	campaignGetSuccess,
	campaignGetFailure,
	campaignSetActiveStart,
	campaignSetActiveSuccess,
	campaignSetActiveFailure,

	campaignPricesAddStart,
	campaignPricesAddSuccess,
	campaignPricesAddFailure,
	campaignPricesDeleteStart,
	campaignPricesDeleteSuccess,
	campaignPricesDeleteFailure,
	campaignPricesGetAllStart,
	campaignPricesGetAllSuccess,
	campaignPricesGetAllFailure,

	campaignTrackerAddStart,
	campaignTrackerAddSuccess,
	campaignTrackerAddFailure,
	campaignTrackerDeleteStart,
	campaignTrackerDeleteSuccess,
	campaignTrackerDeleteFailure,
	campaignTrackerGetAllStart,
	campaignTrackerGetAllSuccess,
	campaignTrackerGetAllFailure,
} = reducer.actions

export default reducer.reducer
