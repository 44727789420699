import React from 'react'

import Form from './Form'
import Submit from './Submit'

import { toast } from '../../../layout/JoyToaster'
import { useCookies } from 'react-cookie'
import { useAppDispatch } from '../../../../store'
import { useTranslation } from 'react-i18next'
import { usersEdit } from '../../../../store/users'

const BaseInformation = ({ setRows, rows }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [, setCookie] = useCookies(['reload'])

	const handlerForm = event => {
		event.preventDefault()

		dispatch(
			usersEdit({
				user_id: rows.user_id,
				role_id: rows.role_id,
				active: rows.active,
				email: rows.email,
				password: rows.password,
			})
		).then(result => {
			result
				? toast.success(t('edit_advertiser_successfully'))
				: toast.error(t('error'))

			setCookie('reload', new Date().getTime(), { path: '/' })
		})
	}

	return (
		<form onSubmit={handlerForm}>
			<Form
				values={{
					set: setRows,
					arr: rows,
				}}
			/>
			<Submit />
		</form>
	)
}

export default BaseInformation
