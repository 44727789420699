import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	ICampaignsGetAllRequest,
	ICampaignsGetAllResponse,
	ICampaignsGetRequest,
	ICampaignsGetResponse,
	ICampaignsPricesGetAllRequest,
	ICampaignsPricesGetAllResponse,
	ICampaignsTrackerGetAllRequest,
	ICampaignsTrackerGetAllResponse,
} from './types'

export const getAll = (
	params: ICampaignsGetAllRequest
): AxiosPromise<ICampaignsGetAllResponse> =>
	axiosInstance.post(
		`${Endpoints.CAMPAIGNS.GET_ALL}/${params.advertiser_id}`,
		params
	)

export const get = (
	params: ICampaignsGetRequest
): AxiosPromise<ICampaignsGetResponse> =>
	axiosInstance.get(
		`${Endpoints.CAMPAIGNS.GET}/${params.advertiser_id}/${params.campaign_id}`
	)

export const pricesGetAll = (
	params: ICampaignsPricesGetAllRequest
): AxiosPromise<ICampaignsPricesGetAllResponse> =>
	axiosInstance.post(`${Endpoints.CAMPAIGNS.PRICES_GET_ALL}`, params)

export const trackerGetAll = (
	params: ICampaignsTrackerGetAllRequest
): AxiosPromise<ICampaignsTrackerGetAllResponse> =>
	axiosInstance.post(`${Endpoints.CAMPAIGNS.TRACKER_GET_ALL}`, params)
