import React from 'react'

import { Button, Popconfirm } from 'antd'
import { DeleteRounded, ModeEditRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from '../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../store'
import { categoryDel } from '../../../../../store/categories'

const Body = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [, setCookie] = useCookies(['reload'])
	const rows = useSelector(state => state.categories.getAllData?.rows)

	const deleteCategory = deleteCategoryId => {
		if (deleteCategoryId) {
			dispatch(categoryDel({ category_id: deleteCategoryId })).then(result => {
				result
					? toast.success(t('delete_category_successfully'))
					: toast.error(t('error'))

				setCookie('reload', new Date().getTime(), { path: '/' })
			})
		}
	}

	return (
		<tbody>
			{rows &&
				rows.map(row => (
					<tr key={row.category_id} className='delete-container-retarget'>
						<td>{row.name}</td>

						<td style={{ paddingLeft: 0, paddingRight: 0 }}>
							<Popconfirm
								placement='topRight'
								style={{ maxWidth: '300px' }}
								title={t('delete_dialog_title')}
								description={t('delete_dialog_remove_category')}
								okText={t('delete_dialog_confirm')}
								cancelText={t('delete_dialog_cancel')}
								onConfirm={() => {
									deleteCategory(row.category_id)
								}}
							>
								<Button
									size='small'
									className='delete-btn-retarget'
									danger
									style={{ padding: 3 }}
								>
									<DeleteRounded style={{ fontSize: 16, color: '#dc4446' }} />
								</Button>
							</Popconfirm>
						</td>

						<td style={{ paddingLeft: 0, paddingRight: 0 }}>
							<NavLink to={`/categories/edit/${row.category_id}`}>
								<Button size='small' style={{ padding: 3 }}>
									<ModeEditRounded style={{ fontSize: 16 }} />
								</Button>
							</NavLink>
						</td>
					</tr>
				))}
		</tbody>
	)
}

export default Body
