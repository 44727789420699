import React, { useState } from 'react'

import {
	Box,
	Sheet,
	Accordion,
	AccordionGroup,
	AccordionDetails,
	AccordionSummary,
} from '@mui/joy'

import { Add } from '@mui/icons-material'
import { accordionDetailsClasses } from '@mui/joy/AccordionDetails'
import { accordionSummaryClasses } from '@mui/joy/AccordionSummary'

export const AccordItem = ({ icon, last, text, items }) => {
	const [index, setIndex] = useState(1)

	return (
		<Accordion
			sx={{ ...(last ? { borderBottom: 0 } : {}) }}
			expanded={index === 0}
			onChange={(event, expanded) => {
				setIndex(expanded ? 0 : null)
			}}
		>
			<AccordionSummary indicator={<Add />} onClick={e => e.preventDefault()}>
				{!!icon && icon}
				<Box sx={{ flex: 1, fontSize: '13px' }}>{text}</Box>
			</AccordionSummary>

			<AccordionDetails>{!!items && items}</AccordionDetails>
		</Accordion>
	)
}

export const Accord = ({ items }) => {
	const accordionStyles = {
		[`& .${accordionSummaryClasses.indicator}`]: {
			transition: '0.2s',
		},
		[`& [aria-expanded="true"] .${accordionSummaryClasses.indicator}`]: {
			transform: 'rotate(45deg)',
		},
		[`& .${accordionSummaryClasses.button}:hover`]: {
			bgcolor: 'transparent',
		},
		[`& .${accordionDetailsClasses.content}`]: {
			gridGap: '10px',
			boxShadow: theme => `inset 0 1px ${theme.vars.palette.divider}`,
			padding: '1.2rem',
			paddingBlock: '1rem',
		},
		[`& .${accordionSummaryClasses.button}`]: {
			padding: '1.2rem',
			paddingBlock: '0.65rem',
		},
		[`& .${accordionSummaryClasses.button}:last-child`]: {
			borderBottom: 'none',
		},
	}

	return (
		<Sheet
			variant='outlined'
			sx={{
				mb: 2,
				border: '1px solid',
				borderColor: 'divider',
				transition: 'transform 0.4s',
				backgroundColor: 'transparent',
				borderRadius: '8px',
				overflow: 'hidden',
			}}
		>
			<AccordionGroup size='sm' sx={accordionStyles}>
				{!!items && items}
			</AccordionGroup>
		</Sheet>
	)
}
