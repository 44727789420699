import React, { useState } from 'react'
import Box from '@mui/joy/Box'
import ControlBar from './ControlBar'
import Table from './Table'

const Main = () => {
	const [searchParams, setSearchParams] = useState({
		active: 1,
		role_id: -1,
		search: '',
	})

	return (
		<Box
			sx={{
				maxWidth: 'clamp(450px, 100dvw, 900px)',
				width: '100%',
				borderRight: '1px solid',
				borderColor: 'divider',
				overflowY: 'auto',
			}}
		>
			<ControlBar search={{ value: searchParams, set: setSearchParams }} />
			<Table search={{ value: searchParams, set: setSearchParams }} />
		</Box>
	)
}

export default Main
