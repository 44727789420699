import Endpoints from "../../endpoints"
import {AxiosPromise} from "axios"
import {axiosInstance} from "../../instance"
import {IAdvertisersSetActiveRequest, 
				IAdvertisersSetActiveResponse,
				IAdvertisersEditRequest, 
				IAdvertisersEditResponse} from "./types"

export const setActive = (params: IAdvertisersSetActiveRequest): 
	AxiosPromise<IAdvertisersSetActiveResponse> => axiosInstance.patch(Endpoints.ADVERTISERS.SET_ACTIVE, params)

export const update = (params: IAdvertisersEditRequest): 
	AxiosPromise<IAdvertisersEditResponse> => axiosInstance.put(Endpoints.ADVERTISERS.EDIT, params)
