import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	IPublishersAddRequest,
	IPublishersAddResponse,
	IPublishersAddSharingRequest,
	IPublishersAddSharingResponse,
} from './types'

export const create = (
	params: IPublishersAddRequest
): AxiosPromise<IPublishersAddResponse> =>
	axiosInstance.post(Endpoints.PUBLISHERS.ADD, params)

export const sharing = (
	params: IPublishersAddSharingRequest
): AxiosPromise<IPublishersAddSharingResponse> =>
	axiosInstance.post(Endpoints.PUBLISHERS.SHARING_ADD, params)
