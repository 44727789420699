import React, { useState } from 'react'

import { Box, Tab, Tabs, TabList, TabPanel, Sheet } from '@mui/joy'
import { Input, Space, Button, Select } from 'antd'
import { tabClasses } from '@mui/joy/Tab'
import { EyeOutlined } from '@ant-design/icons'
import { ContentCopyRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useColorScheme } from '@mui/joy/styles'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from '../../../../../../layout/JoyToaster'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import FormItem from '../../../../../../layout/FormItem'
import Editor from '@monaco-editor/react'

const Video = () => {
	const { t } = useTranslation()
	const { mode } = useColorScheme()
	const [domain, setDomain] = useState(0)
	const [index, setIndex] = useState('xml')

	const out = useSelector(state => state.zones?.getData?.out)

	const currentDomain =
		out.domains &&
		out.domains.vast[domain] &&
		window.location.host.indexOf('localhost') > -1
			? `http://${out.domains.vast[domain]}`
			: `https://${out.domains.vast[domain]}`

	const CopyBtn = ({ text }) => (
		<CopyToClipboard
			text={`${text}`}
			onCopy={(_, result) => {
				if (result) {
					toast.success(t('copied'))
				}
			}}
		>
			<Button
				style={{
					padding: '2px 7px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<ContentCopyRounded sx={{ fontSize: 16 }} />
			</Button>
		</CopyToClipboard>
	)

	const eUrl = `${currentDomain}/eu/${out.vast.v3}`
	const eLink = `${currentDomain}/el/${out.vast.v3}`
	const tLink = `${currentDomain}/o/test/${out.vast.v3}`

	const XmlVast = () =>
		out.vast &&
		Object.keys(out.vast).map(i => {
			const vst = `${currentDomain}/v/${out.vast[i]}.xml`

			return (
				<React.Fragment key={i}>
					<FormItem
						text={`vast_${i}`}
						component={
							<Space.Compact style={{ width: '100%' }}>
								<Input
									disabled
									defaultValue={vst}
									style={{
										width: '100%',
										flex: 1,
										ml: 0,
									}}
								/>
								<CopyBtn text={vst} />
							</Space.Compact>
						}
					/>
				</React.Fragment>
			)
		})

	const OverRoll = () => {
		const code = `<script type="text/javascript" async>
	let aaScript = document.createElement("script");
	aaScript.type = "text/javascript";
	aaScript.src = "${currentDomain}/o?" +
	new URLSearchParams({
	  vst: encodeURIComponent(\`${out.vast.v3}\`),
	  host: 'site.com',
	  lang: 'en',
	  container: '.css-selector'
	})
	document.body.appendChild(aaScript);
</script>`

		return (
			<FormItem
				text={`overroll`}
				component={
					<>
						<Sheet
							sx={{
								width: '100%',
								border: '1px solid',
								borderColor: 'divider',
								p: 1,
								borderRadius: 8,
								'.monaco-editor': {
									'--vscode-editor-background': 'transparent!important',
									'--vscode-editorGutter-background': 'transparent!important',
								},
								'.decorationsOverviewRuler': {
									display: 'none!important',
								},
							}}
						>
							<Editor
								height='215px'
								language='html'
								theme={mode === 'dark' ? 'vs-dark' : 'vs-light'}
								value={code}
								options={{
									fixedOverflowWidgets: true,
									inlineSuggest: true,
									fontSize: '12px',
									formatOnType: true,
									readOnly: true,
									readOnlyMessage: { value: '' },
									autoClosingBrackets: true,
									scrollBeyondLastColumn: 0,
									scrollBeyondLastLine: false,
									wordWrap: 'on',
									acceptSuggestionOnEnter: 'off',
									contextmenu: false,
									minimap: { enabled: false },
									lineNumbers: 'off',
									folding: false,
									scrollPredominantAxis: false,
									smoothScrolling: false,
									renderLineHighlight: 'none',
									renderLineHighlightOnlyWhenFocus: false,
									lineDecorationsWidth: 0,
									stickyScroll: { enabled: false },
									guides: { indentation: false },
									scrollbar: {
										vertical: 'hidden',
										horizontal: 'hidden',
										scrollByPage: false,
										alwaysConsumeMouseWheel: false,
										handleMouseWheel: false,
										useShadows: false,
									},
								}}
							/>
						</Sheet>
					</>
				}
			/>
		)
	}

	const Url = () => {
		return (
			<>
				<FormItem
					text={`creative`}
					component={
						<Space.Compact style={{ width: '100%' }}>
							<Input
								disabled
								defaultValue={eUrl}
								style={{
									width: '100%',
									flex: 1,
									ml: 0,
								}}
							/>
							<CopyBtn text={eUrl} />
						</Space.Compact>
					}
				/>

				<FormItem
					text={`link`}
					component={
						<Space.Compact style={{ width: '100%' }}>
							<Input
								disabled
								defaultValue={eLink}
								style={{
									width: '100%',
									flex: 1,
									ml: 0,
								}}
							/>
							<CopyBtn text={eLink} />
						</Space.Compact>
					}
				/>
			</>
		)
	}

	const isLoading = useSelector(state => state.zones.getData?.isLoading)
	const items =
		out.domains && out.domains.vast.map((i, k) => ({ label: i, value: k }))

	return (
		<>
			<FormItem
				text={'domain'}
				component={
					<Select
						maxTagCount={'responsive'}
						style={{ width: '100%', zIndex: 999 }}
						loading={isLoading}
						defaultValue={domain}
						options={items}
						onChange={setDomain}
					/>
				}
			/>

			<Tabs
				orientation={'horizontal'}
				defaultValue={index}
				sx={{
					gridColumn: '1/-1',
					borderRadius: '8px',
					width: '100%',
					flexDirection: 'column',
					bgcolor: 'transparent',
				}}
				onChange={(_, value) => setIndex(value)}
				size='sm'
			>
				<TabList
					underlinePlacement={'bottom'}
					sx={{
						p: 0.5,
						gap: 0.5,
						borderRadius: '8px',
						bgcolor: 'background.level1',
						border: 'none',
						boxShadow: 'none',
						[`& .${tabClasses.root}[aria-selected="true"]`]: {
							bgcolor: 'background.surface',
						},
					}}
				>
					<Tab disableIndicator value='xml'>
						Vast
					</Tab>
					<Tab disableIndicator value='overroll'>
						OverRoll
					</Tab>
					<Tab disableIndicator value='url'>
						URL
					</Tab>
				</TabList>
				<TabPanel value='xml' sx={{ px: 0, pb: 0 }}>
					<XmlVast />
				</TabPanel>
				<TabPanel value='overroll' sx={{ px: 0, pb: 0 }}>
					<OverRoll />
				</TabPanel>
				<TabPanel value='url' sx={{ px: 0, pb: 0 }}>
					<Url />
				</TabPanel>
			</Tabs>

			<Box
				sx={{
					display: 'flex',
					flex: 1,
					alignItems: 'right',
					justifyContent: 'end',
					textDecoration: 'none',
				}}
				component={NavLink}
				to={tLink}
				target={'_blank'}
			>
				<Button size='sm' variant='soft'>
					<EyeOutlined /> {t('preview')}
				</Button>
			</Box>
		</>
	)
}

export default Video
