import React, { useState } from 'react'

import { Box } from '@mui/joy'
import { Select, Tag, Space } from 'antd'

import { useFilter } from '../../../../../hooks/filter'
import { useTranslation } from 'react-i18next'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const ConnectionType = () => {
	const { t } = useTranslation()
	const { updateFilter } = useFilter()
	const [value, setValue] = useState({})
	const connectionTypeText = t('connection_type', { returnObjects: true })

	const include = () => updateFilter('in_connection_type', value)
	const exclude = () => updateFilter('ex_connection_type', value)

	const connectionTypes = [
		{ label: connectionTypeText['_dialup'], value: 'dialup' },
		{ label: connectionTypeText['_cable'], value: 'cable' },
		{ label: connectionTypeText['_corporate'], value: 'corporate' },
		{ label: connectionTypeText['_cellular'], value: 'cellular' },
		{ label: connectionTypeText['_satellite'], value: 'satellite' },
	]

	return (
		<>
			<Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						flex: 1,
						gridGap: 10,
						alignItems: 'center',
					}}
				>
					<Select
						placeholder={t('target_1_connection_type')}
						maxTagCount={'responsive'}
						allowClear
						mode='multiple'
						style={{ width: '100%' }}
						options={connectionTypes}
						tagRender={({ label, value, closable, onClose }) => {
							const onPreventMouseDown = event => {
								event.preventDefault()
								event.stopPropagation()
							}

							return (
								<Tag
									key={`${label}-${value}`}
									onMouseDown={onPreventMouseDown}
									closable={closable}
									onClose={onClose}
									style={{
										marginInlineEnd: 4,
										display: 'flex',
									}}
								>
									<Space style={{ gridGap: '4px', mr: '4px' }}>{label}</Space>
								</Tag>
							)
						}}
						onChange={newValue => {
							setValue(
								newValue.map(val => ({
									value: val,
								}))
							)
						}}
					/>
				</Box>

				<IncludeExcludeBtn include={include} exclude={exclude} />
			</Box>
		</>
	)
}

export default ConnectionType
