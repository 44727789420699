import React, { createContext, useContext, useState } from 'react'
export const BannerContext = createContext()

export function BannerProvider({ children }) {
	const [bnr, setBnr] = useState({})

	const updateBanner = (key, newValue) => {
		setBnr(current => ({ ...current, [key]: newValue }))
	}

	const replaceBanner = newValue => {
		setBnr(newValue)
	}

	return (
		<BannerContext.Provider value={{ bnr, updateBanner, replaceBanner }}>
			{children}
		</BannerContext.Provider>
	)
}

export function useBnr() {
	const context = useContext(BannerContext)
	if (!context) {
		throw new Error('useBrn must be used within a Banner')
	}
	return context
}
