import * as React from 'react'

import { Box, Sheet } from '@mui/joy'
import Navigation from './Navigation'
import ButtonBar from './ButtonBar'
import UserBar from './UserBar'
import AdAngleLogo from './AdAngleLogo'

export default function FirstSidebar() {
	const sx = {
		position: {
			md: 'sticky',
		},
		transform: {
			md: 'none',
		},
		zIndex: 10000,
		height: '100dvh',
		width: 'var(--Sidebar-width)',
		overflowY: 'auto',
		p: 0,
		py: 3,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: 2,
		borderRight: '1px solid',
		borderColor: 'divider',
		overflow:'visible'
	}

	return (
		<>
			<Sheet variant='soft' color='primary' invertedColors sx={sx}>
				<Box
					sx={{
						mt: 2,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						flex: 1,
					}}
				>
					<AdAngleLogo />
					<Navigation />
				</Box>
				<ButtonBar />
				<UserBar />
			</Sheet>
		</>
	)
}
