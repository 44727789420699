import * as React from 'react'

import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

const IncludeExclude = ({ include, exclude }) => {
	const { t } = useTranslation()

	const IncludeBtn = ({ include }) => (
		<Button type='primary' onClick={include}>
			{t('include')}
		</Button>
	)

	const ExcludeBtn = ({ exclude }) => (
		<Button danger onClick={exclude}>
			{t('exclude')}
		</Button>
	)

	return (
		<>
			<IncludeBtn include={include} />
			<ExcludeBtn exclude={exclude} />
		</>
	)
}

export default IncludeExclude
