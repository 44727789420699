import * as React from 'react'
import Routers from './Routers'

const Filter = () => {
	return (
		<>
			<Routers />
		</>
	)
}

export default Filter
