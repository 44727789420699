import React, { createContext, useContext, useState, useCallback } from 'react'
export const ZonesContext = createContext()

export function ZonesProvider({ children }) {
	const [zone, setZone] = useState({})

	const updateZones = (key, newValue) => {
		setZone(current => ({ ...current, [key]: newValue }))
	}

	const replaceZones = useCallback(newValue => {
		if (newValue) setZone(newValue)
	}, [])

	return (
		<ZonesContext.Provider value={{ zone, updateZones, replaceZones }}>
			{children}
		</ZonesContext.Provider>
	)
}

export function useZone() {
	const context = useContext(ZonesContext)
	if (!context) {
		throw new Error('useZone must be used within a Zone')
	}
	return context
}
