import React from 'react'

import { Box, Typography, Tooltip, IconButton } from '@mui/joy'
import {
	VerifiedRounded,
	QueueRounded,
	ReplayRounded,
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import Button from './Button'

const Header = () => {
	const { t } = useTranslation()
	const [cookie, setCookie] = useCookies(['reload', 'advertisersListActive'])
	const text = t('sidebar_navigation', {
		returnObjects: true,
	})

	const onChangeAdvertActive = value => {
		setCookie('advertisersListActive', value ? value : 'all', { path: '/' })
	}

	const ActiveBtn = () => (
		<Tooltip
			arrow
			size={'sm'}
			placement='top'
			color='primary'
			title={
				cookie.advertisersListActive === 'all'
					? t('hide_inactive_advertisers')
					: t('show_inactive_advertisers')
			}
			sx={{ px: 1 }}
		>
			<IconButton
				size='sm'
				sx={{
					minHeight: '25px',
					minWidth: '25px',
					display: 'flex',
					[`&.active svg`]: {
						color:
							'var(--joy-palette-success-solidBg, var(--joy-palette-success-500, #1F7A1F))',
					},
				}}
				onClick={() => {
					onChangeAdvertActive(
						cookie.advertisersListActive === 'all' ? 'active' : 'all'
					)
					setCookie('reload', new Date().getTime(), { path: '/' })
				}}
				className={cookie.advertisersListActive !== 'all' && 'active'}
			>
				<VerifiedRounded sx={{ fontSize: 15 }} />
			</IconButton>
		</Tooltip>
	)

	return (
		<Box
			sx={{
				pl: 2,
				pr: 1,
				pb: 1,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Typography
				role='presentation'
				sx={{
					color: 'text.primary',
					fontSize: '12px',
					textTransform: 'uppercase',
					letterSpacing: '2px',
					flex: 1,
				}}
			>
				{text['advertisers']}
			</Typography>

			<ActiveBtn />

			<Button
				tooltip={t('refresh_advertisers')}
				onClick={() => setCookie('reload', new Date().getTime(), { path: '/' })}
				component={<ReplayRounded sx={{ fontSize: 15 }} />}
			/>

			<Button
				tooltip={t('add_advertiser')}
				path={'/advertisers/add'}
				component={<QueueRounded sx={{ fontSize: 15 }} />}
			/>
		</Box>
	)
}

export default Header
