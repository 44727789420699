import React from 'react'

import { Box, Sheet, Button, Tooltip } from '@mui/joy'
import { ReplayRounded } from '@mui/icons-material'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import SearchAndAddPrice from './SearchAndAddPrice'

const InputBar = () => {
	const { t } = useTranslation()
	const [, setCookie] = useCookies(['reload'])

	return (
		<Sheet
			sx={() => ({
				mt: '0',
				mx: '-5px',
				display: 'flex',
				backgroundColor: 'transparent',
			})}
		>
			<Box sx={{ display: 'flex', width: '90%' }}>
				<SearchAndAddPrice />
			</Box>

			<Box sx={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
				<Tooltip
					arrow
					size='sm'
					placement='top'
					color='primary'
					title={t('refresh_advertisers_sharing_settings')}
					sx={{ px: 1 }}
				>
					<Button
						size='sm'
						color='primary'
						variant='outlined'
						sx={{ ml: 2, height: '100%', px: 1 }}
						onClick={e => {
							setCookie('reload', new Date().getTime(), { path: '/' })
						}}
					>
						<ReplayRounded />
					</Button>
				</Tooltip>
			</Box>
		</Sheet>
	)
}

export default InputBar
