import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ZonesState {
	addData: {
		id: number | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	editData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	deleteData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	getData: {
		publisher_id: number | null
		id: number | null
		rotation_id: number | null
		active: number | null
		name: string | null

		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	getAllData: {
		rows: object | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	activeData: {
		isLoading: boolean
		id: number | null
		active: number | null
		success: boolean | null
		error: string | boolean | null
	}

	pricesAddData: {
		id: number | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	pricesDeleteData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	pricesGetAllData: {
		rows: object | null
		delete: boolean
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	trackerAddData: {
		tracking_id: number | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	trackerDeleteData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	trackerGetAllData: {
		rows: object | null
		delete: boolean
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}
}

const initialState: ZonesState = {
	addData: {
		id: null,
		isLoading: false,
		success: false,
		error: null,
	},

	editData: {
		success: false,
		isLoading: false,
		error: null,
	},

	deleteData: {
		success: false,
		isLoading: false,
		error: null,
	},

	getData: {
		publisher_id: null,
		id: null,
		rotation_id: null,
		active: null,
		name: null,

		isLoading: false,
		success: false,
		error: null,
	},

	getAllData: {
		rows: [],
		isLoading: false,
		success: false,
		error: null,
	},

	activeData: {
		isLoading: false,
		id: null,
		active: null,
		success: false,
		error: null,
	},

	pricesAddData: {
		id: null,
		isLoading: false,
		success: false,
		error: null,
	},

	pricesDeleteData: {
		success: false,
		isLoading: false,
		error: null,
	},

	pricesGetAllData: {
		rows: [],
		delete: false,
		isLoading: false,
		success: false,
		error: null,
	},

	trackerAddData: {
		tracking_id: null,
		isLoading: false,
		success: false,
		error: null,
	},

	trackerDeleteData: {
		success: false,
		isLoading: false,
		error: null,
	},

	trackerGetAllData: {
		rows: [],
		delete: false,
		isLoading: false,
		success: false,
		error: null,
	},
}

export const reducer = createSlice({
	name: 'zones',
	initialState,
	reducers: {
		// Add zones
		zoneAddStart: (state): ZonesState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		zoneAddSuccess: (state, action: PayloadAction<any>): ZonesState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: false,
				success: true,
				error: false,
				id: action.payload.id,
			},
		}),
		zoneAddFailure: (state, action: PayloadAction<string>): ZonesState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Edit zones
		zoneEditStart: (state): ZonesState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: true,
				success: false,
				error: null,
			},
		}),
		zoneEditSuccess: (state, action: PayloadAction<void>): ZonesState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		zoneEditFailure: (state, action: PayloadAction<string>): ZonesState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Delete zones
		zoneDeleteStart: (state): ZonesState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		zoneDeleteSuccess: (state, action: PayloadAction<void>): ZonesState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		zoneDeleteFailure: (state, action: PayloadAction<string>): ZonesState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Get All zones
		zoneGetAllStart: (state): ZonesState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		zoneGetAllSuccess: (state, action: PayloadAction<any>): ZonesState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: true,
				error: false,
				rows: action.payload.result.rows,
			},
		}),
		zoneGetAllFailure: (state, action: PayloadAction<string>): ZonesState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: false,
				error: action.payload,
				rows: [],
			},
		}),

		// Get zone by id
		zoneGetStart: (state): ZonesState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		zoneGetSuccess: (state, action: PayloadAction<any>): ZonesState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: true,
				error: false,
				...action.payload.result.rows,
			},
		}),
		zoneGetFailure: (state, action: PayloadAction<string>): ZonesState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Set active
		zoneSetActiveStart: (state): ZonesState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		zoneSetActiveSuccess: (state, action: PayloadAction<void>): ZonesState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		zoneSetActiveFailure: (
			state,
			action: PayloadAction<string>
		): ZonesState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Add zone prices
		zonePricesAddStart: (state): ZonesState => ({
			...state,
			pricesAddData: {
				...state.pricesAddData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		zonePricesAddSuccess: (state, action: PayloadAction<any>): ZonesState => ({
			...state,
			pricesAddData: {
				...state.pricesAddData,
				isLoading: false,
				success: true,
				error: false,
				id: action.payload.id,
			},
		}),
		zonePricesAddFailure: (
			state,
			action: PayloadAction<string>
		): ZonesState => ({
			...state,
			pricesAddData: {
				...state.pricesAddData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Delete zone prices
		zonePricesDeleteStart: (state): ZonesState => ({
			...state,
			pricesDeleteData: {
				...state.pricesDeleteData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		zonePricesDeleteSuccess: (
			state,
			action: PayloadAction<void>
		): ZonesState => ({
			...state,
			pricesDeleteData: {
				...state.pricesDeleteData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		zonePricesDeleteFailure: (
			state,
			action: PayloadAction<string>
		): ZonesState => ({
			...state,
			pricesDeleteData: {
				...state.pricesDeleteData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Get All advertiser
		zonePricesGetAllStart: (state): ZonesState => ({
			...state,
			pricesGetAllData: {
				...state.pricesGetAllData,
				isLoading: true,
				success: false,
				delete: false,
				error: false,
			},
		}),
		zonePricesGetAllSuccess: (
			state,
			action: PayloadAction<any>
		): ZonesState => ({
			...state,
			pricesGetAllData: {
				...state.pricesGetAllData,
				isLoading: false,
				success: true,
				error: false,
				delete: action.payload.result.delete,
				rows: action.payload.result.rows,
			},
		}),
		zonePricesGetAllFailure: (
			state,
			action: PayloadAction<string>
		): ZonesState => ({
			...state,
			pricesGetAllData: {
				...state.pricesGetAllData,
				isLoading: false,
				success: false,
				delete: false,
				error: action.payload,
				rows: [],
			},
		}),

		// Add zone prices
		zoneTrackerAddStart: (state): ZonesState => ({
			...state,
			trackerAddData: {
				...state.trackerAddData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		zoneTrackerAddSuccess: (state, action: PayloadAction<any>): ZonesState => ({
			...state,
			trackerAddData: {
				...state.trackerAddData,
				isLoading: false,
				success: true,
				error: false,
				tracking_id: action.payload.tracking_id,
			},
		}),
		zoneTrackerAddFailure: (
			state,
			action: PayloadAction<string>
		): ZonesState => ({
			...state,
			trackerAddData: {
				...state.trackerAddData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Delete zone prices
		zoneTrackerDeleteStart: (state): ZonesState => ({
			...state,
			trackerDeleteData: {
				...state.trackerDeleteData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		zoneTrackerDeleteSuccess: (
			state,
			action: PayloadAction<void>
		): ZonesState => ({
			...state,
			trackerDeleteData: {
				...state.trackerDeleteData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		zoneTrackerDeleteFailure: (
			state,
			action: PayloadAction<string>
		): ZonesState => ({
			...state,
			trackerDeleteData: {
				...state.trackerDeleteData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Get All advertiser
		zoneTrackerGetAllStart: (state): ZonesState => ({
			...state,
			trackerGetAllData: {
				...state.trackerGetAllData,
				isLoading: true,
				success: false,
				delete: false,
				error: false,
			},
		}),
		zoneTrackerGetAllSuccess: (
			state,
			action: PayloadAction<any>
		): ZonesState => ({
			...state,
			trackerGetAllData: {
				...state.trackerGetAllData,
				isLoading: false,
				success: true,
				error: false,
				delete: action.payload.result.delete,
				rows: action.payload.result.rows,
			},
		}),
		zoneTrackerGetAllFailure: (
			state,
			action: PayloadAction<string>
		): ZonesState => ({
			...state,
			trackerGetAllData: {
				...state.trackerGetAllData,
				isLoading: false,
				success: false,
				delete: false,
				error: action.payload,
				rows: [],
			},
		}),
	},
})

export const {
	zoneAddStart,
	zoneAddSuccess,
	zoneAddFailure,
	zoneEditStart,
	zoneEditSuccess,
	zoneEditFailure,
	zoneDeleteStart,
	zoneDeleteSuccess,
	zoneDeleteFailure,
	zoneGetAllSuccess,
	zoneGetAllFailure,
	zoneGetAllStart,
	zoneGetStart,
	zoneGetSuccess,
	zoneGetFailure,
	zoneSetActiveStart,
	zoneSetActiveSuccess,
	zoneSetActiveFailure,

	zonePricesAddStart,
	zonePricesAddSuccess,
	zonePricesAddFailure,
	zonePricesDeleteStart,
	zonePricesDeleteSuccess,
	zonePricesDeleteFailure,
	zonePricesGetAllStart,
	zonePricesGetAllSuccess,
	zonePricesGetAllFailure,

	zoneTrackerAddStart,
	zoneTrackerAddSuccess,
	zoneTrackerAddFailure,
	zoneTrackerDeleteStart,
	zoneTrackerDeleteSuccess,
	zoneTrackerDeleteFailure,
	zoneTrackerGetAllStart,
	zoneTrackerGetAllSuccess,
	zoneTrackerGetAllFailure,
} = reducer.actions

export default reducer.reducer
