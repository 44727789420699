import React, { useState } from 'react'

import Form from './Form'
import Submit from './Submit'

import { Box } from '@mui/joy'
import { toast } from '../../../../layout/JoyToaster'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../../store'
import { bannerAdd } from '../../../../../store/banners'
import { useCookies } from 'react-cookie'
import { useParams } from 'react-router-dom'

const Add = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const advertiserId = parseInt(useParams().pageId)
	const campaignId = parseInt(useParams().campaignId)

	const defaultFormValues = {
		advertiser_id: advertiserId,
		campaign_id: campaignId,
		active: null,
		name: '',
		url: '',
		metadata: { upload: 0 },
		creative: '',
	}

	const [, setCookie] = useCookies(['reload'])
	const [formValues, setFormValues] = useState(defaultFormValues)

	const handlerForm = event => {
		event.preventDefault()

		dispatch(bannerAdd(formValues)).then(result => {
			if (result) {
				result
					? toast.success(t('add_campaign_added'))
					: toast.error(t('error'))
				setCookie('reload', new Date().getTime(), { path: '/' })
			}
		})
	}

	return (
		<Box
			sx={{
				maxWidth: 'clamp(450px, 100dvw, 900px)',
			}}
		>
			<form onSubmit={handlerForm}>
				<Form values={{ set: setFormValues, arr: formValues }} />

				<Submit values={formValues} onSubmit={handlerForm} />
			</form>
		</Box>
	)
}

export default Add
