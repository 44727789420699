import React, { useState } from 'react'
import { Box } from '@mui/joy'
import { Space } from 'antd'
import MaskInput from 'antd-mask-input'

import { useFilter } from '../../../../../hooks/filter'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const IpRange = () => {
	const { updateFilter } = useFilter()
	const [valueFrom, setValueFrom] = useState('255.255.255.0')
	const [valueTo, setValueTo] = useState('255.255.255.254')

	const include = () => {
		updateFilter('in_ip_range', [
			{ value: `${valueFrom}-${valueTo}`, from: valueFrom, to: valueTo },
		])
	}

	const exclude = () => {
		updateFilter('ex_ip_range', [
			{ value: `${valueFrom}-${valueTo}`, from: valueFrom, to: valueTo },
		])
	}

	return (
		<Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
			<Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', gridGap: 10 }}>
				<Space.Compact block>
					<MaskInput
						mask={'0[0][0].0[0][0].0[0][0].0[0][0]'}
						value={valueFrom}
						onChange={e => setValueFrom(e.target.value)}
						placeholder='From: 255.255.255.0'
						size='middle'
						className='ant-input-compact-item ant-input-compact-first-item'
					/>
					<MaskInput
						mask={'0[0][0].0[0][0].0[0][0].0[0][0]'}
						value={valueTo}
						onChange={e => setValueTo(e.target.value)}
						placeholder='To: 255.255.255.254'
						size='middle'
						className='ant-input-compact-item ant-input-compact-last-item'
					/>
				</Space.Compact>
			</Box>

			<IncludeExcludeBtn include={include} exclude={exclude} />
		</Box>
	)
}

export default IpRange
