import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	IZonesGetAllRequest,
	IZonesGetAllResponse,
	IZonesGetRequest,
	IZonesGetResponse,
	IZonesPricesGetAllRequest,
	IZonesPricesGetAllResponse,
	IZonesTrackerGetAllRequest,
	IZonesTrackerGetAllResponse,
} from './types'

export const getAll = (
	params: IZonesGetAllRequest
): AxiosPromise<IZonesGetAllResponse> =>
	axiosInstance.post(
		`${Endpoints.ZONES.GET_ALL}/${params.publisher_id}`,
		params
	)

export const get = (
	params: IZonesGetRequest
): AxiosPromise<IZonesGetResponse> =>
	axiosInstance.get(
		`${Endpoints.ZONES.GET}/${params.publisher_id}/${params.id}`
	)

export const pricesGetAll = (
	params: IZonesPricesGetAllRequest
): AxiosPromise<IZonesPricesGetAllResponse> =>
	axiosInstance.post(`${Endpoints.ZONES.PRICES_GET_ALL}`, params)

export const trackerGetAll = (
	params: IZonesTrackerGetAllRequest
): AxiosPromise<IZonesTrackerGetAllResponse> =>
	axiosInstance.post(`${Endpoints.ZONES.TRACKER_GET_ALL}`, params)
