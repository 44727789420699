import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import {
	IBannersAddRequest,
	IBannersTrackerAddRequest,
	IBannersUploadRequest,
} from '../../api/banners/add/types'

import {
	IBannersRemoveRequest,
	IBannersTrackerRemoveRequest,
} from '../../api/banners/delete/types'

import {
	IBannersGetAllRequest,
	IBannersGetRequest,
	IBannersTrackerGetAllRequest,
	IBannersCloudRequest,
} from '../../api/banners/get/types'

import {
	IBannersSetActiveRequest,
	IBannersEditRequest,
} from '../../api/banners/edit/types'

import {
	bannerAddStart,
	bannerAddSuccess,
	bannerAddFailure,
	bannerEditStart,
	bannerEditSuccess,
	bannerEditFailure,
	bannerDeleteStart,
	bannerDeleteSuccess,
	bannerDeleteFailure,
	bannerGetAllSuccess,
	bannerGetAllFailure,
	bannerGetAllStart,
	bannerGetStart,
	bannerGetSuccess,
	bannerGetFailure,
	bannerSetActiveStart,
	bannerSetActiveSuccess,
	bannerSetActiveFailure,
	bannerTrackerAddStart,
	bannerTrackerAddSuccess,
	bannerTrackerAddFailure,
	bannerTrackerDeleteStart,
	bannerTrackerDeleteSuccess,
	bannerTrackerDeleteFailure,
	bannerTrackerGetAllStart,
	bannerTrackerGetAllSuccess,
	bannerTrackerGetAllFailure,
	bannerCloudStart,
	bannerCloudSuccess,
	bannerCloudFailure,
	bannerUploadStart,
	bannerUploadSuccess,
	bannerUploadFailure,
} from './reducer'

export const bannerAdd =
	(data: IBannersAddRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(bannerAddStart())
			const result = await api.bannersAdd.create(data)
			dispatch(bannerAddSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(bannerAddFailure(e.message))
			return false
		}
	}

export const bannerDel =
	(data: IBannersRemoveRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(bannerDeleteStart())
			await api.bannersDel.remove(data)
			dispatch(bannerDeleteSuccess())
			return true
		} catch (e: any) {
			dispatch(bannerDeleteFailure(e.message))
			return false
		}
	}

export const bannerEdit =
	(data: IBannersEditRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(bannerEditStart())
			await api.bannersEdit.update(data)
			dispatch(bannerEditSuccess())
			return true
		} catch (e: any) {
			dispatch(bannerEditFailure(e.message))
			return false
		}
	}

export const bannerGet =
	(data: IBannersGetRequest) =>
	async (dispatch: Dispatch<any>): Promise<any> => {
		try {
			dispatch(bannerGetStart())
			const result = await api.bannersGet.get(data)
			dispatch(bannerGetSuccess({ result: result.data }))
			return result.data
		} catch (e: any) {
			dispatch(bannerGetFailure(e.message))
			return false
		}
	}

export const bannerGetAll =
	(data: IBannersGetAllRequest) =>
	async (dispatch: Dispatch<any>): Promise<Object | boolean> => {
		try {
			dispatch(bannerGetAllStart())
			const result = await api.bannersGet.getAll(data)
			dispatch(bannerGetAllSuccess({ result: result.data }))
			return result
		} catch (e: any) {
			dispatch(bannerGetAllFailure(e.message))
			return false
		}
	}

export const setActive =
	(data: IBannersSetActiveRequest) =>
	async (dispatch: Dispatch<any>): Promise<void> => {
		try {
			dispatch(bannerSetActiveStart())
			await api.bannersEdit.setActive(data)
			dispatch(bannerSetActiveSuccess())
		} catch (e: any) {
			dispatch(bannerSetActiveFailure(e.message))
		}
	}

export const bannerTrackerAdd =
	(data: IBannersTrackerAddRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(bannerTrackerAddStart())
			const result = await api.bannersAdd.trackerCreate(data)
			dispatch(bannerTrackerAddSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(bannerTrackerAddFailure(e.message))
			return false
		}
	}

export const bannerTrackerDel =
	(data: IBannersTrackerRemoveRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(bannerTrackerDeleteStart())
			await api.bannersDel.trackerRemove(data)
			dispatch(bannerTrackerDeleteSuccess())
			return true
		} catch (e: any) {
			dispatch(bannerTrackerDeleteFailure(e.message))
			return false
		}
	}

export const bannerTrackerGetAll =
	(data: IBannersTrackerGetAllRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(bannerTrackerGetAllStart())
			const result = await api.bannersGet.trackerGetAll(data)
			dispatch(bannerTrackerGetAllSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(bannerTrackerGetAllFailure(e.message))
			return false
		}
	}

export const cloud =
	(data: IBannersCloudRequest) =>
	async (dispatch: Dispatch<any>): Promise<Object | boolean | null> => {
		try {
			dispatch(bannerCloudStart())
			const result = await api.bannersGet.cloud(data)
			dispatch(bannerCloudSuccess({ result: result.data }))
			return result.data
		} catch (e: any) {
			dispatch(bannerCloudFailure(e.message))
			return false
		}
	}

export const upload =
	(data: IBannersUploadRequest) =>
	async (dispatch: Dispatch<any>): Promise<Object | boolean | null> => {
		try {
			dispatch(bannerUploadStart())
			const result = await api.bannersAdd.upload(data)
			dispatch(bannerUploadSuccess({ result: result.data }))
			return result.data
		} catch (e: any) {
			dispatch(bannerUploadFailure(e.message))
			return false
		}
	}
