import React, { useEffect } from 'react'
import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Add, CurrencyExchangeRounded } from '@mui/icons-material'
import { useAppDispatch } from '../../../../../../../store'
import { campaignPricesGetAll } from '../../../../../../../store/campaigns'

import Table from './Table'
import InputBar from './InputBar'

const Additional = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [cookie] = useCookies(['reload'])
	const advertiser_id = parseInt(useParams().pageId)
	const campaign_id = parseInt(useParams().campaignId)
	useEffect(() => {
		dispatch(
			campaignPricesGetAll({
				campaign_id,
				advertiser_id,
			})
		)
	}, [cookie.reload, campaign_id, advertiser_id, dispatch])

	return (
		<Accordion sx={{ border: 'none' }}>
			<AccordionSummary indicator={<Add />} onClick={e => e.preventDefault()}>
				<CurrencyExchangeRounded />
				<Box sx={{ flex: 1, fontSize: '13px' }}>{t('campaign_prices')}</Box>
			</AccordionSummary>
			<AccordionDetails>
				<InputBar />
				<Table />
			</AccordionDetails>
		</Accordion>
	)
}

export default Additional
