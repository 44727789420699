import React, { useRef, useState, useEffect } from 'react'
import { Box, Sheet, Table, CircularProgress } from '@mui/joy'
import { useReport } from '../../../../../hooks/report'

import Head from './Head'
import Body from './Body'
import Footer from './Footer'
import OptionBar from './OptionBar'
import ControlBar from './СontrolBar'
import TotalBar from './TotalBar'

const Content = ({ value, isSplit = false }) => {
	const { report } = useReport()
	const [currentTableRef, setCurrentTableRef] = useState(null)
	const tableRef = useRef(null)

	const sheetSx = {
		border: '1px solid',
		borderColor: 'divider',
		transition: 'transform 0.4s',
		backgroundColor: 'transparent',
		overflow: 'hidden',
		maxHeight: 'calc(calc(100vh - var(--Topbar-height)) - 170px)',
		overflowY: 'auto',
		borderLeft: 'none',
		borderRight: 'none',
	}

	const tableSx = {
		'--Table-headerUnderlineThickness': '1px',
		'--TableCell-height': '30px',
		'--TableCell-paddingX': '15px',
		'--TableCell-paddingY': '3px',
		...(isSplit ? { fontSize: 12 } : {}),
		[`& thead th`]: {
			verticalAlign: 'inherit',
		},
	}

	useEffect(() => {
		if (tableRef.current) {
			setCurrentTableRef(tableRef.current)
		}
	}, [report])

	return report && report[value.name] ? (
		<>
			<TotalBar name={value.name} />
			<OptionBar name={value.name} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<ControlBar name={value.name} tableRef={currentTableRef} />
				<Box>
					<Sheet variant='outlined' sx={sheetSx}>
						<Table
							ref={tableRef}
							id={'table'}
							size={'sm'}
							borderAxis={'xBetween'}
							stickyHeader
							stickyFooter
							hoverRow
							sx={tableSx}
						>
							<Head name={value.name} />
							<Body name={value.name} />
							<Footer name={value.name} />
						</Table>
					</Sheet>
				</Box>
			</Box>
		</>
	) : (
		<Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
			<CircularProgress size='sm' />
		</Box>
	)
}

export default Content
