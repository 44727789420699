import React, { useState } from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Add, PermMediaRounded } from '@mui/icons-material'
import { Input, Switch } from 'antd'
import { useTranslation } from 'react-i18next'
import { useBnr } from '../../../../../../hooks/banners'
import FormItem from '../../../../../../layout/FormItem'

const Basic = () => {
	const { t } = useTranslation()
	const { bnr, updateBanner } = useBnr()
	const active = parseInt(bnr.active)
	const [index, setIndex] = useState(0)

	return (
		<Accordion
			expanded={index === 0}
			onChange={(_, expanded) => {
				setIndex(expanded ? 0 : null)
			}}
		>
			<AccordionSummary indicator={<Add />} onClick={e => e.preventDefault()}>
				<PermMediaRounded />
				<Box sx={{ flex: 1, fontSize: '13px' }}>
					{t('add_advertiser_basic')}
				</Box>
			</AccordionSummary>

			<AccordionDetails>
				<FormItem
					text={'name'}
					component={
						<Input
							style={{ width: '100%' }}
							value={bnr.name || ''}
							onChange={e => {
								updateBanner('name', e.target.value)
							}}
						/>
					}
				/>

				<FormItem
					text={'add_advertiser_basic_active'}
					component={
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Switch
								size='small'
								checked={active === 1}
								onChange={e => {
									updateBanner('active', e ? 1 : 0)
								}}
							/>
						</Box>
					}
				/>
			</AccordionDetails>
		</Accordion>
	)
}

export default Basic
