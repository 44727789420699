import React, { useState, useEffect } from 'react'
import { Box, Sheet, Typography, Divider, Chip, ChipDelete } from '@mui/joy'
import { useTranslation } from 'react-i18next'
import { random, updateObjToArrItem } from '../utils'

import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'
import WeekAndTime from './WeekAndTime'

import { ConfigProvider, DatePicker } from 'antd'
import localeRu from 'antd/locale/ru_RU'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

const { RangePicker } = DatePicker

const DateAndTime = ({ rotation, current }) => {
	const { t, i18n } = useTranslation()

	const dateFormat = 'YYYY-MM-DD HH:mm:00'
	const [dateFrom, setDateFrom] = useState(dayjs())
	const [dateTo, setDateTo] = useState(dayjs())
	const [now, setNow] = useState(dayjs())

	useEffect(() => {
		setNow(dayjs())
	}, [])


	const targets = current.val.targets
	const value = [
		{
			from: dayjs(dateFrom).format(dateFormat),
			to: dayjs(dateTo).format(dateFormat),
		},
	]

	const arrayUniq = arr => {
		const map = new Map()

		arr.forEach(item => {
			const key = `${item.from}-${item.to}`
			if (!map.has(key)) {
				map.set(key, item)
			}
		})

		return Array.from(map.values())
	}

	const handleDateChange = actionType => {
		const targetKey = actionType === 'include' ? 'date' : 'ex_date'
		const updatedValue = arrayUniq(
			targets && targetKey in targets
				? [...targets[targetKey], ...value]
				: [...value]
		)

		return updateObjToArrItem({
			rotation,
			current,
			value: updatedValue,
			target_name: targetKey,
		})
	}

	const removeItem = (rotation, item_id, val, type) => {
		const updatedRotation = rotation.map(item => {
			if (item.item_id === item_id) {
				const index = item.targets[type]?.indexOf(val)
				if (index > -1) {
					item.targets[type].splice(index, 1)
				}
			}

			if (item.items) {
				item.items = removeItem(item.items, item_id, val, type)
			}

			return item
		})

		const index = current.val.targets[type]?.indexOf(val)
		if (index > -1) {
			current.val.targets[type].splice(index, 1)
			current.set(current.val)
		}

		return updatedRotation
	}

	const removeElement = (item_id, val, type) => {
		rotation.set(removeItem(rotation.val, item_id, val, type))
	}

	const onDatesChange = (_, dateString) => {
		setDateFrom(dateString[0])
		setDateTo(dateString[1])
	}

	const renderChips = (items, color, type) => {
		return items.map(i => (
			<Chip
				key={random()}
				sx={{ mr: 1, mb: 1, px: 2 }}
				size={'lg'}
				variant='soft'
				color={color}
				endDecorator={
					<ChipDelete
						onDelete={() => removeElement(current.val.item_id, i, type)}
					/>
				}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						gridGap: '5px',
						pr: 1,
						fontSize: 14,
					}}
				>
					{`${i.from ? `${t('from')}: ${i.from}` : ''}`}
					{`${i.from && i.to ? ' - ' : ''}`}
					{`${i.to ? `${t('to')}: ${i.to}` : ''}`}
				</Box>
			</Chip>
		))
	}

	const disabledDate = current => {
		return current && current < dayjs().startOf('day')
	}

	const disabledTime = (date) => {
		if (date && date.isBefore(now, 'day')) {
			return {
				disabledHours: () => [],
				disabledMinutes: () => [],
				disabledSeconds: () => []
			}
		} else if (date && date.isSame(now, 'day')) {
			return {
				disabledHours: () => Array.from({ length: 24 }, (_, i) => i).splice(0, now.hour()),
				disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i).splice(0, now.minute()),
				disabledSeconds: () => Array.from({ length: 60 }, (_, i) => i).splice(0, now.second())
			}
		}
		return {
			disabledHours: () => [],
			disabledMinutes: () => [],
			disabledSeconds: () => []
		}
	}

	return (
		<>
			<Typography sx={{ mb: 2, fontWeight: 600, ml: 1 }} level='body-sm'>
				{t('target_1_day_and_time')}
			</Typography>
			<Sheet
				sx={theme => ({
					mt: '0',
					borderRadius: '8px',
					p: 2,
					mb: 2.5,
					display: 'flex',
					[theme.getColorSchemeSelector('light')]: {
						backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
					},
					[theme.getColorSchemeSelector('dark')]: {
						backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
					},
				})}
			>
				<Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							flex: 1,
							gridGap: 10,
							alignItems: 'center',
						}}
					>
						<ConfigProvider locale={i18n.language === 'ru' ? localeRu : {}}>
							<RangePicker
								showTime
								style={{ flex: 1 }}
								defaultValue={[dateFrom, dateTo]}
								format={dateFormat}
								onChange={onDatesChange}
								disabledDate={disabledDate}
								disabledTime={disabledTime}
							/>
						</ConfigProvider>
					</Box>
					<IncludeExcludeBtn
						include={() => {
							handleDateChange('include')
						}}
						exclude={() => {
							handleDateChange('exclude')
						}}
					/>
				</Box>
			</Sheet>
			{(targets?.date?.length > 0 || targets?.ex_date?.length > 0) && (
				<Box sx={{ mb: 2 }}>
					{targets?.date?.length > 0 &&
						renderChips(targets.date, 'primary', 'date')}
					{targets?.ex_date?.length > 0 &&
						renderChips(targets.ex_date, 'danger', 'ex_date')}
				</Box>
			)}
			<Divider sx={{ mb: 2.5 }} />
			<Typography sx={{ mb: 2, fontWeight: 600, ml: 1 }} level='body-sm'>
				{t('target_1_week_and_time')}
			</Typography>
			<WeekAndTime rotation={rotation} current={current} />
		</>
	)
}

export default DateAndTime
