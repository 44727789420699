import React, { useEffect } from 'react'

import { useCookies } from 'react-cookie'
import { useAppDispatch } from '../../../../../store'
import { getAllCategories } from '../../../../../store/categories'

import Container from './Container'

const CustomTable = () => {
	const dispatch = useAppDispatch()
	const [cookie] = useCookies(['reload'])

	useEffect(() => {
		dispatch(getAllCategories())
	}, [cookie.reload, dispatch])

	return <Container />
}

export default CustomTable
