import React, { useState } from 'react'

import Box from '@mui/joy/Box'
import Sheet from '@mui/joy/Sheet'
import AddIcon from '@mui/icons-material/Add'
import Accordion from '@mui/joy/Accordion'
import AccordionGroup from '@mui/joy/AccordionGroup'
import AccordionDetails, {
	accordionDetailsClasses,
} from '@mui/joy/AccordionDetails'
import AccordionSummary, {
	accordionSummaryClasses,
} from '@mui/joy/AccordionSummary'

export const AccordItem = ({ icon, last, text, items }) => {
	const [index, setIndex] = useState(1)

	return (
		<Accordion
			sx={{ ...(last && { borderBottom: 0 }) }}
			expanded={index === 0}
			onChange={(event, expanded) => {
				setIndex(expanded ? 0 : null)
			}}
		>
			<AccordionSummary
				indicator={<AddIcon />}
				onClick={e => e.preventDefault()}
			>
				{icon}
				<Box sx={{ flex: 1, fontSize: '13px' }}>{text}</Box>
			</AccordionSummary>
			<AccordionDetails>{items}</AccordionDetails>
		</Accordion>
	)
}

export const Accord = ({ items }) => {
	const accordionStyles = {
		[`& .${accordionSummaryClasses.indicator}`]: {
			transition: '0.2s',
		},
		[`& [aria-expanded="true"] .${accordionSummaryClasses.indicator}`]: {
			transform: 'rotate(45deg)',
		},
		[`& .${accordionSummaryClasses.button}:hover`]: {
			bgcolor: 'transparent',
		},
		[`& .${accordionDetailsClasses.content}`]: {
			gridGap: '10px',
			boxShadow: theme => `inset 0 1px ${theme.vars.palette.divider}`,
			padding: '1.2rem',
			paddingBlock: '1rem',
		},
		[`& .${accordionSummaryClasses.button}`]: {
			padding: '7px 15px',
		},
		[`& .${accordionSummaryClasses.button}:last-child`]: {
			borderBottom: 'none',
		},
	}

	return (
		<Sheet
			variant='outlined'
			sx={{
				borderLeft: 'none',
				borderRight: 'none',
				borderBottom: 'none',
				borderRadius: 0,
				// borderColor: 'divider',
				transition: 'transform 0.4s',
				backgroundColor: 'transparent',
				overflow: 'hidden',
			}}
		>
			<AccordionGroup size={'sm'} sx={accordionStyles}>
				{items}
			</AccordionGroup>
		</Sheet>
	)
}
