import React from 'react'

import FormContainer from './FormContainer'

import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { toast } from '../../../../../layout/JoyToaster'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../../../store'
import { bannerEdit } from '../../../../../../store/banners'
import { useBnr } from '../../../../../hooks/banners'

const BaseInformation = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const { bnr } = useBnr()
	const [, setCookie] = useCookies(['reload'])
	const advertiserId = parseInt(useParams().pageId)

	const handlerForm = event => {
		event.preventDefault()

		dispatch(
			bannerEdit({
				advertiser_id: advertiserId,
				banner_id: bnr.banner_id,
				active: bnr.active,
				name: bnr.name,
				url: bnr.url,
				creative: bnr.creative,
				metadata: bnr.metadata,
			})
		).then(result => {
			if (result) {
				result
					? toast.success(t('edit_banner_successfully'))
					: toast.error(t('error'))

				setCookie('reload', new Date().getTime(), { path: '/' })
			}
		})
	}

	return (
		<form onSubmit={handlerForm}>
			<FormContainer />
		</form>
	)
}

export default BaseInformation
