import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	IRotationsGetAllResponse,
	IRotationsGetAllRequest,
	IRotationsGetRequest,
	IRotationsGetResponse,
} from './types'

export const getAll = (
	params: IRotationsGetAllRequest
): AxiosPromise<IRotationsGetAllResponse> =>
	axiosInstance.post(`${Endpoints.ROTATIONS.GET_ALL}`, params)

export const get = (
	params: IRotationsGetRequest
): AxiosPromise<IRotationsGetResponse> =>
	axiosInstance.get(`${Endpoints.ROTATIONS.GET}/${params.id}`)
