import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	IPublishersSetActiveRequest,
	IPublishersSetActiveResponse,
	IPublishersEditRequest,
	IPublishersEditResponse,
} from './types'

export const setActive = (
	params: IPublishersSetActiveRequest
): AxiosPromise<IPublishersSetActiveResponse> =>
	axiosInstance.patch(Endpoints.PUBLISHERS.SET_ACTIVE, params)

export const update = (
	params: IPublishersEditRequest
): AxiosPromise<IPublishersEditResponse> =>
	axiosInstance.put(Endpoints.PUBLISHERS.EDIT, params)
