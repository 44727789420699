import React from 'react'

import { DeleteRounded } from '@mui/icons-material'
import { Button, Popconfirm } from 'antd'

import { toast } from '../../../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../../../store'
import { sharingDel } from '../../../../../../../store/advertisers'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const Body = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const { pageId } = useParams()
	const advertiser_id = parseInt(pageId)
	const [, setCookie] = useCookies(['reload'])

	const levels = {
		1: t('user_sharing_setting_is_owner'),
		2: t('user_sharing_setting_can_edit'),
		3: t('user_sharing_setting_can_view'),
	}

	const deleteAdvert = deleteUserId => {
		if (deleteUserId) {
			dispatch(sharingDel({ user_id: deleteUserId, advertiser_id })).then(
				result => {
					result
						? toast.success(t('delete_sharing_successfully'))
						: toast.error(t('error'))

					setCookie('reload', new Date().getTime(), { path: '/' })
				}
			)
		}
	}

	const rows = useSelector(state => state.advertisers?.sharingGetData?.rows)
	const del = useSelector(state => state.advertisers?.sharingGetData?.delete)

	return (
		<tbody>
			{rows &&
				rows.map(item => {
					return (
						<tr
							key={`${item.advertiser_id}-${item.user_id}-${item.sharing_level}`}
							className='delete-container-retarget'
						>
							<td>{item.user_email}</td>
							<td>{levels[item.sharing_level] || '---'}</td>
							<td style={{ paddingLeft: 0, paddingRight: 0 }}>
								{del && (
									<Popconfirm
										placement='topRight'
										style={{ maxWidth: '300px' }}
										title={t('delete_dialog_title')}
										description={t('delete_dialog_sharing')}
										okText={t('delete_dialog_confirm')}
										cancelText={t('delete_dialog_cancel')}
										onConfirm={() => {
											deleteAdvert(item.user_id)
										}}
									>
										<Button
											size='small'
											className='delete-btn-retarget'
											danger
											style={{ padding: 3 }}
										>
											<DeleteRounded
												style={{ fontSize: 16, color: '#dc4446' }}
											/>
										</Button>
									</Popconfirm>
								)}
							</td>
						</tr>
					)
				})}
		</tbody>
	)
}

export default Body
