import Endpoints from "../../endpoints"
import {AxiosPromise} from "axios"
import {axiosInstance} from "../../instance"
import { IAdvertisersGetAllRequest, IAdvertisersGetAllResponse,
				 IAdvertisersGetAllSharingRequest,
				 IAdvertisersGetRequest, IAdvertisersGetResponse} from "./types"

export const getAllSharings = (params: IAdvertisersGetAllSharingRequest): 
	AxiosPromise<IAdvertisersGetAllResponse> => axiosInstance.get(`${Endpoints.ADVERTISERS.SHARING_GET}/${params.advertiser_id}`)

export const getAll = (params: IAdvertisersGetAllRequest): 
	AxiosPromise<IAdvertisersGetAllResponse> => axiosInstance.post(Endpoints.ADVERTISERS.GET_ALL, params)

export const get = (params: IAdvertisersGetRequest): 
	AxiosPromise<IAdvertisersGetResponse> => axiosInstance.get(`${Endpoints.ADVERTISERS.GET}/${params.advertiser_id}`)
