import React from 'react'
import { useReport } from '../../../../../hooks/report'

const Footer = ({ name }) => {
	const { report } = useReport()
	const totals = report && report[name] && report[name].totals
	const { options: { columns = [], unique = '0' } = {} } = report[name] || {}
	const isAvailable = key => !columns.includes(key)

	const Item = ({ value, item }) =>
		isAvailable(item) && <td style={{ textAlign: 'end' }}>{value}</td>

	const tableD = row => {
		const columns = Object.keys(row)
			.map(key => {
				if (
					!['clicks', 'count', 'unique', 'ctr', 'spend', 'frequency'].includes(
						key
					)
				) {
					return isAvailable(key) ? <td key={key}></td> : null
				}
				return false
			})
			.filter(i => !!i)

		return (
			<tr key={`${Object.values(row).join('-')}-${Object.keys(row).join('-')}`}>
				{!!columns.length && columns}

				<Item item={`count`} value={row.count.toLocaleString('ru-RU')} />
				<Item item={`clicks`} value={row.clicks.toLocaleString('ru-RU')} />
				<Item item={`ctr`} value={`${row.ctr ? row.ctr.toFixed(2) : 0}%`} />
				{unique === '1' && (
					<>
						<Item item={`unique`} value={row.unique.toLocaleString('ru-RU')} />
						<Item
							item={`frequency`}
							value={row.frequency.toLocaleString('ru-RU')}
						/>
					</>
				)}

				<Item
					item={`spend`}
					value={`$${row.spend ? row.spend.toFixed(2) : '0'}`}
				/>
			</tr>
		)
	}

	return <tfoot>{totals.length && totals.map(item => tableD(item))}</tfoot>
}
export default Footer
