import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import {
	ICustomFieldsAddRequest,
	ICustomParamsAddRequest,
} from '../../api/custom/add/types'
import {
	ICustomFieldsEditRequest,
	ICustomParamsEditRequest,
} from '../../api/custom/edit/types'
import {
	ICustomFieldsGetRequest,
	ICustomParamsGetRequest,
} from '../../api/custom/get/types'
import {
	ICustomFieldsRemoveRequest,
	ICustomParamsRemoveRequest,
} from '../../api/custom/delete/types'

import {
	customFieldsAddStart,
	customFieldsAddSuccess,
	customFieldsAddFailure,
	customParamsAddStart,
	customParamsAddSuccess,
	customParamsAddFailure,
	customFieldsEditStart,
	customFieldsEditSuccess,
	customFieldsEditFailure,
	customParamsEditStart,
	customParamsEditSuccess,
	customParamsEditFailure,
	customFieldsDeleteStart,
	customFieldsDeleteSuccess,
	customFieldsDeleteFailure,
	customParamsDeleteStart,
	customParamsDeleteSuccess,
	customParamsDeleteFailure,
	customFieldsGetStart,
	customFieldsGetSuccess,
	customFieldsGetFailure,
	customParamsGetStart,
	customParamsGetSuccess,
	customParamsGetFailure,
	customFieldsGetAllStart,
	customFieldsGetAllSuccess,
	customFieldsGetAllFailure,
	customParamsGetAllStart,
	customParamsGetAllSuccess,
	customParamsGetAllFailure,
} from './reducer'

export const customFieldAdd =
	(data: ICustomFieldsAddRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(customFieldsAddStart())
			const result = await api.customAdd.createField(data)
			dispatch(customFieldsAddSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(customFieldsAddFailure(e.message))
			return false
		}
	}

export const customFieldDel =
	(data: ICustomFieldsRemoveRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(customFieldsDeleteStart())
			await api.customDel.removeField(data)
			dispatch(customFieldsDeleteSuccess())
			return true
		} catch (e: any) {
			dispatch(customFieldsDeleteFailure(e.message))
			return false
		}
	}

export const customFieldEdit =
	(data: ICustomFieldsEditRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(customFieldsEditStart())
			await api.customEdit.fieldsUpdate(data)
			dispatch(customFieldsEditSuccess())
			return true
		} catch (e: any) {
			dispatch(customFieldsEditFailure(e.message))
			return false
		}
	}

export const customFieldGetAll =
	() =>
	async (dispatch: Dispatch<any>): Promise<void> => {
		try {
			dispatch(customFieldsGetAllStart())
			const result = await api.customGet.fieldsGetAll()
			dispatch(customFieldsGetAllSuccess({ result: result.data }))
		} catch (e: any) {
			dispatch(customFieldsGetAllFailure(e.message))
		}
	}

export const customFieldGet =
	(data: ICustomFieldsGetRequest) =>
	async (dispatch: Dispatch<any>): Promise<any> => {
		try {
			dispatch(customFieldsGetStart())
			const result = await api.customGet.fieldsGet(data)
			dispatch(customFieldsGetSuccess({ result: result.data }))
			return result.data
		} catch (e: any) {
			dispatch(customFieldsGetFailure(e.message))
			return false
		}
	}

export const customParamAdd =
	(data: ICustomParamsAddRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(customParamsAddStart())
			const result = await api.customAdd.createParam(data)
			dispatch(customParamsAddSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(customParamsAddFailure(e.message))
			return false
		}
	}

export const customParamDel =
	(data: ICustomParamsRemoveRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(customParamsDeleteStart())
			await api.customDel.removeParam(data)
			dispatch(customParamsDeleteSuccess())
			return true
		} catch (e: any) {
			dispatch(customParamsDeleteFailure(e.message))
			return false
		}
	}

export const customParamEdit =
	(data: ICustomParamsEditRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(customParamsEditStart())
			await api.customEdit.paramsUpdate(data)
			dispatch(customParamsEditSuccess())
			return true
		} catch (e: any) {
			dispatch(customParamsEditFailure(e.message))
			return false
		}
	}

export const customParamGetAll =
	() =>
	async (dispatch: Dispatch<any>): Promise<void> => {
		try {
			dispatch(customParamsGetAllStart())
			const result = await api.customGet.paramsGetAll()
			dispatch(customParamsGetAllSuccess({ result: result.data }))
		} catch (e: any) {
			dispatch(customParamsGetAllFailure(e.message))
		}
	}

export const customParamGet =
	(data: ICustomParamsGetRequest) =>
	async (dispatch: Dispatch<any>): Promise<any> => {
		try {
			dispatch(customParamsGetStart())
			const result = await api.customGet.paramsGet(data)
			dispatch(customParamsGetSuccess({ result: result.data }))
			return result.data
		} catch (e: any) {
			dispatch(customParamsGetFailure(e.message))
			return false
		}
	}
