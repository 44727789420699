import React from 'react'

import { Box } from '@mui/joy'
import ControlBar from './ControlBar'
import Table from './Table'

const Main = () => (
	<Box
		sx={{
			maxWidth: 'clamp(450px, 100dvw, 900px)',
			width: '100%',
			borderRight: '1px solid',
			borderColor: 'divider',
			overflowY: 'auto',
		}}
	>
		<ControlBar />
		<Table />
	</Box>
)

export default Main
