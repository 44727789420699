import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PublishersState {
	addData: {
		id: number | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	editData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	deleteData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	getAllData: {
		rows: object | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	getData: {
		id: number | null
		name: string
		active: null

		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	activeData: {
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	sharingAddData: {
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	sharingGetData: {
		rows: object
		delete: boolean
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	sharingDeleteData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}
}

const initialState: PublishersState = {
	addData: {
		id: null,
		isLoading: false,
		success: false,
		error: null,
	},

	editData: {
		success: false,
		isLoading: false,
		error: null,
	},

	deleteData: {
		success: false,
		isLoading: false,
		error: null,
	},

	getData: {
		id: null,
		name: '',
		active: null,

		isLoading: false,
		success: false,
		error: null,
	},

	getAllData: {
		rows: [],
		isLoading: false,
		success: false,
		error: null,
	},

	activeData: {
		isLoading: false,
		success: false,
		error: null,
	},

	sharingAddData: {
		isLoading: false,
		success: false,
		error: false,
	},

	sharingGetData: {
		rows: [],
		delete: false,
		isLoading: false,
		success: false,
		error: false,
	},

	sharingDeleteData: {
		success: false,
		isLoading: false,
		error: null,
	},
}

export const reducer = createSlice({
	name: 'publishers',
	initialState,
	reducers: {
		// Add publisher
		publisherAddStart: (state): PublishersState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		publisherAddSuccess: (
			state,
			action: PayloadAction<any>
		): PublishersState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: false,
				success: true,
				error: false,
				id: action.payload.id,
			},
		}),
		publisherAddFailure: (
			state,
			action: PayloadAction<string>
		): PublishersState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Add sharing publisher
		publisherAddSharingStart: (state): PublishersState => ({
			...state,
			sharingAddData: {
				...state.sharingAddData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		publisherAddSharingSuccess: (
			state,
			action: PayloadAction<void>
		): PublishersState => ({
			...state,
			sharingAddData: {
				...state.sharingAddData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		publisherAddSharingFailure: (
			state,
			action: PayloadAction<string>
		): PublishersState => ({
			...state,
			sharingAddData: {
				...state.sharingAddData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Edit publisher
		publisherEditStart: (state): PublishersState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: true,
				success: false,
				error: null,
			},
		}),
		publisherEditSuccess: (
			state,
			action: PayloadAction<void>
		): PublishersState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		publisherEditFailure: (
			state,
			action: PayloadAction<string>
		): PublishersState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Delete advertiser
		publisherDeleteStart: (state): PublishersState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		publisherDeleteSuccess: (
			state,
			action: PayloadAction<void>
		): PublishersState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		publisherDeleteFailure: (
			state,
			action: PayloadAction<string>
		): PublishersState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Delete sharing publisher
		publisherSharingDeleteStart: (state): PublishersState => ({
			...state,
			sharingDeleteData: {
				...state.sharingDeleteData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		publisherSharingDeleteSuccess: (
			state,
			action: PayloadAction<void>
		): PublishersState => ({
			...state,
			sharingDeleteData: {
				...state.sharingDeleteData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		publisherSharingDeleteFailure: (
			state,
			action: PayloadAction<string>
		): PublishersState => ({
			...state,
			sharingDeleteData: {
				...state.sharingDeleteData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Get All publisher
		publisherGetAllStart: (state): PublishersState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		publisherGetAllSuccess: (
			state,
			action: PayloadAction<any>
		): PublishersState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: true,
				error: false,
				rows: action.payload.result.rows,
			},
		}),
		publisherGetAllFailure: (
			state,
			action: PayloadAction<string>
		): PublishersState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: false,
				error: action.payload,
				rows: [],
			},
		}),

		// Get All publisher
		publisherGetAllSharingStart: (state): PublishersState => ({
			...state,
			sharingGetData: {
				...state.sharingGetData,
				isLoading: true,
				success: false,
				delete: false,
				error: false,
			},
		}),
		publisherGetAllSharingSuccess: (
			state,
			action: PayloadAction<any>
		): PublishersState => ({
			...state,
			sharingGetData: {
				...state.sharingGetData,
				isLoading: false,
				success: true,
				error: false,
				delete: action.payload.result.delete,
				rows: action.payload.result.rows,
			},
		}),
		publisherGetAllSharingFailure: (
			state,
			action: PayloadAction<string>
		): PublishersState => ({
			...state,
			sharingGetData: {
				...state.sharingGetData,
				isLoading: false,
				success: false,
				delete: false,
				rows: [],
				error: action.payload,
			},
		}),

		// Get publisher by id
		publisherGetStart: (state): PublishersState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		publisherGetSuccess: (
			state,
			action: PayloadAction<any>
		): PublishersState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: true,
				error: false,
				...action.payload.result.rows,
			},
		}),
		publisherGetFailure: (
			state,
			action: PayloadAction<string>
		): PublishersState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Set active
		publisherSetActiveStart: (state): PublishersState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		publisherSetActiveSuccess: (
			state,
			action: PayloadAction<void>
		): PublishersState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		publisherSetActiveFailure: (
			state,
			action: PayloadAction<string>
		): PublishersState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),
	},
})

export const {
	publisherAddStart,
	publisherAddSuccess,
	publisherAddFailure,
	publisherGetAllSuccess,
	publisherGetAllFailure,
	publisherGetAllStart,
	publisherSetActiveStart,
	publisherSetActiveSuccess,
	publisherSetActiveFailure,
	publisherDeleteStart,
	publisherDeleteSuccess,
	publisherDeleteFailure,
	publisherEditStart,
	publisherEditSuccess,
	publisherEditFailure,
	publisherGetStart,
	publisherGetSuccess,
	publisherGetFailure,
	publisherAddSharingStart,
	publisherAddSharingSuccess,
	publisherAddSharingFailure,
	publisherGetAllSharingStart,
	publisherGetAllSharingSuccess,
	publisherGetAllSharingFailure,
	publisherSharingDeleteStart,
	publisherSharingDeleteSuccess,
	publisherSharingDeleteFailure,
} = reducer.actions

export default reducer.reducer
