import React, { useState } from 'react'

import {
	Box,
	Sheet,
	Tooltip,
	Typography,
	IconButton,
	CircularProgress,
} from '@mui/joy'

import {
	SourceRounded,
	FolderSpecialRounded,
	DeleteForeverRounded,
} from '@mui/icons-material'

import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { useAppDispatch } from '../../../../store'
import { publisherDel } from '../../../../store/publishers'
import DeleteDialog from '../../../layout/DeleteDialog'
import { usePub } from '../../../hooks/publishers'


const ControlBar = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const { pub, replacePublisher } = usePub()
	const [, setCookie] = useCookies(['reload'])
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
	const navigate = useNavigate();

	const deletePub = () => {
		dispatch(publisherDel({ id: pub.id })).then(result => {
			if (result) {
				replacePublisher({})
				setCookie('reload', new Date().getTime(), { path: '/' })
			}
			navigate('/publishers')
		})
		setOpenDeleteDialog(false)
	}

	const AddNewCampaigns = () => {
		return (
			<Tooltip
				arrow
				size='sm'
				color='primary'
				placement='top'
				title={t('create_publisher_zone')}
				variant='solid'
			>
				<IconButton
					size='sm'
					component={NavLink}
					to={`/publishers/zones/add/${pub.id}`}
				>
					<FolderSpecialRounded sx={{ fontSize: '20px' }} color='primary' />
				</IconButton>
			</Tooltip>
		)
	}

	const RemoveAdvertiser = () => {
		return (
			<Tooltip
				arrow
				size='sm'
				color='primary'
				placement='top'
				title={t('remove_publisher')}
				variant='solid'
			>
				<IconButton size='sm' onClick={setOpenDeleteDialog}>
					<DeleteForeverRounded sx={{ fontSize: '20px' }} color='danger' />
				</IconButton>
			</Tooltip>
		)
	}

	return (
		<>
			<DeleteDialog
				open={{ set: setOpenDeleteDialog, value: openDeleteDialog }}
				description={t('delete_dialog_remove_publisher')}
				onClick={e => {
					deletePub()
				}}
			/>
			<Sheet
				variant='outlined'
				sx={{
					border: 'none',
					transition: 'transform 0.4s',
					overflow: 'hidden',
					px: 2,
					py: 1.5,
					minHeight: '58px',
					display: 'flex',
					alignItems: 'center',
					backgroundColor:
						'var(--variant-softBg, var(--joy-palette-primary-softBg, var(--joy-palette-primary-100, #E3EFFB)))',
				}}
			>
				<Typography
					level='title-md'
					sx={{ flex: 1, display: 'flex', alignItems: 'center' }}
				>
					<SourceRounded sx={{ mr: '10px' }} />
					{pub && !!pub.name ? pub.name : <CircularProgress size='sm' />}
				</Typography>

				<Box sx={{ gridGap: '10px', display: 'flex' }}>
					{pub && !!pub.name && <AddNewCampaigns />}
					{pub && !!pub.name && <RemoveAdvertiser />}
				</Box>
			</Sheet>
		</>
	)
}

export default ControlBar
