import React from 'react'
import { useTranslation } from 'react-i18next'

const Head = () => {
	const { t } = useTranslation()

	return (
		<thead>
			<tr>
				<th>{t('categories')}</th>
				<th style={{ width: '30px' }}></th>
				<th style={{ width: '30px' }}></th>
			</tr>
		</thead>
	)
}

export default Head
