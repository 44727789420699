import * as React from 'react'

import { Box, Chip, ChipDelete } from '@mui/joy'
import countryList from 'react-select-country-list'

import { useFilter } from '../../../../../hooks/filter'
import { FlagImg } from '../../../../../handlers/Chips'
import { useTranslation } from 'react-i18next'

const Chips = () => {
	const { t } = useTranslation()
	const { filter, replaceFilter } = useFilter()

	const deviceText = t('device', { returnObjects: true })
	const connectionTypeText = t('connection_type', { returnObjects: true })
	const userTypesText = t('user_types', { returnObjects: true })

	const removeElementDefault = ({ index, category }) => {
		const newFilter = {}

		Object.entries(filter).forEach(([k, value]) => {
			if (Array.isArray(value)) {
				newFilter[k] = value.filter(
					(_, itemIndex) => !(category === k && itemIndex === index)
				)
			} else {
				newFilter[k] = category !== k ? value : ''
			}
		})

		replaceFilter(newFilter)
	}

	const createChip = ({ key, color, label, prefix = '', index, category }) => (
		<Chip
			key={key}
			sx={{ mr: 1, mt: 1, pr: 2 }}
			size='lg'
			variant='soft'
			color={color}
			endDecorator={
				<ChipDelete
					sx={{
						width: '14px',
						ml: '2px',
						mr: '-12px',
						[`& svg`]: {
							width: '14px',
						},
					}}
					onDelete={() => {
						removeElementDefault({ index, key, category })
					}}
				/>
			}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gridGap: '5px',
					fontSize: 13,
				}}
			>
				{prefix}
				{label}
			</Box>
		</Chip>
	)

	const renderItems = ({ items, type, isExcluded = false, category }) => {
		return items.map((item, index) => {
			const color = isExcluded ? 'danger' : 'primary'
			const key = `${type}-${index}-${color}`
			let label = ''
			let prefix = null

			switch (type) {
				case 'country':
					label = countryList().getLabel(item.value)
					prefix = <FlagImg countryCode={item.value} />
					break

				case 'region':
					label = `${countryList().getLabel(item.country)}, ${item.label}`
					prefix = <FlagImg countryCode={item.country} />
					break

				case 'city':
					label = `${countryList().getLabel(item.country)}, ${item.region}, ${
						item.label
					}`
					prefix = <FlagImg countryCode={item.country} />
					break

				case 'ip_range':
					label = `${item.from}${item.to ? ` - ${item.to}` : ''}`
					break

				case 'language':
					label = item.label
					break

				case 'browser':
				case 'os':
					label = `${item.name} ${item.condition} ${
						item.version > 0 ? item.version : ''
					}`
					break

				case 'device_format':
					label = deviceText[`_${item.value}`]
					break

				case 'vendor':
				case 'isp':
				case 'category':
				case 'template':
					label = `${type} ${isExcluded ? '!' : ''}= '${item.value}'`
					break

				case 'connection_type':
					label = connectionTypeText[`_${item.value}`]
					break

				case 'user_type':
					label = userTypesText[`_${item.value}`]
					break

				case 'custom':
					label = `${item.name} ${
						item.condition === '='
							? `${isExcluded ? '!' : ''}${item.condition} '${item._value}'`
							: `ILIKE '%${item._value}%'`
					}`
					break

				case 'referer':
					label = `referer ${
						item.condition === '='
							? `${isExcluded ? '!' : ''}${item.condition} '${item.referer}'`
							: `ILIKE '%${item.referer}%'`
					}`
					break
				case 'zone':
				case 'publisher':
				case 'advertiser':
				case 'campaign':
				case 'banner':
					label = `${type} ${isExcluded ? '!' : ''}= ${item.name}`
					break
				default:
					console.error('Unknown type', type)
			}
			if (!label) return null
			return createChip({ key, color, label, prefix, index, category })
		})
	}

	const renderCategory = ({ category }) => {
		const isExcluded = !!category.includes('ex_')
		const type = category.replaceAll('in_', '').replaceAll('ex_', '')
		const items = filter[category]

		return [!!items && renderItems({ items, type, isExcluded, category })]
	}

	const result = Object.entries(filter)
		.map(([key, value]) => {
			if (
				typeof value === 'object' &&
				Object.keys(value).length &&
				key !== 'group_by'
			) {
				return renderCategory({ category: key })
			}

			return null
		})
		.filter(i => !!i)

	return (
		!!result.length && (
			<Box
				sx={{
					maxWidth: 'clamp(450px, 100dvw, 900px)',
					mb: 2,
					px: 3,
				}}
			>
				{result}
			</Box>
		)
	)
}

export default Chips
