import * as React from 'react'

import { useColorScheme } from '@mui/joy/styles'
import ListItem from '@mui/joy/ListItem'
import ListItemButton from '@mui/joy/ListItemButton'
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded'
import NightlightRoundedIcon from '@mui/icons-material/NightlightRounded'

const SchemeToggle = () => {
	const { mode, setMode } = useColorScheme()

	return (
		<ListItem>
			<ListItemButton
				onClick={() => setMode(mode === 'light' ? 'dark' : 'light')}
			>
				{mode === 'light' ? (
					<NightlightRoundedIcon />
				) : (
					<LightModeRoundedIcon />
				)}
			</ListItemButton>
		</ListItem>
	)
}

export default SchemeToggle
