import React from 'react'
import { Sheet, Table } from '@mui/joy'

import Head from './Head'
import Body from './Body'

const CustomTable = () => (
	<Sheet
		sx={() => ({
			mt: '0',
			mx: '-5px',
			display: 'flex',
			border: '1px solid',
			borderColor: 'divider',
			transition: 'transform 0.4s',
			backgroundColor: 'transparent',
			borderRadius: '8px',
			overflow: 'hidden',
		})}
	>
		<Table
			size='sm'
			variant='plain'
			borderAxis={'xBetween'}
			hoverRow
			sx={{
				'--Table-headerUnderlineThickness': '1px',
				'--TableCell-height': '33px',
				'--TableCell-paddingX': '15px',
				'--TableCell-paddingY': '3px',
				[`& thead th`]: {
					verticalAlign: 'inherit',
				},
			}}
		>
			<Head />
			<Body />
		</Table>
	</Sheet>
)

export default CustomTable
