import React, { createContext, useContext, useState } from 'react'
export const RetargetContext = createContext()

export function RetargetProvider({ children }) {
	const andItem = {
		pr: 'pr1',
		value: '',
		count: 0,
		condition: '=',
		period: 0,
		first: '',
		last: '',
	}

	const orItem = {
		status: 0,
		banner_id: '',
		banner_name: '',
		period: 0,
		first: '',
		last: '',
		items: [andItem],
	}

	const [retarget, setRetarget] = useState([])

	const addNewOrItem = () => {
		setRetarget(current => [...current, orItem])
	}

	const addNewAndItem = ({ itemKey }) => {
		if (retarget[itemKey]) {
			let newRetarget = [...retarget]
			newRetarget[itemKey].items = [...newRetarget[itemKey].items, andItem]
			setRetarget(newRetarget)
		}
	}

	const defaultOrItem = items => {
		if (items) {
			setRetarget(items)
		} else {
			setRetarget([orItem])
		}
	}

	const updateOrItem = ({ itemKey, fieldName, newValue }) => {
		if (retarget[itemKey]) {
			let newItems = retarget
			newItems[itemKey][fieldName] = newValue
			setRetarget(newItems)
		}
	}

	const updateAndItem = ({ itemKey, fieldKey, fieldName, newValue }) => {
		if (retarget[itemKey]) {
			let newItems = retarget
			newItems[itemKey].items[fieldKey][fieldName] = newValue
			setRetarget(newItems)
		}
	}

	const removeOrItem = ({ itemKey }) => {
		const newArr = retarget
			.map((val, key) => (key !== itemKey ? val : false))
			.filter(i => !!i)
		setRetarget(newArr)
	}

	const removeAndItem = ({ itemKey, fieldKey }) => {
		let newRetarget = [...retarget]

		if (
			newRetarget[itemKey] &&
			newRetarget[itemKey].items[fieldKey] !== undefined
		) {
			const newItems = newRetarget[itemKey].items
				.map((val, key) => {
					console.log(key, fieldKey)
					return key !== fieldKey ? val : false
				})
				.filter(i => !!i)

			newRetarget[itemKey].items = newItems
		}

		setRetarget(newRetarget)
	}

	return (
		<RetargetContext.Provider
			value={{
				retarget,
				addNewOrItem,
				addNewAndItem,
				updateOrItem,
				updateAndItem,
				removeOrItem,
				removeAndItem,
				defaultOrItem,
			}}
		>
			{children}
		</RetargetContext.Provider>
	)
}

export function useRetarget() {
	const context = useContext(RetargetContext)
	if (!context) {
		throw new Error('useRetarget must be used within a RetargetContext')
	}
	return context
}
