import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AdvertisersState {
	addData: {
		advertiser_id: number | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	editData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	deleteData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	getAllData: {
		rows: object | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	getData: {
		advertiser_id: number | null
		name: string
		contact_name: string
		email: string
		active: null
		description: string
		category: null

		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	activeData: {
		isLoading: boolean
		advertiser_id: number | null
		active: number | null
		success: boolean | null
		error: string | boolean | null
	}

	sharingAddData: {
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	sharingGetData: {
		rows: object
		delete: boolean
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	sharingDeleteData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}
}

const initialState: AdvertisersState = {
	addData: {
		advertiser_id: null,
		isLoading: false,
		success: false,
		error: null,
	},

	editData: {
		success: false,
		isLoading: false,
		error: null,
	},

	deleteData: {
		success: false,
		isLoading: false,
		error: null,
	},

	getData: {
		advertiser_id: null,
		name: '',
		contact_name: '',
		email: '',
		active: null,
		description: '',
		category: null,

		isLoading: false,
		success: false,
		error: null,
	},

	getAllData: {
		rows: [],
		isLoading: false,
		success: false,
		error: null,
	},

	activeData: {
		isLoading: false,
		advertiser_id: null,
		active: null,
		success: false,
		error: null,
	},

	sharingAddData: {
		isLoading: false,
		success: false,
		error: false,
	},

	sharingGetData: {
		rows: [],
		delete: false,
		isLoading: false,
		success: false,
		error: false,
	},

	sharingDeleteData: {
		success: false,
		isLoading: false,
		error: null,
	},
}

export const reducer = createSlice({
	name: 'advertisers',
	initialState,
	reducers: {
		// Add advertiser
		advertiserAddStart: (state): AdvertisersState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		advertiserAddSuccess: (
			state,
			action: PayloadAction<any>
		): AdvertisersState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: false,
				success: true,
				error: false,
				advertiser_id: action.payload.advertiser_id,
			},
		}),
		advertiserAddFailure: (
			state,
			action: PayloadAction<string>
		): AdvertisersState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Add sharing advertiser
		advertiserAddSharingStart: (state): AdvertisersState => ({
			...state,
			sharingAddData: {
				...state.sharingAddData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		advertiserAddSharingSuccess: (
			state,
			action: PayloadAction<void>
		): AdvertisersState => ({
			...state,
			sharingAddData: {
				...state.sharingAddData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		advertiserAddSharingFailure: (
			state,
			action: PayloadAction<string>
		): AdvertisersState => ({
			...state,
			sharingAddData: {
				...state.sharingAddData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Edit advertiser
		advertiserEditStart: (state): AdvertisersState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: true,
				success: false,
				error: null,
			},
		}),
		advertiserEditSuccess: (
			state,
			action: PayloadAction<void>
		): AdvertisersState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		advertiserEditFailure: (
			state,
			action: PayloadAction<string>
		): AdvertisersState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Delete advertiser
		advertiserDeleteStart: (state): AdvertisersState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		advertiserDeleteSuccess: (
			state,
			action: PayloadAction<void>
		): AdvertisersState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		advertiserDeleteFailure: (
			state,
			action: PayloadAction<string>
		): AdvertisersState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Delete sharing advertiser
		advertiserSharingDeleteStart: (state): AdvertisersState => ({
			...state,
			sharingDeleteData: {
				...state.sharingDeleteData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		advertiserSharingDeleteSuccess: (
			state,
			action: PayloadAction<void>
		): AdvertisersState => ({
			...state,
			sharingDeleteData: {
				...state.sharingDeleteData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		advertiserSharingDeleteFailure: (
			state,
			action: PayloadAction<string>
		): AdvertisersState => ({
			...state,
			sharingDeleteData: {
				...state.sharingDeleteData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Get All advertiser
		advertiserGetAllStart: (state): AdvertisersState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		advertiserGetAllSuccess: (
			state,
			action: PayloadAction<any>
		): AdvertisersState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: true,
				error: false,
				rows: action.payload.result.rows,
			},
		}),
		advertiserGetAllFailure: (
			state,
			action: PayloadAction<string>
		): AdvertisersState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: false,
				error: action.payload,
				rows: [],
			},
		}),

		// Get All advertiser
		advertiserGetAllSharingStart: (state): AdvertisersState => ({
			...state,
			sharingGetData: {
				...state.sharingGetData,
				isLoading: true,
				success: false,
				delete: false,
				error: false,
			},
		}),
		advertiserGetAllSharingSuccess: (
			state,
			action: PayloadAction<any>
		): AdvertisersState => ({
			...state,
			sharingGetData: {
				...state.sharingGetData,
				isLoading: false,
				success: true,
				error: false,
				delete: action.payload.result.delete,
				rows: action.payload.result.rows,
			},
		}),
		advertiserGetAllSharingFailure: (
			state,
			action: PayloadAction<string>
		): AdvertisersState => ({
			...state,
			sharingGetData: {
				...state.sharingGetData,
				isLoading: false,
				success: false,
				delete: false,
				rows: [],
				error: action.payload,
			},
		}),

		// Get advertiser by id
		advertiserGetStart: (state): AdvertisersState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		advertiserGetSuccess: (
			state,
			action: PayloadAction<any>
		): AdvertisersState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: true,
				error: false,
				...action.payload.result.rows,
			},
		}),
		advertiserGetFailure: (
			state,
			action: PayloadAction<string>
		): AdvertisersState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Set active
		advertiserSetActiveStart: (state): AdvertisersState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		advertiserSetActiveSuccess: (
			state,
			action: PayloadAction<void>
		): AdvertisersState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		advertiserSetActiveFailure: (
			state,
			action: PayloadAction<string>
		): AdvertisersState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),
	},
})

export const {
	advertiserAddStart,
	advertiserAddSuccess,
	advertiserAddFailure,
	advertiserGetAllSuccess,
	advertiserGetAllFailure,
	advertiserGetAllStart,
	advertiserSetActiveStart,
	advertiserSetActiveSuccess,
	advertiserSetActiveFailure,
	advertiserDeleteStart,
	advertiserDeleteSuccess,
	advertiserDeleteFailure,
	advertiserEditStart,
	advertiserEditSuccess,
	advertiserEditFailure,
	advertiserGetStart,
	advertiserGetSuccess,
	advertiserGetFailure,
	advertiserAddSharingStart,
	advertiserAddSharingSuccess,
	advertiserAddSharingFailure,
	advertiserGetAllSharingStart,
	advertiserGetAllSharingSuccess,
	advertiserGetAllSharingFailure,
	advertiserSharingDeleteStart,
	advertiserSharingDeleteSuccess,
	advertiserSharingDeleteFailure,
} = reducer.actions

export default reducer.reducer
