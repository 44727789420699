import React from 'react'

import FormContainer from './FormContainer'

import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { toast } from '../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../store'
import { publisherEdit } from '../../../../../store/publishers'
import { usePub } from '../../../../hooks/publishers'

const BaseInformation = () => {
	const { t } = useTranslation()
	const { pub } = usePub()
	const dispatch = useAppDispatch()
	const [, setCookie] = useCookies(['reload'])

	const handlerForm = event => {
		event.preventDefault()
		dispatch(publisherEdit(pub)).then(result => {
			result
				? toast.success(t('edit_publisher_successfully'))
				: toast.error(t('error'))

			setCookie('reload', new Date().getTime(), { path: '/' })
		})
	}

	return (
		<form onSubmit={handlerForm}>
			<FormContainer />
		</form>
	)
}

export default BaseInformation
