import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	IUsersSetActiveRequest,
	IUsersSetActiveResponse,
	IUsersEditRequest,
	IUsersEditResponse,
} from './types'

export const setActive = (
	params: IUsersSetActiveRequest
): AxiosPromise<IUsersSetActiveResponse> =>
	axiosInstance.patch(Endpoints.USERS.SET_ACTIVE, params)

export const update = (
	params: IUsersEditRequest
): AxiosPromise<IUsersEditResponse> =>
	axiosInstance.put(Endpoints.USERS.EDIT, params)
