import React from 'react'

import { Box } from '@mui/joy'
import {
	Select,
	TreeSelect,
	Typography,
	Space,
	ConfigProvider,
	DatePicker,
	Button,
	Divider,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { useRetarget } from '../../../../../hooks/retarget'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'

import localeRu from 'antd/locale/ru_RU'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import AndContainer from './AndContainer'

const OrContainer = ({ itemKey, treeData }) => {
	const { t, i18n } = useTranslation()
	const { retarget, addNewOrItem, updateOrItem, removeOrItem } = useRetarget()
	const dateFormat = 'YYYY-MM-DD'

	const Text = ({ text }) => (
		<Typography style={{ fontSize: 12 }} type='secondary'>
			{t(text)}
		</Typography>
	)

	const theme = {
		components: {
			Select: {
				optionFontSize: 12,
				optionHeight: 28,
			},
		},
		token: {
			fontSize: 12,
		},
	}

	const StatusSelect = () => {
		const items = [
			{ label: t('retarget_status_1'), value: 1 },
			{ label: t('retarget_status_0'), value: 0 },
		]

		return (
			<Space direction='vertical' style={{ flex: 1 }}>
				<Text text={'retarget_status'} />
				<ConfigProvider theme={theme}>
					<Select
						style={{ display: 'flex', flex: 1 }}
						size='small'
						maxTagCount={'responsive'}
						defaultValue={retarget[itemKey].status}
						options={items}
						onChange={newValue =>
							updateOrItem({ itemKey, fieldName: 'status', newValue })
						}
					/>
				</ConfigProvider>
			</Space>
		)
	}

	const PeriodSelect = () => {
		const items = Array(21)
			.fill(1)
			.map((_, key) => ({
				label: t(`period_${key}`),
				value: key,
			}))
			.filter(i => !!i)

		return (
			<Space direction='vertical' style={{ flex: 1 }}>
				<Text text={'capping_period'} />
				<ConfigProvider theme={theme}>
					<Select
						style={{ display: 'flex', flex: 1 }}
						size='small'
						maxTagCount={'responsive'}
						defaultValue={retarget[itemKey].period}
						options={items}
						onChange={newValue =>
							updateOrItem({ itemKey, fieldName: 'period', newValue })
						}
					/>
				</ConfigProvider>
			</Space>
		)
	}

	const BannerSelect = () => {
		return (
			<Space direction='vertical' style={{ flex: 1 }}>
				<Text text={'creative'} />
				<ConfigProvider theme={theme}>
					<TreeSelect
						style={{ display: 'flex', flex: 1 }}
						size='small'
						defaultValue={retarget[itemKey].banner_id}
						dropdownStyle={{ minWidth: '300px' }}
						treeData={treeData}
						treeIcon
						treeLine
						onChange={(newValue, newName) => {
							updateOrItem({ itemKey, fieldName: 'banner_id', newValue })
							updateOrItem({
								itemKey,
								fieldName: 'banner_name',
								newValue: newName[0],
							})
						}}
					/>
				</ConfigProvider>
			</Space>
		)
	}

	const FirstView = () => (
		<Space direction='vertical' style={{ flex: 1 }}>
			<Text text={'retarget_first_view'} />
			<ConfigProvider
				locale={i18n.language === 'ru' ? localeRu : {}}
				theme={theme}
			>
				<DatePicker
					size={'small'}
					style={{ display: 'flex', flex: 1 }}
					defaultValue={
						retarget[itemKey].first !== ''
							? dayjs(retarget[itemKey].first, dateFormat)
							: undefined
					}
					format={dateFormat}
					onChange={(_, newValue) =>
						updateOrItem({ itemKey, fieldName: 'first', newValue })
					}
				/>
			</ConfigProvider>
		</Space>
	)

	const LastView = () => (
		<Space direction='vertical' style={{ flex: 1 }}>
			<Text text={'retarget_last_view'} />
			<ConfigProvider
				locale={i18n.language === 'ru' ? localeRu : {}}
				theme={theme}
			>
				<DatePicker
					size={'small'}
					style={{ display: 'flex', flex: 1 }}
					defaultValue={
						retarget[itemKey].last !== ''
							? dayjs(retarget[itemKey].last, dateFormat)
							: undefined
					}
					format={dateFormat}
					onChange={(_, newValue) =>
						updateOrItem({ itemKey, fieldName: 'last', newValue })
					}
				/>
			</ConfigProvider>
		</Space>
	)

	const handlerOnAdd = () => {
		addNewOrItem()
	}

	const handlerOnRemove = () => {
		removeOrItem({ itemKey })
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				backgroundColor: 'var(--joy-palette-background-surface)',
				border: '1px solid',
				borderRadius: '8px',
				borderColor:
					'var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))',
			}}
		>
			<Space.Compact
				block
				direction='vertical'
				style={{
					flexDirection: 'end',
					margin: '10px -10px -58px',
					justifyContent: 'end',
					width: '30px',
				}}
			>
				<Button
					size={'small'}
					onClick={handlerOnRemove}
					type={'primary'}
					danger
				>
					<DeleteOutlined />
				</Button>
				<Button size={'small'} type={'primary'} onClick={handlerOnAdd}>
					<PlusOutlined />
				</Button>
			</Space.Compact>
			<Box
				sx={{
					p: '10px',
					pl: '30px',
					display: 'flex',
					gap: 2,
					flexDirection: 'column',
					overflowY: 'auto',
				}}
			>
				<Space.Compact block>
					<StatusSelect />
					<PeriodSelect />
					<BannerSelect />
					<FirstView />
					<LastView />
				</Space.Compact>
				<Divider orientation='left' plain style={{ margin: 0 }} />
				<Space direction='vertical'>
					{!!retarget[itemKey].items &&
						retarget[itemKey].items.map((_, key) => (
							<AndContainer key={key} itemKey={itemKey} fieldKey={key} />
						))}
				</Space>
			</Box>
		</Box>
	)
}

export default OrContainer
