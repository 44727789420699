import React from 'react'
import { Box, Typography, IconButton } from '@mui/joy'
import {
	QueueRounded,
	ReplayRounded,
	VerifiedRounded,
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import Button from './Button'

const Header = props => {
	const { t } = useTranslation()
	const [, setCookie] = useCookies(['reload'])
	const text = t('sidebar_navigation', {
		returnObjects: true,
	})

	const ActiveBtn = () => {
		const iconButtonSx = () => ({
			display: 'flex',
			minHeight: '25px',
			minWidth: '25px',
			[`&.active svg`]: {
				color:
					'var(--joy-palette-success-solidBg, var(--joy-palette-success-500, #1F7A1F))',
			},
		})

		return (
			<IconButton
				size='sm'
				sx={iconButtonSx}
				onClick={e => {
					props.active.set(props.active.value === 'all' ? 'active' : 'all')
					setCookie('reload', new Date().getTime(), { path: '/' })
				}}
				className={props.active.value !== 'all' && 'active'}
			>
				<VerifiedRounded
					sx={{
						fontSize: 15,
					}}
				/>
			</IconButton>
		)
	}

	return (
		<Box
			sx={{
				pl: 2,
				pr: 1,
				pb: 1,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Typography
				role='presentation'
				sx={{
					color: 'text.primary',
					fontSize: '12px',
					textTransform: 'uppercase',
					letterSpacing: '2px',
					flex: 1,
				}}
			>
				{text['rotation']}
			</Typography>
			<ActiveBtn />
			<Button
				tooltip={t('refresh_advertisers')}
				onClick={e => setCookie('reload', new Date().getTime(), { path: '/' })}
				component={<ReplayRounded sx={{ fontSize: 15 }} />}
			/>

			<Button
				tooltip={t('add_rotation')}
				path={'/rotation/add'}
				component={<QueueRounded sx={{ fontSize: 15 }} />}
			/>
		</Box>
	)
}

export default Header
