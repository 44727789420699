import * as React from 'react'

import { Box, IconButton, ToggleButtonGroup, Typography } from '@mui/joy'
import { RemoveRedEyeRounded, VisibilityOffRounded } from '@mui/icons-material/'

import { useTranslation } from 'react-i18next'
import { useFilter } from '../../../../../hooks/filter'

const VpnTorBtn = ({ id }) => {
	const { t } = useTranslation()
	const { updateFilter } = useFilter()
	const text = t('filter_utility', { returnObjects: true })
	const [value, setter] = React.useState('0')

	return (
		<Box
			variant='outlined'
			color='neutral'
			sx={{
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'row',
				gridGap: 10,
			}}
		>
			<Typography
				sx={{
					fontSize: 15,
					textAlign: 'center',
				}}
			>
				{text[id]}
			</Typography>
			<ToggleButtonGroup
				size='sm'
				color='neutral'
				value={`${value}`}
				onChange={(_, val) => {
					if (val !== '0') {
						if (val === '2') {
							if (id === 'unique') {
								updateFilter(`unique`, val)
							} else {
								updateFilter(`ex_${id}`, val)
								updateFilter(`in_${id}`, '0')
							}
						} else {
							if (id === 'unique') {
								updateFilter(`unique`, val)
							} else {
								updateFilter(`in_${id}`, val)
								updateFilter(`ex_${id}`, '0')
							}
						}
					}
					setter(!val ? 0 : val)
				}}
				sx={{ justifyContent: 'center' }}
			>
				<IconButton
					variant='outlined'
					color={value === '1' ? 'primary' : ''}
					sx={{
						px: 2,
						zIndex: 1,
					}}
					value={'1'}
				>
					<RemoveRedEyeRounded />
				</IconButton>
				{id !== 'unique' && (
					<IconButton
						variant='outlined'
						color={value === '2' ? 'danger' : ''}
						sx={{ px: 2 }}
						value={'2'}
					>
						<VisibilityOffRounded />
					</IconButton>
				)}
			</ToggleButtonGroup>
		</Box>
	)
}

export default VpnTorBtn
