import React, { useState, useEffect } from 'react'

import { Box, Typography } from '@mui/joy'
import { Input, Button, message, Upload, Space, Image, Select } from 'antd'
import {
	UploadOutlined,
	FolderOutlined,
	FileOutlined,
	EyeOutlined,
	FolderOpenOutlined,
	FileImageOutlined,
	PlaySquareOutlined,
	FileZipOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../store'
import { cloud, upload } from '../../store/banners'
import { customFieldGetAll } from '../../store/custom'

import { ReactComponent as MailRu } from '../icons/mail.svg'
import { ReactComponent as Yandex } from '../icons/yandex.svg'
import { ReactComponent as Google } from '../icons/google.svg'
import { ReactComponent as DropBox } from '../icons/dropbox.svg'

const { TextArea } = Input

const Uploader = ({ value, onChange }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [svc, setSvc] = useState('')
	const [inp, setInp] = useState('')
	const [tree, setTree] = useState([])
	const [cdns, setCdns] = useState([])
	const [currentCdn, setCurrentCdn] = useState('')
	const [visible, setVisible] = useState(false)
	const [src, setSrc] = useState('')
	const [mime, setMime] = useState('')
	const [open, setOpen] = useState({})

	const cf = useSelector(state => state.custom.customFieldsGetAllData?.rows)

	useEffect(() => {
		if (!cf.length) {
			dispatch(customFieldGetAll())
		}
	}, [dispatch, cf])

	const props = {
		name: 'file',
		action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
		headers: {
			authorization: 'authorization-text',
		},
		onChange(info) {
			if (info.file.status !== 'uploading') {
				console.log(info.file, info.fileList)
			}
			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`)
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`)
			}
		},
	}

	const SvgIcons = {
		mail: MailRu,
		yandex: Yandex,
		google: Google,
		dropbox: DropBox,
	}

	const Icon = svc !== '' && SvgIcons[svc] ? SvgIcons[svc] : React.Fragment

	const handlerOnChangeServiceUri = uri => {
		uri = uri.trim()

		if (uri === inp) {
			return
		}

		setInp(uri)

		let service = ''
		if (uri.indexOf('cloud.mail.ru') > -1) {
			service = 'mail'
		} else if (uri.indexOf('disk.yandex.ru') > -1) {
			service = 'yandex'
		} else if (uri.indexOf('dropbox.com') > -1) {
			service = 'dropbox'
		} else if (uri.indexOf('google.com') > -1) {
			service = 'google'
		} else {
			service = ''
		}

		setSvc(service)

		if (service) {
			dispatch(cloud({ uri, service })).then(result => {
				setTree(result.tree)
				setCdns(result.cdns)
			})
		}
	}

	function convertToMB(kilobytes) {
		const megabytes = kilobytes / 1024
		return megabytes
	}

	const replaceCP = url => {
		cf &&
			cf.forEach(i => {
				url = url.replaceAll(i.field_value, `$$$$CF[${i.field_key}]$$$$`)
			})
		return url
	}

	const UploadBtn = ({ url, name, type }) => (
		<Button
			type='text'
			style={{ borderRadius: '0' }}
			size={'small'}
			onClick={() => {
				uploadFile({ url, name, type })
			}}
		>
			<UploadOutlined />
		</Button>
	)

	const uploadFile = ({ url, name, type }) => {
		let value = ''
		dispatch(upload({ url, cdn: currentCdn, name, type })).then(result => {
			if (result.status === 'success') {
				value = replaceCP(result.value)
				onChange({ target: { value } })
			}
		})
	}

	const Preview = React.memo(({ src, mime }) => {
		return (
			<Button
				type='text'
				style={{ borderRadius: '0' }}
				size={'small'}
				onClick={() => {
					setVisible(true)
					setSrc(src)
					setMime(mime)
				}}
			>
				<EyeOutlined />
			</Button>
		)
	})

	const checkFormat = str => {
		const pattern = /\.([0-9a-z]+)(?:[?#]|$)/i
		const extension = (str.match(pattern) || ['', ''])[1]
		if (['jpg', 'png', 'gif'].includes(extension.toLowerCase())) {
			return 'image'
		} else if (['mp4', 'mov', 'm4p'].includes(extension.toLowerCase())) {
			return 'video'
		} else if (['zip'].includes(extension.toLowerCase())) {
			return 'zip'
		} else {
			return 'file'
		}
	}

	const folderItem = (parent, item) => {
		const current =
			item.type === 'folder' ? (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						paddingLeft: '4px',
						cursor: 'pointer',
						[`&:hover`]: {
							backgroundColor:
								'var(--variant-plainHoverBg, var(--joy-palette-neutral-plainHoverBg, var(--joy-palette-neutral-100, #F0F4F8)))',
						},
					}}
					onClick={() => {
						setOpen({
							...open,
							[`${parent}${item.name}${item.type}`]:
								!open[`${parent}${item.name}${item.type}`],
						})
					}}
				>
					<Space.Compact>
						{open[`${parent}${item.name}${item.type}`] ? (
							<FolderOpenOutlined style={{ color: 'orange' }} />
						) : (
							<FolderOutlined style={{ color: 'orange' }} />
						)}
						<Typography sx={{ fontSize: 12, paddingLeft: '4px' }}>
							{item.name}
						</Typography>
					</Space.Compact>
				</Box>
			) : (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						paddingLeft: '4px',
						[`&:hover`]: {
							backgroundColor:
								'var(--variant-plainHoverBg, var(--joy-palette-neutral-plainHoverBg, var(--joy-palette-neutral-100, #F0F4F8)))',
						},
					}}
				>
					<Space.Compact
						style={{
							display: 'flex',
							width: '100%',
							alignItems: 'center',
						}}
					>
						{(item.mime && item.mime.indexOf('image') > -1) ||
						checkFormat(item.name) === 'image' ? (
							<FileImageOutlined style={{ color: 'green' }} />
						) : checkFormat(item.name) === 'video' ? (
							<PlaySquareOutlined style={{ color: 'green' }} />
						) : checkFormat(item.name) === 'zip' ? (
							<FileZipOutlined style={{ color: 'green' }} />
						) : (
							<FileOutlined />
						)}

						<Typography
							sx={{ fontSize: 12, paddingLeft: '4px', flex: 1, width: '100%' }}
						>
							{item.name}
						</Typography>
						<Box
							sx={{
								fontSize: 12,
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							<Typography sx={{ fontSize: 12, mx: '4px' }}>
								{item.size ? `${convertToMB(item.size).toFixed(2)}мб` : ''}
							</Typography>
							{(item.preview || item.download) &&
							((item.mime &&
								(item.mime.indexOf('video') > -1 ||
									item.mime.indexOf('image') > -1)) ||
								['image', 'video'].includes(checkFormat(item.name)) > 0) ? (
								<Typography sx={{ fontSize: 12 }}>
									<Preview
										src={
											(item.mime && item.mime.indexOf('video') > -1) ||
											checkFormat(item.name) === 'video'
												? item.download
												: item.preview || item.download
										}
										mime={item.mime || item.name}
									/>
								</Typography>
							) : (
								<></>
							)}
							{item.download ? (
								<Typography sx={{ fontSize: 12 }}>
									<UploadBtn
										url={item.download}
										name={item.name}
										type={
											item.mime
												? item.mime.indexOf('video') > -1
													? 'video'
													: item.mime.indexOf('image') > -1
													? 'image'
													: 'file'
												: checkFormat(item.name)
										}
									/>
								</Typography>
							) : (
								''
							)}
						</Box>
					</Space.Compact>
				</Box>
			)

		return current
	}

	const Items = ({ tree, display, parent, parentKey }) => {
		return (
			!!tree &&
			!!tree.length &&
			tree.map(item => {
				if (item.type === 'folder') {
					return (
						<>
							<Box
								sx={{
									display: display || parent ? 'flex' : 'none',
									flexDirection: 'column',
									flex: 1,
								}}
								key={`${item.name}${item.type}`}
							>
								{folderItem(parentKey, item)}

								<Box
									sx={{
										ml: '10px',
										flexDirection: 'column',
										flex: 1,
									}}
								>
									{open?.[`${parentKey}${item.name}${item.type}`] &&
										Items({
											parentKey: `${parentKey}${item.name}${item.type}`,
											tree: item.childrens,
											parent: open?.[`${parentKey}${item.name}${item.type}`],
										})}
								</Box>
							</Box>
						</>
					)
				} else {
					return (
						<React.Fragment key={`${item.name}${item.type}`}>
							{folderItem(parentKey, item)}
						</React.Fragment>
					)
				}
			})
		)
	}

	return (
		<>
			{mime.indexOf('image') > -1 || checkFormat(mime) === 'image' ? (
				<Image
					width={0}
					style={{ display: 'none' }}
					src={src}
					preview={{
						visible,
						src,
						onVisibleChange: value => {
							setVisible(value)
						},
					}}
				/>
			) : (
				<Image
					width={0}
					style={{ display: 'none' }}
					src={src}
					preview={{
						visible,
						imageRender: () => (
							<video muted width='500px' height='300px' controls src={src} />
						),
						toolbarRender: () => null,
						onVisibleChange: value => {
							setVisible(value)
						},
					}}
				/>
			)}
			<Box sx={{ width: '100%' }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						marginBottom: '8px',
						gridGap: '8px',
					}}
				>
					<Space.Compact>
						<Upload {...props}>
							<Button icon={<UploadOutlined />}>{t('upload')}</Button>
						</Upload>
					</Space.Compact>

					<Input
						value={inp}
						prefix={<Icon />}
						style={{ width: '100%' }}
						onChange={e => handlerOnChangeServiceUri(e.target.value)}
					/>

					{cdns && (
						<Select
							style={{ width: '200px' }}
							options={cdns}
							defaultValue={currentCdn}
							onChange={val => {
								setCurrentCdn(val)
							}}
						/>
					)}
				</Box>

				<Box sx={{ mb: '10px' }}>
					<Items parentKey={''} tree={tree} display={true} parent={true} />
				</Box>

				<TextArea style={{ width: '100%' }} value={value} onChange={onChange} />
			</Box>
		</>
	)
}

export default Uploader
