import React, { useEffect, useState } from 'react'
import { Sheet, Divider } from '@mui/joy'

import List from './List'
import Search from './Search'
import Header from './Header'

import { useAppDispatch } from '../../../../store'
import { getAllAdvertisers } from '../../../../store/advertisers'
import { getAllCategories } from '../../../../store/categories'
import { useCookies } from 'react-cookie'

export default function Sidebar() {
	const dispatch = useAppDispatch()
	const [searchCandidate, setSearchCandidate] = useState('');

	const [cookie] = useCookies([
		'advertisersListActive',
		'advertisersListCategory',
		'reload',
	])

	useEffect(() => {
		dispatch(getAllCategories())

		dispatch(
			getAllAdvertisers({
				active: cookie.advertisersListActive,
				category: cookie.advertisersListCategory,
			})
		)
	}, [
		cookie.reload,
		cookie.advertisersListActive,
		cookie.advertisersListCategory,
		dispatch,
	])

	return (
		<Sheet
			sx={{
				borderRight: '1px solid',
				borderColor: 'divider',
				transition: 'transform 0.4s',
				height: 'calc(100dvh - var(--Topbar-height))',
				top: 0,
				pt: 1,
				overflowY: 'auto',
				flexShrink: 0,
				display: 'flex',
				flexDirection: 'column',
				zIndex: 999,
			}}
		>
			<Header />
			<Divider />
			<Search setSearchCandidate={setSearchCandidate}/>
			<List searchCandidate={searchCandidate} />
		</Sheet>
	)
}
