import React from 'react'

import Basic from './Basic'
import { Sheet, AccordionGroup } from '@mui/joy'
import { accordionDetailsClasses } from '@mui/joy/AccordionDetails'
import { accordionSummaryClasses } from '@mui/joy/AccordionSummary'

const Form = ({ values }) => {
	const accordionStyles = {
		[`& .${accordionSummaryClasses.indicator}`]: {
			transition: '0.2s',
		},
		[`& [aria-expanded="true"] .${accordionSummaryClasses.indicator}`]: {
			transform: 'rotate(45deg)',
		},
		[`& .${accordionSummaryClasses.button}:hover`]: {
			bgcolor: 'transparent',
		},
		[`& .${accordionDetailsClasses.content}`]: {
			gridGap: '10px',
			boxShadow: theme => `inset 0 1px ${theme.vars.palette.divider}`,
			padding: '1.2rem',
			paddingBlock: '1rem',
		},
		[`& .${accordionSummaryClasses.button}`]: {
			padding: '1.2rem',
			paddingBlock: '0.65rem',
		},
		[`& .${accordionSummaryClasses.button}:last-child`]: {
			borderBottom: 'none',
		},
	}

	const { arr, set } = values

	return (
		<Sheet
			variant='outlined'
			sx={{
				border: 'none',
				borderBottom: '1px solid',
				borderColor: 'divider',
				transition: 'transform 0.4s',
				backgroundColor: 'transparent',
				overflow: 'hidden',
			}}
		>
			<AccordionGroup size={'sm'} sx={accordionStyles}>
				<Basic values={{ set, arr }} />
			</AccordionGroup>
		</Sheet>
	)
}

export default Form
