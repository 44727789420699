import React from 'react'
import { Link } from '@mui/joy'
import { ExpandMoreRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useReport } from '../../../../../hooks/report'

const Head = ({ name }) => {
	const { t } = useTranslation()
	const { report, replaceOption } = useReport()
	const groupByText = t('group_by', { returnObjects: true })

	const {
		group_by = [],
		options: {
			columns = [],
			unique = '0',
			order_by: orderBy,
			order_by_key: orderByKey,
		} = {},
	} = report[name] || {}

	const createSortHandler = newKey => {
		const isAsc = orderBy === 'asc'
		const newValueOrderBy = isAsc ? 'desc' : 'asc'

		replaceOption({
			name,
			key: { order_by: newValueOrderBy, order_by_key: newKey },
		})
	}

	const Th = ({ text, itemKey, numeric = true }) => {
		const active =
			report[name] &&
			orderByKey.toString().toLowerCase() === itemKey.toString().toLowerCase()

		return (
			<th style={{ ...(numeric ? { width: '110px', textAlign: 'end' } : {}) }}>
				<Link
					underline='none'
					color='neutral'
					textColor={active ? 'primary.plainColor' : undefined}
					component='button'
					onClick={() => {
						createSortHandler(itemKey)
					}}
					fontWeight='lg'
					startDecorator={
						numeric ? (
							<ExpandMoreRounded sx={{ opacity: active ? 1 : 0, mr: '-4px' }} />
						) : null
					}
					endDecorator={
						!numeric ? (
							<ExpandMoreRounded sx={{ opacity: active ? 1 : 0, ml: '-4px' }} />
						) : null
					}
					sx={{
						'& svg': {
							transition: '0.2s',
							transform:
								active && orderBy === 'desc'
									? 'rotate(0deg)'
									: 'rotate(180deg)',
						},
						'&:hover': { '& svg': { opacity: 1 } },
					}}
				>
					{text}
				</Link>
			</th>
		)
	}

	const isAvailable = key => !columns.includes(key)

	const Item = ({ item }) =>
		isAvailable(item) && <Th text={t(item)} itemKey={item} />

	return (
		<thead>
			<tr>
				{group_by.map((item, k) =>
					isAvailable(`customField${k}`) ? (
						<Th
							key={`customField${k}`}
							itemKey={`customField${k}`}
							text={groupByText[item]}
							numeric={false}
						/>
					) : null
				)}

				<Item item={`count`} />
				<Item item={`clicks`} />
				<Item item={`ctr`} />
				{unique === '1' && (
					<>
						<Item item={`unique`} />
						<Item item={`frequency`} />
					</>
				)}
				<Item item={`spend`} />
			</tr>
		</thead>
	)
}

export default Head
