import React, { useState } from 'react'

import { Box } from '@mui/joy'
import { Input, Select, Space } from 'antd'
import { useFilter } from '../../../../../hooks/filter'
import { useTranslation } from 'react-i18next'

import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const Referer = () => {
	const { t } = useTranslation()
	const { updateFilter } = useFilter()
	const [referer, setReferer] = useState('')
	const [condition, setCondition] = useState('=')

	const include = () => {
		if (!referer) return
		updateFilter('in_referer', {
			value: `${referer}${condition}`,
			referer,
			condition,
		})
	}

	const exclude = () => {
		if (!referer) return
		updateFilter('ex_referer', {
			value: `${referer}${condition}`,
			referer,
			condition,
		})
	}

	return (
		<>
			<Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						flex: 1,
						gridGap: 10,
						alignItems: 'center',
					}}
				>
					<Space.Compact block>
						<Input
							onChange={e => setReferer(e.target.value)}
							placeholder={t('value')}
							defaultValue={referer}
						/>
						<Select
							key={'conditions'}
							style={{ minWidth: '120px' }}
							defaultValue={condition}
							onChange={val => setCondition(val)}
							options={[
								{ label: t('referer_eq'), value: '=' },
								{ label: t('referer_contains'), value: '%' },
							]}
						/>
					</Space.Compact>
				</Box>

				<IncludeExcludeBtn include={include} exclude={exclude} />
			</Box>
		</>
	)
}

export default Referer
