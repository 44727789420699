import React from 'react'
import Splitter from './Splitter'
import Case from './Case'

const Report = ({ itemValue, itemKey }) => {
	if (!itemValue || itemValue.length === 0) {
		return null
	}

	const renderCases = values =>
		values.map((value, index) => (
			<Case key={index} itemKey={itemKey} inSplitter={true} value={value} />
		))

	return itemValue.length === 1 ? (
		<Case itemKey={itemKey} inSplitter={false} value={itemValue[0]} />
	) : (
		<Splitter
			cacheKey={`${itemKey}-0`}
			vertical={itemValue[0].vertical}
			secondaryInitialSize={itemValue.length === 3 ? (100 / 3) * 2 : 50}
		>
			{renderCases(itemValue.slice(0, 1))}
			{itemValue.length > 2 && (
				<Splitter
					cacheKey={`${itemKey}-1`}
					vertical={itemValue[1].vertical}
					secondaryInitialSize={50}
				>
					{renderCases(itemValue.slice(1))}
				</Splitter>
			)}
			{itemValue.length === 2 && renderCases(itemValue.slice(1))}
		</Splitter>
	)
}

export default Report
