import React, { useState, useEffect, useCallback } from 'react'

import { Modal, ModalDialog, ModalClose } from '@mui/joy'
import { DialogTitle, DialogContent, Divider } from '@mui/joy'
import { Tabs, TabList, Tab, TabPanel, Tooltip, IconButton } from '@mui/joy'
import { tabClasses } from '@mui/joy/Tab'
import { Box, Sheet, Table } from '@mui/joy'
import { AnnouncementRounded } from '@mui/icons-material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import { toast } from '../../layout/JoyToaster'
import { useAppDispatch } from '../../../store'
import { customFieldGetAll, customParamGetAll } from '../../../store/custom'

const MacrosList = require('../../configs/macros.json')

export const MacrosBtn = ({ onClick }) => {
	const { t } = useTranslation()

	return (
		<Tooltip
			arrow
			size='sm'
			color='primary'
			placement='top'
			title={t('macros')}
			variant='solid'
		>
			<IconButton size='sm' onClick={onClick}>
				<AnnouncementRounded sx={{ fontSize: '20px' }} color='primary' />
			</IconButton>
		</Tooltip>
	)
}

export const Macros = ({ open, setOpen }) => {
	const { t } = useTranslation()
	const text = t('sidebar_navigation', { returnObjects: true })
	const macrosDescriptions = t('macros_list', { returnObjects: true })
	const dispatch = useAppDispatch()
	const [cookie] = useCookies(['reload'])
	const [index] = useState(0)

	const getRows = useCallback(() => {
		dispatch(customFieldGetAll())
		dispatch(customParamGetAll())
	}, [dispatch])

	const fields = useSelector(state => state.custom.customFieldsGetAllData?.rows)
	const params = useSelector(state => state.custom.customParamsGetAllData?.rows)

	useEffect(() => {
		getRows()
	}, [cookie.reload, getRows])

	const sheetSx = {
		border: '1px solid',
		borderColor: 'divider',
		transition: 'transform 0.4s',
		borderRadius: '8px',
		overflow: 'hidden',
	}

	const tableSx = {
		'--Table-headerUnderlineThickness': '1px',
		'--TableCell-height': '33px',
		'--TableCell-paddingX': '15px',
		'--TableCell-paddingY': '3px',
		[`& thead th`]: {
			verticalAlign: 'inherit',
		},
	}

	const MainTable = () => (
		<Box sx={{ maxWidth: 'clamp(450px, 100dvw, 900px)' }}>
			<Sheet variant='outlined' sx={sheetSx}>
				<Table
					borderAxis={'xBetween'}
					stickyHeader
					hoverRow
					size='sm'
					sx={tableSx}
				>
					<thead>
						<tr>
							<th
								style={{
									backgroundColor: 'var(--joy-palette-background-level1)',
								}}
							>
								{t('macros')}
							</th>
							<th
								style={{
									backgroundColor: 'var(--joy-palette-background-level1)',
									width: '300px',
								}}
							>
								{t('description')}
							</th>
						</tr>
					</thead>
					<tbody>
						{Object.keys(MacrosList).map(row => (
							<tr key={row}>
								<td>{macrosDescriptions[MacrosList[row]]}</td>
								<td>
									<CopyToClipboard
										text={`$$${row}$$`}
										onCopy={(_, result) => {
											if (result) {
												toast.success(t('copied'))
											}
										}}
									>
										<code style={{ cursor: 'pointer' }}>$${row}$$</code>
									</CopyToClipboard>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Sheet>
		</Box>
	)

	const FieldTable = () => (
		<Box sx={{ maxWidth: 'clamp(450px, 100dvw, 900px)' }}>
			<Sheet variant='outlined' sx={sheetSx}>
				<Table
					borderAxis={'xBetween'}
					stickyHeader
					hoverRow
					size='sm'
					sx={tableSx}
				>
					<thead>
						<tr>
							<th
								style={{
									backgroundColor: 'var(--joy-palette-background-level1)',
									width: '200px',
								}}
							>
								{t('name')}
							</th>
							<th
								style={{
									backgroundColor: 'var(--joy-palette-background-level1)',
								}}
							>
								{t('value')}
							</th>
							<th
								style={{
									backgroundColor: 'var(--joy-palette-background-level1)',
								}}
							>
								{t('key')}
							</th>
						</tr>
					</thead>
					<tbody>
						{fields &&
							fields.map(row => (
								<tr key={row.field_id}>
									<td>{row.name}</td>
									<td>{row.field_value}</td>
									<td>
										<CopyToClipboard
											text={`$$CF[${row.field_key}]$$`}
											onCopy={(_, result) => {
												if (result) {
													toast.success(t('copied'))
												}
											}}
										>
											<code style={{ cursor: 'pointer' }}>
												$$CF[{row.field_key}]$$
											</code>
										</CopyToClipboard>
									</td>
								</tr>
							))}
					</tbody>
				</Table>
			</Sheet>
		</Box>
	)

	const ParamsTable = () => (
		<Box sx={{ maxWidth: 'clamp(450px, 100dvw, 900px)' }}>
			<Sheet variant='outlined' sx={sheetSx}>
				<Table
					borderAxis={'xBetween'}
					stickyHeader
					hoverRow
					size='sm'
					sx={tableSx}
				>
					<thead>
						<tr>
							<th
								style={{
									backgroundColor: 'var(--joy-palette-background-level1)',
									width: '200px',
								}}
							>
								{t('name')}
							</th>
							<th
								style={{
									backgroundColor: 'var(--joy-palette-background-level1)',
								}}
							>
								{t('value')}
							</th>
							<th
								style={{
									backgroundColor: 'var(--joy-palette-background-level1)',
								}}
							>
								{t('key')}
							</th>
						</tr>
					</thead>
					<tbody>
						{params &&
							params.map(row => (
								<tr key={row.param_id}>
									<td>{row.name}</td>
									<td>{row.param_value}</td>
									<td>
										<CopyToClipboard
											text={`$$CP[${row.param_key}]$$`}
											onCopy={(_, result) => {
												if (result) {
													toast.success(t('copied'))
												}
											}}
										>
											<code style={{ cursor: 'pointer' }}>
												$$CP[{row.param_key}]$$
											</code>
										</CopyToClipboard>
									</td>
								</tr>
							))}
					</tbody>
				</Table>
			</Sheet>
		</Box>
	)

	return (
		<>
			<Modal
				open={!!open}
				onClose={() => setOpen(false)}
				sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
			>
				<ModalDialog
					sx={{ width: '800px', maxHeight: '800px', minHeight: '400px' }}
					variant='outlined'
					role='alertdialog'
				>
					<DialogTitle>{t('macros')}</DialogTitle>
					<ModalClose variant='plain' sx={{ m: 1 }} />
					<Divider sx={{ mt: 1 }} />

					<DialogContent
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Tabs
							size={'sm'}
							orientation={'horizontal'}
							defaultValue={index}
							sx={{
								mt: 1,
								gridColumn: '1/-1',
								borderRadius: '8px',
								width: '100%',
								flexDirection: 'column',
								bgcolor: 'transparent',
							}}
						>
							<TabList
								underlinePlacement={'bottom'}
								sx={{
									p: 0.5,
									gap: 0.5,
									borderRadius: 'md',
									bgcolor: 'background.level1',
									border: 'none',
									boxShadow: 'none',
									[`& .${tabClasses.root}[aria-selected="true"]`]: {
										bgcolor: 'background.surface',
									},
								}}
							>
								<Tab disableIndicator>{t('main_macros')}</Tab>
								<Tab disableIndicator>{text['custom_fields']}</Tab>
								<Tab disableIndicator>{text['custom_params']}</Tab>
							</TabList>
							<TabPanel value={0} sx={{ px: 0, pb: 0 }}>
								<MainTable />
							</TabPanel>
							<TabPanel value={1} sx={{ px: 0, pb: 0, overflowY: 'auto' }}>
								<FieldTable />
							</TabPanel>
							<TabPanel value={2} sx={{ px: 0, pb: 0, overflowY: 'auto' }}>
								<ParamsTable />
							</TabPanel>
						</Tabs>
					</DialogContent>
				</ModalDialog>
			</Modal>
		</>
	)
}
