import React from 'react'
import { Box, Sheet, Typography, Divider } from '@mui/joy'
import { PeopleAltRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import SearchUser from './SearchUser'
import ActiveBtn from './ActiveBtn'
import AddNewUser from './AddNewUser'

const ControlBar = props => {
	const { t } = useTranslation()
	const text = t('sidebar_navigation', { returnObjects: true })

	return (
		<>
			<Sheet
				variant='outlined'
				sx={{
					border: 'none',
					overflow: 'hidden',
					px: 2,
					py: 1.5,
					display: 'flex',
					alignItems: 'center',
					backgroundColor:
						'var(--variant-softBg, var(--joy-palette-primary-softBg, var(--joy-palette-primary-100, #E3EFFB)))',
				}}
			>
				<Typography
					level='title-md'
					sx={{ flex: 1, display: 'flex', alignItems: 'center' }}
				>
					<PeopleAltRounded sx={{ mr: '10px' }} /> {text['users']}
				</Typography>

				<Box sx={{ gridGap: '10px', display: 'flex', flex: 1.6 }}>
					<SearchUser search={props.search} />
					<ActiveBtn search={props.search} />
					<Divider orientation='vertical' />
					<AddNewUser />
				</Box>
			</Sheet>
		</>
	)
}

export default ControlBar
