import * as React from 'react'

import { Tab, Tabs, TabList, TabPanel } from '@mui/joy'
import { tabClasses } from '@mui/joy/Tab'
import { useTranslation } from 'react-i18next'

import CustomTargets from './CustomTargets'
import IpTargets from './IpTargets'
import BrowserTargets from './BrowserTargets'
import Limits from './Limits'
import Capping from './Capping'
import StartAndStop from './StartAndStop'
import Zones from './Zones'
import Retarget from './Retarget'

import { RetargetProvider } from '../../../../hooks/retarget'

const TargetsTabs = ({ rotation, current, type }) => {
	const { t } = useTranslation()

	return (
		<Tabs
			orientation={'horizontal'}
			defaultValue={0}
			sx={{
				mt: 1,
				gridColumn: '1/-1',
				borderRadius: '8px',
				width: '100%',
				flexDirection: 'column',
				bgcolor: 'transparent',

				[`& .MuiAccordionDetails-content`]: {
					gridGap: 0,
				},
			}}
		>
			<TabList
				underlinePlacement={'bottom'}
				sx={{
					p: 0.5,
					gap: 0.5,
					borderRadius: '8px',
					bgcolor: 'background.level1',
					border: 'none',
					boxShadow: 'none',
					[`& .${tabClasses.root}[aria-selected="true"]`]: {
						bgcolor: 'background.surface',
					},
				}}
				size='sm'
			>
				<Tab value={0} disableIndicator>
					{t('target_ip')}
				</Tab>
				<Tab value={1} disableIndicator>
					{t('target_browser')}
				</Tab>
				<Tab value={2} disableIndicator>
					{t('target_1_custom')}
				</Tab>
				<Tab value={3} disableIndicator>
					{t('target_1_date')}
				</Tab>
				<Tab value={4} disableIndicator>
					{t('target_2')}
				</Tab>
				<Tab value={5} disableIndicator>
					{t('target_2_zones')}
				</Tab>
				{!['wrapper', 'interests', 'retarget'].includes(type) && (
					<Tab value={6} disableIndicator>
						{t('target_3')}
					</Tab>
				)}
				{['retarget'].includes(type) && (
					<Tab value={7} disableIndicator>
						{t('retarget')}
					</Tab>
				)}
			</TabList>

			<TabPanel value={0} sx={{ px: 0, pb: 0 }}>
				<IpTargets rotation={rotation} current={current} />
			</TabPanel>
			<TabPanel value={1} sx={{ px: 0, pb: 0 }}>
				<BrowserTargets rotation={rotation} current={current} />
			</TabPanel>
			<TabPanel value={2} sx={{ px: 0, pb: 0 }}>
				<CustomTargets rotation={rotation} current={current} />
			</TabPanel>
			<TabPanel
				value={3}
				sx={{ px: 0, pb: 0, overflowY: 'auto', minHeight: '430px' }}
			>
				<StartAndStop rotation={rotation} current={current} />
			</TabPanel>
			<TabPanel value={4} sx={{ px: 0, pb: 0, overflowY: 'auto' }}>
				<Limits rotation={rotation} current={current} />
			</TabPanel>

			<TabPanel value={5} sx={{ px: 0, pb: 0, overflowY: 'auto' }}>
				<Zones rotation={rotation} current={current} />
			</TabPanel>

			{!['wrapper', 'interests', 'retarget'].includes(type) && (
				<TabPanel value={6} sx={{ px: 0, pb: 0, overflowY: 'auto' }}>
					<Capping rotation={rotation} current={current} />
				</TabPanel>
			)}
			{['retarget'].includes(type) && (
				<TabPanel value={7} sx={{ px: 0, pb: 0, overflowY: 'auto' }}>
					<RetargetProvider>
						<Retarget rotation={rotation} current={current} />
					</RetargetProvider>
				</TabPanel>
			)}
		</Tabs>
	)
}

export default TargetsTabs
