import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	IBannersAddRequest,
	IBannersAddResponse,
	IBannersTrackerAddRequest,
	IBannersTrackerAddResponse,
	IBannersUploadRequest,
	IBannersUploadResponse,
} from './types'

export const create = (
	params: IBannersAddRequest
): AxiosPromise<IBannersAddResponse> =>
	axiosInstance.post(Endpoints.BANNERS.ADD, params)

export const trackerCreate = (
	params: IBannersTrackerAddRequest
): AxiosPromise<IBannersTrackerAddResponse> =>
	axiosInstance.post(Endpoints.BANNERS.TRACKER_ADD, params)

export const upload = (
	params: IBannersUploadRequest
): AxiosPromise<IBannersUploadResponse> =>
	axiosInstance.post(Endpoints.BANNERS.UPLOAD, params)
