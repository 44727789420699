import React from 'react'

import { Box, Sheet, Tooltip, Typography, IconButton } from '@mui/joy'
import { QueueRounded, DescriptionRounded } from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const ControlBar = () => {
	const { t } = useTranslation()
	const text = t('sidebar_navigation', { returnObjects: true })

	const AddNewUser = () => (
		<Tooltip
			arrow
			size='sm'
			color='primary'
			placement='top'
			title={t('add_template')}
			variant='solid'
		>
			<IconButton size='sm' component={NavLink} to={`/templetes/add`}>
				<QueueRounded
					sx={{
						fontSize: '20px',
						mx: '6px',
					}}
					color='primary'
				/>
			</IconButton>
		</Tooltip>
	)

	return (
		<Sheet
			variant='outlined'
			sx={{
				border: 'none',
				overflow: 'hidden',
				px: 2,
				py: 1.5,
				display: 'flex',
				alignItems: 'center',
				backgroundColor:
					'var(--variant-softBg, var(--joy-palette-primary-softBg, var(--joy-palette-primary-100, #E3EFFB)))',
			}}
		>
			<Typography
				level='title-md'
				sx={{ flex: 1, display: 'flex', alignItems: 'center' }}
			>
				<DescriptionRounded sx={{ mr: '10px' }} /> {text['templetes']}
			</Typography>

			<Box sx={{ gridGap: '10px', display: 'flex' }}>
				<AddNewUser />
			</Box>
		</Sheet>
	)
}

export default ControlBar
