import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import {
	IAdvertisersAddRequest,
	IAdvertisersAddSharingRequest,
} from '../../api/advertisers/add/types'

import {
	IAdvertisersRemoveRequest,
	IAdvertisersRemoveSharingRequest,
} from '../../api/advertisers/delete/types'

import {
	IAdvertisersGetAllRequest,
	IAdvertisersGetRequest,
	IAdvertisersGetAllSharingRequest,
} from '../../api/advertisers/get/types'

import {
	IAdvertisersSetActiveRequest,
	IAdvertisersEditRequest,
} from '../../api/advertisers/edit/types'

import {
	advertiserAddStart,
	advertiserAddSuccess,
	advertiserAddFailure,
	advertiserGetAllSuccess,
	advertiserGetAllFailure,
	advertiserGetAllStart,
	advertiserSetActiveStart,
	advertiserSetActiveSuccess,
	advertiserSetActiveFailure,
	advertiserDeleteStart,
	advertiserDeleteSuccess,
	advertiserDeleteFailure,
	advertiserEditStart,
	advertiserEditSuccess,
	advertiserEditFailure,
	advertiserGetStart,
	advertiserGetSuccess,
	advertiserGetFailure,
	advertiserAddSharingStart,
	advertiserAddSharingSuccess,
	advertiserAddSharingFailure,
	advertiserGetAllSharingStart,
	advertiserGetAllSharingSuccess,
	advertiserGetAllSharingFailure,
	advertiserSharingDeleteStart,
	advertiserSharingDeleteSuccess,
	advertiserSharingDeleteFailure,
} from './reducer'

export const advertiserAdd =
	(data: IAdvertisersAddRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(advertiserAddStart())
			const result = await api.advertisersAdd.create(data)
			dispatch(advertiserAddSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(advertiserAddFailure(e.message))
			return false
		}
	}

export const advertiserAddSharing =
	(data: IAdvertisersAddSharingRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(advertiserAddSharingStart())
			await api.advertisersAdd.sharing(data)
			dispatch(advertiserAddSharingSuccess())
			return true
		} catch (e: any) {
			dispatch(advertiserAddSharingFailure(e.message))
			return false
		}
	}

export const advertiserDel =
	(data: IAdvertisersRemoveRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(advertiserDeleteStart())
			await api.advertisersDel.remove(data)
			dispatch(advertiserDeleteSuccess())
			return true
		} catch (e: any) {
			dispatch(advertiserDeleteFailure(e.message))
			return false
		}
	}

export const sharingDel =
	(data: IAdvertisersRemoveSharingRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(advertiserSharingDeleteStart())
			await api.advertisersDel.removeSharing(data)
			dispatch(advertiserSharingDeleteSuccess())
			return true
		} catch (e: any) {
			dispatch(advertiserSharingDeleteFailure(e.message))
			return false
		}
	}

export const advertisersEdit =
	(data: IAdvertisersEditRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(advertiserEditStart())
			await api.advertisersEdit.update(data)
			dispatch(advertiserEditSuccess())
			return true
		} catch (e: any) {
			dispatch(advertiserEditFailure(e.message))
			return false
		}
	}

export const getAdvertiser =
	(data: IAdvertisersGetRequest) =>
	async (dispatch: Dispatch<any>): Promise<any> => {
		try {
			dispatch(advertiserGetStart())
			const result = await api.advertisersGet.get(data)
			dispatch(advertiserGetSuccess({ result: result.data }))
			return result.data
		} catch (e: any) {
			dispatch(advertiserGetFailure(e.message))
			return false
		}
	}

export const getAllAdvertisers =
	(data: IAdvertisersGetAllRequest) =>
	async (dispatch: Dispatch<any>): Promise<void> => {
		try {
			dispatch(advertiserGetAllStart())
			const result = await api.advertisersGet.getAll(data)
			dispatch(advertiserGetAllSuccess({ result: result.data }))
		} catch (e: any) {
			dispatch(advertiserGetAllFailure(e.message))
		}
	}

export const getAllAdvertiserSharings =
	(data: IAdvertisersGetAllSharingRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(advertiserGetAllSharingStart())
			const result = await api.advertisersGet.getAllSharings(data)
			dispatch(advertiserGetAllSharingSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(advertiserGetAllSharingFailure(e.message))
			return false
		}
	}

export const setActive =
	(data: IAdvertisersSetActiveRequest) =>
	async (dispatch: Dispatch<any>): Promise<void> => {
		try {
			dispatch(advertiserSetActiveStart())
			await api.advertisersEdit.setActive(data)
			dispatch(advertiserSetActiveSuccess())
		} catch (e: any) {
			dispatch(advertiserSetActiveFailure(e.message))
		}
	}
