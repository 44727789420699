import React from 'react'

import { Box } from '@mui/joy'
import { useParams } from 'react-router-dom'

import Add from './Add'
import Main from './Main'
import Edit from './Edit'

const Routers = () => {
	const { page } = useParams()

	const pages = {
		add: Add,
		edit: Edit,
		main: Main,
	}

	const CurrentPage = pages[page] ?? pages.main

	return (
		<Box
			component='main'
			className='MainContent'
			sx={theme => ({
				borderRight: '1px solid',
				borderColor: 'divider',
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
				minWidth: 0,
				minHeight: 'calc(100dvh - var(--Topbar-height))',
				maxWidth: 'clamp(450px, 100dvw, 900px)',
				overflowY: 'auto',
			})}
		>
			<CurrentPage />
		</Box>
	)
}

export default Routers
