import * as React from 'react'

import {
	WebRounded,
	TranslateRounded,
	ResetTvRounded,
	DevicesOtherRounded,
	ImportantDevicesRounded,
	ShareRounded,
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { Accord, AccordItem } from '../Accord'

import Browser from './Browser'
import OperatingSystem from './OperatingSystem'
import DeviceFormat from './DeviceFormat'
import DeviceVendor from './DeviceVendor'
import Language from './Language'
import Referer from './Referer'

const Targets = ({ rotation, current }) => {
	const { t } = useTranslation()

	return (
		<>
			<Accord
				items={
					<>
						<AccordItem
							text={t('target_1_browser')}
							icon={<WebRounded />}
							items={<Browser rotation={rotation} current={current} />}
						/>
						<AccordItem
							text={t('target_1_browser_lang')}
							icon={<TranslateRounded />}
							items={<Language rotation={rotation} current={current} />}
						/>
						<AccordItem
							text={t('target_1_os')}
							icon={<ResetTvRounded />}
							items={<OperatingSystem rotation={rotation} current={current} />}
						/>
						<AccordItem
							text={t('target_1_device')}
							icon={<DevicesOtherRounded />}
							items={<DeviceFormat rotation={rotation} current={current} />}
						/>
						<AccordItem
							text={t('target_1_device_vendor')}
							icon={<ImportantDevicesRounded />}
							items={<DeviceVendor rotation={rotation} current={current} />}
						/>
						<AccordItem
							text={t('target_1_referer')}
							icon={<ShareRounded />}
							last={1}
							items={<Referer rotation={rotation} current={current} />}
						/>
					</>
				}
			/>
		</>
	)
}

export default Targets
