import React, { createContext, useContext, useState } from 'react'
export const TabContext = createContext()

export function TabProvider({ children }) {
	const [tabs, setTabs] = useState({})
	const [currentTab, setCurrentTab] = useState('options')
	const [changeTab, setChangeTab] = useState('')

	const deleteTab = key => {
		return setTabs(current => {
			const newTabs = { ...current }
			if (currentTab !== 'options' && newTabs.hasOwnProperty(key)) {
				delete newTabs[key]
			}

			let newTabId = Object.keys(newTabs)
			newTabId = newTabId[newTabId.length - 1] || 'options'
			setCurrentTab(newTabId)
			setChangeTab(newTabId)

			return newTabs
		})
	}

	const updateTab = (key, newValue) => {
		setTabs(current => {
			const updatedTabs = { ...current }

			if (!updatedTabs.hasOwnProperty(key)) {
				setChangeTab(key)
				updatedTabs[key] = [newValue]
			} else {
				if (!updatedTabs[key].includes(newValue)) {
					updatedTabs[key] = [...updatedTabs[key], newValue]
				}
			}

			return updatedTabs
		})
	}

	const replaceTabs = (key, newValue, add = false) => {
		setTabs(current => {
			const updatedTabs = { ...current }

			if (updatedTabs.hasOwnProperty(key) || add) {
				updatedTabs[key] = newValue
			}

			return updatedTabs
		})
	}

	return (
		<TabContext.Provider
			value={{
				tabs,
				changeTab,
				updateTab,
				currentTab,
				setCurrentTab,
				deleteTab,
				replaceTabs,
			}}
		>
			{children}
		</TabContext.Provider>
	)
}

export function useTab() {
	const context = useContext(TabContext)
	if (!context) {
		throw new Error('useTab must be used within a TabContext')
	}
	return context
}
