import React, { useState } from 'react'

import { Select } from 'antd'
import { useFilter } from '../../../../../hooks/filter'

const ReportType = () => {
	const { filter, updateFilter } = useFilter()
	const [report, setReport] = useState(filter.report_type || 1)

	const reportTypes = [
		{ value: 1, label: 'Advanced', disabled: false },
		{ value: 2, label: 'Re-Target', disabled: true },
		{ value: 3, label: 'Logs', disabled: false },
	]

	return (
		<Select
			defaultValue={report}
			maxTagCount={'responsive'}
			style={{ marginRight: '12px', flex: 1 }}
			options={reportTypes}
			onChange={newValue => {
				setReport(newValue)
				updateFilter('report_type', newValue)
			}}
		/>
	)
}

export default ReportType
