import React, { useState, useEffect } from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Input, Switch, Select } from 'antd'
import { Add, PermMediaRounded } from '@mui/icons-material'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../../../../store'
import { rotationGetAll } from '../../../../../../../store/rotations'
import { useZone } from '../../../../../../hooks/zones'

import FormItem from '../../../../../../layout/FormItem'

const Basic = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [index, setIndex] = useState(0)
	const [cookie] = useCookies(['reload'])
	const { zone, updateZones } = useZone()
	const [checked, setChecked] = useState(zone.active === 1 ? true : false)
	const rotations = useSelector(state => state.rotations.getAllData?.rows)

	useEffect(() => {
		dispatch(rotationGetAll({ active: 'all' }))
	}, [cookie.reload, dispatch])

	return (
		<Accordion
			sx={{ border: 'none' }}
			expanded={index === 0}
			onChange={(_, expanded) => {
				setIndex(expanded ? 0 : null)
			}}
		>
			<AccordionSummary indicator={<Add />} onClick={e => e.preventDefault()}>
				<PermMediaRounded />
				<Box sx={{ flex: 1, fontSize: '13px' }}>
					{t('add_advertiser_basic')}
				</Box>
			</AccordionSummary>

			{zone.name !== undefined && (
				<>
					<AccordionDetails>
						<FormItem
							text={'add_advertiser_basic_name'}
							component={
								<Input
									sx={{ width: '100%' }}
									value={!!zone.name ? zone.name : ''}
									max={180}
									onChange={e => {
										updateZones('zone', e.target.value)
									}}
								/>
							}
						/>

						<FormItem
							text={'rotation'}
							component={
								<Select
									size='sm'
									style={{ width: '100%' }}
									options={rotations}
									fieldNames={{ label: 'name', value: 'id' }}
									value={zone.rotation_id}
									onChange={val => {
										updateZones('rotation_id', val === '' ? 0 : parseInt(val))
									}}
								/>
							}
						/>

						<FormItem
							text={'add_advertiser_basic_active'}
							component={
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Switch
										style={{ marginLeft: 'initial', marginRight: 'auto' }}
										size='small'
										checked={checked}
										onChange={e => {
											setChecked(e)
											updateZones('active', e ? 1 : 0)
										}}
									/>
								</Box>
							}
						/>
					</AccordionDetails>
				</>
			)}
		</Accordion>
	)
}

export default Basic
