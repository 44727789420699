import React, { useState } from 'react'
import { Box } from '@mui/joy'
import { toast } from '../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../store'
import { categoryAdd } from '../../../../store/categories'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import Form from './Form'
import Submit from './Submit'

const Add = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const defaultFormValues = { name: '' }

	const [, setCookie] = useCookies(['reload'])
	const [formValues, setFormValues] = useState(defaultFormValues)

	const handlerForm = event => {
		event.preventDefault()
		dispatch(categoryAdd(formValues)).then(result => {
			result ? toast.success(t('add_category_added')) : toast.error(t('error'))
			setCookie('reload', new Date().getTime(), { path: '/' })
		})
	}

	return (
		<Box
			sx={{
				maxWidth: 'clamp(450px, 100dvw, 900px)',
				width: '100%',
				overflowY: 'auto',
				borderRight: '1px solid',
				borderColor: 'divider',
				backgroundColor: 'var(--joy-palette-background-surface)',
			}}
		>
			<form onSubmit={handlerForm}>
				<Form values={{ set: setFormValues, arr: formValues }} />
				<Submit />
			</form>
		</Box>
	)
}

export default Add
