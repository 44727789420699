import React, { useEffect } from 'react'

import { Box, CircularProgress } from '@mui/joy'
import ControlBar from './ControlBar'
import Form from './Form'

import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import { getPublisher } from '../../../../store/publishers'
import { usePub } from '../../../hooks/publishers'

const Edit = () => {
	const dispatch = useAppDispatch()
	const { pub, replacePublisher } = usePub()
	const { pageId } = useParams()
	const parsedPageId = parseInt(pageId, 10)

	useEffect(() => {
		const isValid = !!pub ? !pub.id || pub.id !== parsedPageId : true

		if (isValid && parsedPageId > 0) {
			dispatch(getPublisher({ publisher_id: parsedPageId })).then(result => {
				if (!result.error) {
					replacePublisher(result.rows)
				}
			})
		}
	}, [parsedPageId, pub, replacePublisher, dispatch])

	return !!pub && Object.keys(pub).length && pub.id === parsedPageId ? (
		<Box sx={{ width: '100%' }}>
			<ControlBar />
			<Form />
		</Box>
	) : (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				minHeight: '20dvh',
			}}
		>
			<CircularProgress size='sm' />
		</Box>
	)
}

export default Edit
