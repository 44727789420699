import React from 'react'
import { useParams } from 'react-router-dom'

import Add from './Add'
import Edit from './Edit'
import Main from './Main'

const Routers = () => {
	const { page } = useParams()

	const pages = {
		add: Add,
		edit: Edit,
		main: Main,
	}

	const CurrentPage = pages[page] ?? pages.main
	return <CurrentPage />
}

export default Routers
