import React, { createContext, useContext, useState } from 'react'
export const FilterContext = createContext()

export function FilterProvider({ children }) {
	const [filter, setFilter] = useState({
		report_type: '',
		group_by: [],

		order_by: 'desc',
		order_by_key: 'count',
		page: 0,
		pages: 1,
		onPage: 10,
		search: '',
		openOptions: false,
		columns: [],

		unique: '0',
		new: '0',

		date_from: '',
		date_to: '',

		in_country: [],
		in_city: [],
		in_isp: [],
		in_browser: [],
		in_os: [],
		in_language: [],
		in_device_format: [],
		in_vendor: [],
		in_advertiser: [],
		in_campaign: [],
		in_banner: [],
		in_publisher: [],
		in_zone: [],
		in_connection_type: [],
		in_category: [],
		in_template: [],
		in_ip_range: [],
		in_referer: [],
		in_custom: [],
		in_region: [],
		in_vpn: '0',
		in_tor: '0',

		ex_country: [],
		ex_region: [],
		ex_city: [],
		ex_ip_range: [],
		ex_isp: [],
		ex_browser: [],
		ex_os: [],
		ex_language: [],
		ex_device_format: [],
		ex_vendor: [],
		ex_referer: [],
		ex_custom: [],
		ex_advertiser: [],
		ex_campaign: [],
		ex_banner: [],
		ex_publisher: [],
		ex_zone: [],
		ex_connection_type: [],
		ex_category: [],
		ex_template: [],
		ex_vpn: '0',
		ex_tor: '0',
	})

	const updateFilter = (key, newValue) => {
		setFilter(current => {
			if (current.hasOwnProperty(key) && Array.isArray(current[key])) {
				if (Array.isArray(newValue)) {
					let updatedArray = [...current[key]]
					newValue.forEach(newItem => {
						if (typeof newItem === 'string' || typeof newItem === 'number') {
							if (!updatedArray.includes(newItem)) {
								updatedArray.push(newItem)
							}
						} else if (typeof newItem === 'object' && newItem !== null) {
							if (!updatedArray.some(item => item.value === newItem.value)) {
								updatedArray.push(newItem)
							}
						}
					})
					return { ...current, [key]: updatedArray }
				} else {
					if (typeof newValue === 'string' || typeof newValue === 'number') {
						if (!current[key].includes(newValue)) {
							return { ...current, [key]: [...current[key], newValue] }
						}
					} else if (typeof newValue === 'object' && newValue !== null) {
						if (!current[key].some(item => item.value === newValue.value)) {
							return { ...current, [key]: [...current[key], newValue] }
						}
					}
				}
			} else if (typeof newValue === 'string' || typeof newValue === 'number') {
				current[key] = newValue
				return { ...current, [key]: newValue }
			}
			return current
		})
	}

	const replaceItemFromFilter = (key, value) => {
		setFilter(current => {
			if (current.hasOwnProperty(key) && Array.isArray(current[key])) {
				current[key] = value
			}

			return current
		})
	}

	const replaceFilter = newFilter => {
		setFilter(newFilter)
	}

	return (
		<FilterContext.Provider
			value={{ filter, updateFilter, replaceFilter, replaceItemFromFilter }}
		>
			{children}
		</FilterContext.Provider>
	)
}

export function useFilter() {
	const context = useContext(FilterContext)
	if (!context) {
		throw new Error('useFilter must be used within a FilterProvider')
	}
	return context
}
