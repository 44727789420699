import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	IBannersGetAllRequest,
	IBannersGetAllResponse,
	IBannersGetRequest,
	IBannersGetResponse,
	IBannersTrackerGetAllRequest,
	IBannersTrackerGetAllResponse,
	IBannersCloudRequest,
	IBannersCloudResponse,
} from './types'

export const getAll = (
	params: IBannersGetAllRequest
): AxiosPromise<IBannersGetAllResponse> =>
	axiosInstance.post(
		`${Endpoints.BANNERS.GET_ALL}/${params.advertiser_id}`,
		params
	)

export const get = (
	params: IBannersGetRequest
): AxiosPromise<IBannersGetResponse> =>
	axiosInstance.get(
		`${Endpoints.BANNERS.GET}/${params.advertiser_id}/${params.banner_id}`
	)

export const trackerGetAll = (
	params: IBannersTrackerGetAllRequest
): AxiosPromise<IBannersTrackerGetAllResponse> =>
	axiosInstance.post(`${Endpoints.BANNERS.TRACKER_GET_ALL}`, params)

export const cloud = (
	params: IBannersCloudRequest
): AxiosPromise<IBannersCloudResponse> =>
	axiosInstance.post(`${Endpoints.BANNERS.CLOUD}`, params)
