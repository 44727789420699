import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import {
	ICampaignsAddRequest,
	ICampaignsPricesAddRequest,
	ICampaignsTrackerAddRequest,
} from '../../api/campaigns/add/types'

import {
	ICampaignsRemoveRequest,
	ICampaignsPricesRemoveRequest,
	ICampaignsTrackerRemoveRequest,
} from '../../api/campaigns/delete/types'

import {
	ICampaignsGetAllRequest,
	ICampaignsGetRequest,
	ICampaignsPricesGetAllRequest,
	ICampaignsTrackerGetAllRequest,
} from '../../api/campaigns/get/types'

import {
	ICampaignsSetActiveRequest,
	ICampaignsEditRequest,
} from '../../api/campaigns/edit/types'

import {
	campaignAddStart,
	campaignAddSuccess,
	campaignAddFailure,
	campaignEditStart,
	campaignEditSuccess,
	campaignEditFailure,
	campaignDeleteStart,
	campaignDeleteSuccess,
	campaignDeleteFailure,
	campaignGetAllSuccess,
	campaignGetAllFailure,
	campaignGetAllStart,
	campaignGetStart,
	campaignGetSuccess,
	campaignGetFailure,
	campaignSetActiveStart,
	campaignSetActiveSuccess,
	campaignSetActiveFailure,
	campaignPricesAddStart,
	campaignPricesAddSuccess,
	campaignPricesAddFailure,
	campaignPricesDeleteStart,
	campaignPricesDeleteSuccess,
	campaignPricesDeleteFailure,
	campaignPricesGetAllStart,
	campaignPricesGetAllSuccess,
	campaignPricesGetAllFailure,
	campaignTrackerAddStart,
	campaignTrackerAddSuccess,
	campaignTrackerAddFailure,
	campaignTrackerDeleteStart,
	campaignTrackerDeleteSuccess,
	campaignTrackerDeleteFailure,
	campaignTrackerGetAllStart,
	campaignTrackerGetAllSuccess,
	campaignTrackerGetAllFailure,
} from './reducer'

export const campaignAdd =
	(data: ICampaignsAddRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(campaignAddStart())
			const result = await api.campaignsAdd.create(data)
			dispatch(campaignAddSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(campaignAddFailure(e.message))
			return false
		}
	}

export const campaignDel =
	(data: ICampaignsRemoveRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(campaignDeleteStart())
			await api.campaignsDel.remove(data)
			dispatch(campaignDeleteSuccess())
			return true
		} catch (e: any) {
			dispatch(campaignDeleteFailure(e.message))
			return false
		}
	}

export const campaignEdit =
	(data: ICampaignsEditRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(campaignEditStart())
			await api.campaignsEdit.update(data)
			dispatch(campaignEditSuccess())
			return true
		} catch (e: any) {
			dispatch(campaignEditFailure(e.message))
			return false
		}
	}

export const campaignGet =
	(data: ICampaignsGetRequest) =>
	async (dispatch: Dispatch<any>): Promise<any> => {
		try {
			dispatch(campaignGetStart())
			const result = await api.campaignsGet.get(data)
			dispatch(campaignGetSuccess({ result: result.data }))
			return result.data
		} catch (e: any) {
			dispatch(campaignGetFailure(e.message))
			return false
		}
	}

export const campaignGetAll =
	(data: ICampaignsGetAllRequest) =>
	async (dispatch: Dispatch<any>): Promise<Object | boolean> => {
		try {
			dispatch(campaignGetAllStart())
			const result = await api.campaignsGet.getAll(data)
			dispatch(campaignGetAllSuccess({ result: result.data }))
			return result
		} catch (e: any) {
			dispatch(campaignGetAllFailure(e.message))
			return false
		}
	}

export const setActive =
	(data: ICampaignsSetActiveRequest) =>
	async (dispatch: Dispatch<any>): Promise<any> => {
		try {
			dispatch(campaignSetActiveStart())
			await api.campaignsEdit.setActive(data)
			dispatch(campaignSetActiveSuccess())
			return true
		} catch (e: any) {
			dispatch(campaignSetActiveFailure(e.message))
			return false
		}
	}

export const campaignPricesAdd =
	(data: ICampaignsPricesAddRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(campaignPricesAddStart())
			const result = await api.campaignsAdd.pricesCreate(data)
			dispatch(campaignPricesAddSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(campaignPricesAddFailure(e.message))
			return false
		}
	}

export const campaignPricesDel =
	(data: ICampaignsPricesRemoveRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(campaignPricesDeleteStart())
			await api.campaignsDel.pricesRemove(data)
			dispatch(campaignPricesDeleteSuccess())
			return true
		} catch (e: any) {
			dispatch(campaignPricesDeleteFailure(e.message))
			return false
		}
	}

export const campaignPricesGetAll =
	(data: ICampaignsPricesGetAllRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(campaignPricesGetAllStart())
			const result = await api.campaignsGet.pricesGetAll(data)
			dispatch(campaignPricesGetAllSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(campaignPricesGetAllFailure(e.message))
			return false
		}
	}

export const campaignTrackerAdd =
	(data: ICampaignsTrackerAddRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(campaignTrackerAddStart())
			const result = await api.campaignsAdd.trackerCreate(data)
			dispatch(campaignTrackerAddSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(campaignTrackerAddFailure(e.message))
			return false
		}
	}

export const campaignTrackerDel =
	(data: ICampaignsTrackerRemoveRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(campaignTrackerDeleteStart())
			await api.campaignsDel.trackerRemove(data)
			dispatch(campaignTrackerDeleteSuccess())
			return true
		} catch (e: any) {
			dispatch(campaignTrackerDeleteFailure(e.message))
			return false
		}
	}

export const campaignTrackerGetAll =
	(data: ICampaignsTrackerGetAllRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(campaignTrackerGetAllStart())
			const result = await api.campaignsGet.trackerGetAll(data)
			dispatch(campaignTrackerGetAllSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(campaignTrackerGetAllFailure(e.message))
			return false
		}
	}
