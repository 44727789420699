import React from 'react'
import Box from '@mui/joy/Box'

const Background = () => {
	const WhiteImg = 'https://source.unsplash.com/random/?white'
	const DarkImg = 'https://source.unsplash.com/random/?dark-blue'

	return (
		<Box
			sx={theme => ({
				height: '100%',
				position: 'fixed',
				top: 0,
				bottom: 0,
				right: 0,
				left: 0,
				transition:
					'background-image var(--Transition-duration), left var(--Transition-duration) !important',
				transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
				backgroundColor: 'background.level1',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundImage: `url(${WhiteImg})`,
				[theme.getColorSchemeSelector('dark')]: {
					backgroundImage: `url(${DarkImg})`,
				},
			})}
		>
			<Box
				sx={{
					textAlign: 'center',
					fontSize: '60px',
					fontWeight: 900,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100dvh',
					color: 'white',
					textTransform: 'uppercase',
					background: 'transparent',
					backdropFilter: 'blur(15px)',
				}}
			></Box>
		</Box>
	)
}

export default Background
