import React, { createContext, useContext, useState } from 'react'
export const CampaignContext = createContext()

export function CampaignProvider({ children }) {
	const [cmp, setCmp] = useState({})

	const updateCampaign = (key, newValue) => {
		setCmp(current => ({ ...current, [key]: newValue }))
	}

	const replaceCampaign = newValue => {
		setCmp(newValue)
	}

	return (
		<CampaignContext.Provider value={{ cmp, updateCampaign, replaceCampaign }}>
			{children}
		</CampaignContext.Provider>
	)
}

export function useCmp() {
	const context = useContext(CampaignContext)
	if (!context) {
		throw new Error('useCmp must be used within a Campaign')
	}
	return context
}
