import React from 'react'

import { Input, Select } from 'antd'
import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Add, ContactEmergencyRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FormItem from '../../../layout/FormItem'
const { TextArea } = Input

const Additional = ({ values: { set, arr } }) => {
	const { t } = useTranslation()
	const categories = useSelector(state => state.categories?.getAllData)

	return (
		<Accordion sx={{ border: 'none' }}>
			<AccordionSummary indicator={<Add />} onClick={e => e.preventDefault()}>
				<ContactEmergencyRounded />
				<Box sx={{ flex: 1, fontSize: '13px' }}>
					{t('add_advertiser_additional')}
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<FormItem
					text={'add_advertiser_additional_contact_name'}
					component={
						<Input
							style={{ width: '100%' }}
							onChange={e => {
								set({
									...arr,
									...{ contact_name: e.target.value },
								})
							}}
						/>
					}
				/>

				<FormItem
					text={'add_advertiser_additional_email'}
					component={
						<Input
							style={{ width: '100%' }}
							type='email'
							onChange={e => {
								set({
									...arr,
									...{ email: e.target.value },
								})
							}}
						/>
					}
				/>

				{categories.rows !== null && (
					<FormItem
						text={'add_advertiser_additional_category'}
						component={
							<Select
								style={{ width: '100%' }}
								value={arr.category}
								onChange={val => {
									set({
										...arr,
										...{ category: val === '' ? 0 : val },
									})
								}}
								fieldNames={{ label: 'name', value: 'category_id' }}
								options={[{ name: '---', category_id: 0 }, ...categories.rows]}
							/>
						}
					/>
				)}

				<FormItem
					text={'add_advertiser_additional_description'}
					component={
						<TextArea
							style={{ width: '100%' }}
							autoSize={{ minRows: 2, maxRows: 6 }}
							onChange={e => {
								set({
									...arr,
									...{ description: e.target.value },
								})
							}}
						/>
					}
				/>
			</AccordionDetails>
		</Accordion>
	)
}

export default Additional
