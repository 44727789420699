import React from 'react'

import { useReport } from '../../../../../../hooks/report'
import { useTranslation } from 'react-i18next'
import {
	VisibilityRounded,
	AdsClickRounded,
	PersonRounded,
	CurrencyExchangeRounded,
} from '@mui/icons-material'

import CustomCard from './Card'
import { Box } from '@mui/joy'

const TotalBar = ({ name }) => {
	const { t } = useTranslation()
	const { report } = useReport()
	const totals = report && report[name] && report[name].totals
	const totalBar = report && report[name] && report[name].totalBar
	const {
		options: { openOptions = false },
	} = report[name] || {}

	const numberToText = num => {
		return num.toLocaleString('ru-RU')
	}

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					mb: openOptions ? 0 : 1,
					[`& > div:last-child`]: {
						borderRight: '0',
					},
				}}
			>
				<CustomCard
					title={t('count')}
					value={numberToText(totals[0].count)}
					icon={<VisibilityRounded />}
				/>
				<CustomCard
					title={`${t('clicks')} / ${t('ctr')}`}
					value={`${numberToText(totals[0].clicks)} ${
						totals[0].ctr ? `/ ${totals[0].ctr.toFixed(2)}%` : ''
					}`}
					icon={<AdsClickRounded />}
				/>

				{totalBar && totalBar.unique > 0 && (
					<CustomCard
						title={`${t('unique')} / ${t('frequency')}`}
						value={`${numberToText(totalBar.unique)} ${
							totalBar.frequency ? ` / ${totalBar.frequency.toFixed(2)}` : ''
						}`}
						icon={<PersonRounded />}
					/>
				)}
				{!!totals && !!('spend' in totals[0]) && !!totals[0].spend && (
					<CustomCard
						title={t('spend')}
						value={`$ ${totals[0].spend ? totals[0].spend.toFixed(2) : ''} ${
							totals[0].spend2 > 0 ? `/ $ ${totals[0].spend2.toFixed(2)}` : ''
						}`}
						icon={<CurrencyExchangeRounded />}
					/>
				)}
			</Box>
		</>
	)
}

export default TotalBar
