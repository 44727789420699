import React, { createContext, useContext, useState } from 'react'
export const AdvertiserContext = createContext()

export function AdvertiserProvider({ children }) {
	const [adv, setAdv] = useState({})

	const updateAdvertiser = (key, newValue) => {
		setAdv(current => ({ ...current, [key]: newValue }))
	}

	const replaceAdvertiser = newValue => {
		setAdv(newValue)
	}

	return (
		<AdvertiserContext.Provider
			value={{ adv, updateAdvertiser, replaceAdvertiser }}
		>
			{children}
		</AdvertiserContext.Provider>
	)
}

export function useAdv() {
	const context = useContext(AdvertiserContext)
	if (!context) {
		throw new Error('useAdv must be used within a Advertiser')
	}
	return context
}
