import React, { useState } from 'react'

import { Box } from '@mui/joy'
import { Select, Tag, Space } from 'antd'

import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

import { useSelector } from 'react-redux'
import { useFilter } from '../../../../../hooks/filter'
import { useTranslation } from 'react-i18next'

const DeviceVendor = () => {
	const { t } = useTranslation()
	const { updateFilter } = useFilter()
	const [value, setValue] = useState([])
	const vendor = useSelector(state => state.rotations.target.rows?.vendor)

	const include = () => updateFilter('in_vendor', value)
	const exclude = () => updateFilter('ex_vendor', value)

	const items = vendor
		? Object.values(vendor).map(i => ({ label: i, value: i, key: i }))
		: []

	return (
		<>
			<Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						flex: 1,
						gridGap: 10,
						alignItems: 'center',
					}}
				>
					<Select
						placeholder={t('target_1_device_vendor')}
						maxTagCount={'responsive'}
						allowClear
						mode='multiple'
						style={{ width: '100%' }}
						options={items}
						defaultValue={value}
						tagRender={({ label, value, closable, onClose }) => {
							const onPreventMouseDown = event => {
								event.preventDefault()
								event.stopPropagation()
							}

							return (
								<Tag
									key={`${label}-${value}`}
									onMouseDown={onPreventMouseDown}
									closable={closable}
									onClose={onClose}
									style={{
										marginInlineEnd: 4,
										display: 'flex',
									}}
								>
									<Space style={{ gridGap: '4px', mr: '4px' }}>{label}</Space>
								</Tag>
							)
						}}
						onChange={(_, newValue) => {
							setValue(newValue)
						}}
					/>
				</Box>

				<IncludeExcludeBtn include={include} exclude={exclude} />
			</Box>
		</>
	)
}

export default DeviceVendor
