import React, { useEffect } from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Add, ManageAccountsRounded } from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useAppDispatch } from '../../../../../../store'
import { getAllPublisherSharings } from '../../../../../../store/publishers'

import Table from './Table'
import SearchBar from './SearchBar'

const SharingSetting = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [cookie] = useCookies(['reload'])
	const pageId = parseInt(useParams().pageId)

	useEffect(() => {
		dispatch(getAllPublisherSharings({ publisher_id: pageId }))
	}, [cookie.reload, dispatch, pageId])

	return (
		<Accordion sx={{ border: 'none' }}>
			<AccordionSummary indicator={<Add />} onClick={e => e.preventDefault()}>
				<ManageAccountsRounded />
				<Box sx={{ flex: 1, fontSize: '13px' }}>
					{t('edit_sharing_settings')}
				</Box>
			</AccordionSummary>
			<AccordionDetails sx={{ p: 0 }}>
				<SearchBar />
				<Table />
			</AccordionDetails>
		</Accordion>
	)
}

export default SharingSetting
