import React, { useState } from 'react'

import Container from './Container'
import Banners from './Banners'

import { Box, CircularProgress } from '@mui/joy'
import { useAppDispatch } from '../../../../../store'
import { campaignGetAll, setActive } from '../../../../../store/campaigns'
import { bannerGetAll } from '../../../../../store/banners'

const Campaigns = () => {
	const dispatch = useAppDispatch()
	const [banners, setBanners] = useState({})
	const [campaigns, setCampaigns] = useState({})

	const getBanners = ({ advertiser_id, campaignIdList }) => {
		dispatch(
			bannerGetAll({
				active: 1,
				advertiser_id,
				campaigns: campaignIdList,
			})
		).then(allBanners => {
			if (allBanners.data.rows) {
				let campaignBanners = banners

				campaignIdList.forEach(item => {
					campaignBanners[item] = []
				})

				allBanners.data.rows.forEach(item => {
					let bannerItem = []

					if (campaignBanners[item.campaign_id]) {
						bannerItem = [...campaignBanners[item.campaign_id], ...[item]]
					} else {
						bannerItem = [...[item]]
					}

					campaignBanners = {
						...campaignBanners,
						...{ [item.campaign_id]: bannerItem },
					}
				})

				setBanners(campaignBanners)
			}
		})
	}

	const getCampaigns = advertiser_id => {
		dispatch(campaignGetAll({ active: 1, advertiser_id })).then(result => {
			if (result.data.rows) {
				setCampaigns({
					...campaigns,
					...{ [advertiser_id]: result.data.rows },
				})

				const campaignIdList = result.data.rows.map(item => item.campaign_id)
				getBanners({ advertiser_id, campaignIdList })
			}
		})
	}

	const onChangeActiveCampaign = (campaign_id, active, advertiser_id) => {
		dispatch(setActive({ active, advertiser_id, campaign_id })).then(result => {
			if (result) getCampaigns(advertiser_id)
		})
	}

	const campaignGetter = (setter, advertiser_id) => {
		setter([{ advertiser_id }])
		getCampaigns(advertiser_id)
	}

	const banner = Banners({ banners, getCampaigns })

	const campaignTemplate = (adverts, id) => {
		if (!campaigns[id]) {
			return (
				<Box
					sx={{
						display: 'flex',
						height: '30px',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress size='sm' />
				</Box>
			)
		}

		if (
			!(
				adverts[0].advertiser_id === id &&
				campaigns[id] &&
				!!campaigns[id].length
			)
		)
			return <></>

		return campaigns[id].map(item => (
			<Container
				key={item.campaign_id}
				title={item.name}
				path={`/advertisers/campaigns/edit/${adverts[0].advertiser_id}/${item.campaign_id}`}
				addPath={`/advertisers/banner/add/${adverts[0].advertiser_id}/${item.campaign_id}`}
				content_id={item.campaign_id}
				active={item.active}
				activeBtnOnClick={(id, active) =>
					onChangeActiveCampaign(id, active, adverts[0].advertiser_id)
				}
				templateItem={(ad, id) =>
					banner.bannerTemplate(ad, id, adverts[0].advertiser_id)
				}
				getItems={(setter, campaign_id) => {
					banner.bannerGetter(setter, campaign_id, adverts[0].advertiser_id)
				}}
			/>
		))
	}

	return { campaignGetter, campaignTemplate }
}

export default Campaigns
