import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CategoriesState {
	addData: {
		category_id: number | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	editData: {
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	deleteData: {
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	getAllData: {
		rows: object | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	getData: {
		category_id: number | null
		name: string
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}
}

const initialState: CategoriesState = {
	addData: {
		category_id: null,
		isLoading: false,
		success: false,
		error: false,
	},

	editData: {
		success: false,
		isLoading: false,
		error: false,
	},

	deleteData: {
		success: false,
		isLoading: false,
		error: false,
	},

	getData: {
		category_id: null,
		name: '',
		isLoading: false,
		success: false,
		error: false,
	},

	getAllData: {
		rows: null,
		success: false,
		isLoading: false,
		error: false,
	},
}

export const reducer = createSlice({
	name: 'categories',
	initialState,
	reducers: {
		// Add advertiser
		categoryAddStart: (state): CategoriesState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		categoryAddSuccess: (
			state,
			action: PayloadAction<any>
		): CategoriesState => ({
			...state,
			addData: {
				...state.addData,
				category_id: action.payload.category_id,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		categoryAddFailure: (
			state,
			action: PayloadAction<string>
		): CategoriesState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Edit advertiser
		categoryEditStart: (state): CategoriesState => ({
			...state,
			editData: {
				...state.editData,
				success: false,
				error: false,
				isLoading: true,
			},
		}),
		categoryEditSuccess: (
			state,
			action: PayloadAction<void>
		): CategoriesState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		categoryEditFailure: (
			state,
			action: PayloadAction<string>
		): CategoriesState => ({
			...state,
			editData: {
				...state.editData,
				success: false,
				isLoading: false,
				error: action.payload,
			},
		}),

		// Delete advertiser
		categoryDeleteStart: (state): CategoriesState => ({
			...state,
			deleteData: {
				...state.deleteData,
				success: false,
				error: false,
				isLoading: true,
			},
		}),
		categoryDeleteSuccess: (
			state,
			action: PayloadAction<void>
		): CategoriesState => ({
			...state,
			deleteData: {
				...state.deleteData,
				success: true,
				error: false,
				isLoading: false,
			},
		}),
		categoryDeleteFailure: (
			state,
			action: PayloadAction<string>
		): CategoriesState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Get all advertisers
		categoryGetAllStart: (state): CategoriesState => ({
			...state,
			getAllData: {
				...state.getAllData,
				success: false,
				error: false,
				isLoading: true,
			},
		}),

		categoryGetAllSuccess: (
			state,
			action: PayloadAction<any>
		): CategoriesState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: true,
				error: false,
				rows: action.payload.result.rows,
			},
		}),

		categoryGetAllFailure: (
			state,
			action: PayloadAction<string>
		): CategoriesState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		categoryGetStart: (state): CategoriesState => ({
			...state,
			getData: {
				...state.getData,
				success: false,
				error: false,
				isLoading: true,
			},
		}),
		categoryGetSuccess: (
			state,
			action: PayloadAction<any>
		): CategoriesState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: true,
				error: false,
				...action.payload.result.rows,
			},
		}),
		categoryGetFailure: (
			state,
			action: PayloadAction<string>
		): CategoriesState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),
	},
})

export const {
	categoryAddStart,
	categoryAddSuccess,
	categoryAddFailure,
	categoryGetAllStart,
	categoryGetAllSuccess,
	categoryGetAllFailure,
	categoryEditStart,
	categoryEditSuccess,
	categoryEditFailure,
	categoryDeleteStart,
	categoryDeleteSuccess,
	categoryDeleteFailure,
	categoryGetStart,
	categoryGetSuccess,
	categoryGetFailure,
} = reducer.actions

export default reducer.reducer
