import React from 'react'
import { useTranslation } from 'react-i18next'

const Head = () => {
	const { t } = useTranslation()

	return (
		<thead>
			<tr>
				<th style={{ width: '40%' }}>{t('users_table_email')}</th>
				<th>{t('users_table_role')}</th>
				<th>{t('users_table_created')}</th>
				<th style={{ width: '100px', textAlign: 'center' }}>
					{t('users_table_status')}
				</th>
				<th style={{ width: '30px' }}></th>
				<th style={{ width: '30px' }}></th>
			</tr>
		</thead>
	)
}

export default Head
