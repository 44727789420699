import React from 'react'

import { Button } from '@mui/joy'
import { ToggleOnRounded, ToggleOffRounded } from '@mui/icons-material'

const AdvertiserActive = ({ activeBtnOnClick, content_id, active }) => {
	const isActive = [1, '1'].includes(active)
	const btnSx = {
		mx: '6px',
		fontSize: '15px',
	}

	const onClickActive = () => {
		!!activeBtnOnClick && activeBtnOnClick(content_id, isActive ? 0 : 1)
	}

	const activeBtnSx = theme => ({
		...btnSx,
		...{
			[theme.getColorSchemeSelector('light')]: {
				'--Icon-color':
					'var(--joy-palette-success-solidBg, var(--joy-palette-success-500, #1F7A1F))',
			},
			[theme.getColorSchemeSelector('dark')]: {
				'--Icon-color': '#0a6bcb',
			},
		},
	})

	return (
		['1', '0', 1, 0].includes(active) && (
			<Button
				variant='plain'
				color='neutral'
				size='sm'
				sx={{ mr: '-4px', p: '3px', borderRadius: 0 }}
				onClick={onClickActive}
			>
				{isActive ? (
					<ToggleOnRounded sx={activeBtnSx} />
				) : (
					<ToggleOffRounded
						color='neutral'
						sx={{ ...btnSx, ...{ opacity: '0.5' } }}
					/>
				)}
			</Button>
		)
	)
}

export default AdvertiserActive
