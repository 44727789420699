import React, { useEffect } from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Add, ManageAccountsRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useAppDispatch } from '../../../../../../store'
import { getAllAdvertiserSharings } from '../../../../../../store/advertisers'

import Table from './Table'
import SearchBar from './SearchBar'

const Sharing = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [cookie] = useCookies(['reload'])
	const { pageId } = useParams()
	const advertiser_id = parseInt(pageId)

	useEffect(() => {
		dispatch(getAllAdvertiserSharings({ advertiser_id }))
	}, [cookie.reload, dispatch, advertiser_id])

	return (
		<Accordion sx={{ border: 'none' }}>
			<AccordionSummary indicator={<Add />} onClick={e => e.preventDefault()}>
				<ManageAccountsRounded />
				<Box sx={{ flex: 1, fontSize: '13px' }}>
					{t('edit_sharing_settings')}
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<SearchBar />
				<Table />
			</AccordionDetails>
		</Accordion>
	)
}

export default Sharing
