import React, { useState, useMemo } from 'react'
import { Box, Sheet, Chip, ChipDelete, Typography } from '@mui/joy'
import { Select, Space, Tag } from 'antd'
import { random, updateObjItem } from '../utils'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../../../store'
import { rotationTarget } from '../../../../../../store/rotations'
import { FlagImg } from '../../../../../handlers/Chips'

import countryList from 'react-select-country-list'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const Locations = ({ rotation, current }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const countries = useMemo(() => countryList().getData(), [])
	const regions = useSelector(state => state.rotations.target.rows?.regions)

	const [selectedCountry, setSelectedCountry] = useState([])
	const [selectedRegion, setSelectedRegion] = useState([])
	const [selectedCity, setSelectedCity] = useState([])

	const getRows = val => {
		if (val) {
			const country = val.map(i => i.value)
			dispatch(rotationTarget({ country }))
		}
	}

	const targets = current.val.targets

	const arrayUniq = arr => [
		...new Map(arr.map(item => [item.value, item])).values(),
	]

	const updateLocations = type => {
		let value = {}
		const target_name = type === 'include' ? 'locations' : 'ex_locations'
		const regions = arrayUniq([
			...(targets[target_name]?.regions || []),
			...selectedRegion,
		])
		const cities = arrayUniq([
			...(targets[target_name]?.cities || []),
			...selectedCity,
		])

		if (selectedCity.length) value = { cities }
		else if (selectedRegion.length) value = { regions }

		updateObjItem({ rotation, current, value, target_name })
	}

	const regionsSelect = arr =>
		Object.entries(arr).flatMap(([country, regions]) =>
			Object.keys(regions).map(region => ({
				label: region,
				value: region,
				country,
			}))
		)

	const citiesSelect = (arr, selectedRegions) => {
		const selectedRegionValues = selectedRegions.map(i => i.value)
		return Object.entries(arr).flatMap(([country, regions]) =>
			Object.entries(regions).flatMap(([region, cities]) =>
				cities
					.filter(city => selectedRegionValues.includes(region) && city.value)
					.map(city => ({
						label: city.value,
						value: city.value,
						key: city.key,
						region,
						country,
					}))
			)
		)
	}

	const removeCountryItem = (rotation, item_id, val, type, type2) => {
		const removeItem = (targets, val, type, type2) => {
			if (!targets || !targets[type2] || !targets[type2][type]) return targets

			const updatedTypeArray = targets[type2][type].filter(x => x !== val)
			return {
				...targets,
				[type2]: {
					...targets[type2],
					[type]: updatedTypeArray,
				},
			}
		}

		current.val.targets = removeItem(current.val.targets, val, type, type2)
		current.set(current.val)

		return rotation.map(item => {
			if (item.item_id === item_id) {
				item.targets = removeItem(item.targets, val, type, type2)
			}
			if (item.items) {
				item.items = removeCountryItem(item.items, item_id, val, type, type2)
			}
			return item
		})
	}

	const removeElement = (item_id, val, type, type2) => {
		rotation.set(removeCountryItem(rotation.val, item_id, val, type, type2))
	}

	const CustomAutocomplete = ({ placeholderKey, options, value, setValue }) => (
		<Select
			placeholder={t(placeholderKey)}
			maxTagCount={'responsive'}
			allowClear
			mode='multiple'
			style={{ width: '100%' }}
			onChange={(_, newValue) => {
				if (newValue.length && placeholderKey === 'country') {
					getRows(newValue)
				}

				setValue(newValue)
			}}
			defaultValue={value}
			tagRender={({ label, value, closable, onClose }) => {
				const onPreventMouseDown = event => {
					event.preventDefault()
					event.stopPropagation()
				}

				return (
					<Tag
						key={`${label}-${value}`}
						onMouseDown={onPreventMouseDown}
						closable={closable}
						onClose={onClose}
						style={{
							marginInlineEnd: 4,
							display: 'flex',
						}}
					>
						<Space style={{ gridGap: '4px', mr: '4px' }}>
							{placeholderKey === 'country' && (
								<FlagImg sx={{ display: 'flex' }} countryCode={value} />
							)}
							{label}
						</Space>
					</Tag>
				)
			}}
			options={options}
			optionLabelProp='label'
			filterOption={(input, option) =>
				option.label.toLowerCase().includes(input.toLowerCase())
			}
			optionRender={option => (
				<Space>
					<FlagImg
						sx={{ display: 'flex' }}
						countryCode={option.data.country || option.data.value}
					/>
					{option.data.label}
				</Space>
			)}
		/>
	)

	const renderChips = (items, type, color, onDeleteType) => {
		return items.map(i => (
			<Chip
				key={random()}
				sx={{ mr: 1, mb: 1, px: 2 }}
				size='lg'
				variant='soft'
				color={color}
				endDecorator={
					<ChipDelete
						onDelete={() =>
							removeElement(current.val.item_id, i, type, onDeleteType)
						}
					/>
				}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						gridGap: '5px',
						fontSize: 14,
					}}
				>
					<FlagImg countryCode={i.country} />
					{`${countryList(i.country).getLabel(i.country)}, ${
						type === 'cities' ? `${i.region}, ` : ''
					}${i.label}`}
				</Box>
			</Chip>
		))
	}

	const RenderSections = ({ targets }) => {
		const hasRegions =
			targets.locations?.regions?.length > 0 ||
			targets.ex_locations?.regions?.length > 0
		const hasCities =
			targets.locations?.cities?.length > 0 ||
			targets.ex_locations?.cities?.length > 0

		return (
			<>
				{hasRegions && (
					<>
						<Box sx={{ mt: 2.5 }}>
							<Typography sx={{ fontSize: 15, mb: 1 }}>
								{t('regions')}
							</Typography>
							{renderChips(
								targets.locations?.regions || [],
								'regions',
								'primary',
								'locations'
							)}
							{renderChips(
								targets.ex_locations?.regions || [],
								'regions',
								'danger',
								'ex_locations'
							)}
						</Box>
					</>
				)}
				{hasCities && (
					<>
						<Box sx={{ mt: 2.5 }}>
							<Typography sx={{ fontSize: 15, mb: 1, mt: !hasRegions ? 2 : 0 }}>
								{t('cities')}
							</Typography>
							{renderChips(
								targets.locations?.cities || [],
								'cities',
								'primary',
								'locations'
							)}
							{renderChips(
								targets.ex_locations?.cities || [],
								'cities',
								'danger',
								'ex_locations'
							)}
						</Box>
					</>
				)}
			</>
		)
	}

	const select1 = regions ? regionsSelect(regions) : []
	const select2 = regions ? citiesSelect(regions, selectedRegion) : []

	return (
		<>
			<Sheet
				sx={theme => ({
					mt: '0',
					borderRadius: '8px',
					p: 2,
					display: 'flex',
					[theme.getColorSchemeSelector('light')]: {
						backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
					},
					[theme.getColorSchemeSelector('dark')]: {
						backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
					},
				})}
			>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						gridGap: '10px',
						flexDirection: 'column',
						flexWrap: 'wrap',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							width: '100%',
							gridGap: 10,
							flex: 1,
						}}
					>
						<Space.Compact block>
							<CustomAutocomplete
								placeholderKey='country'
								options={countries}
								value={selectedCountry}
								setValue={setSelectedCountry}
							/>

							<CustomAutocomplete
								placeholderKey='region'
								options={select1}
								value={selectedRegion}
								setValue={setSelectedRegion}
							/>

							<CustomAutocomplete
								placeholderKey='city'
								options={select2}
								value={selectedCity}
								setValue={setSelectedCity}
							/>
						</Space.Compact>
						<IncludeExcludeBtn
							include={() => updateLocations('include')}
							exclude={() => updateLocations('exclude')}
						/>
					</Box>
				</Box>
			</Sheet>

			{targets.locations && <RenderSections targets={targets} />}
		</>
	)
}

export default Locations
