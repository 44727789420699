import React from 'react'

import Advertisers from './Advertisers'
import { Box, CircularProgress } from '@mui/joy'
import { useSelector } from 'react-redux'

const List = ({searchCandidate}) => {
	const rows = useSelector(state => state.advertisers.getAllData?.rows)
	const isLoading = useSelector(
		state => state.advertisers.getAllData?.isLoading
	)

	return (
		<Box>
			{rows ? (
				<Advertisers searchCandidate={searchCandidate}  items={rows} />
			) : (
				isLoading && (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							minHeight: '20dvh',
						}}
					>
						<CircularProgress size='sm' />
					</Box>
				)
			)}
		</Box>
	)
}

export default List
