import React from 'react'

import Sidebar from './Sidebar'
import Routers from './Routers'
import Splitter from '../../layout/Splitter'
import { RotationProvider } from '../../hooks/rotations'

const Rotation = () => (
	<RotationProvider>
		<Splitter>
			<Sidebar />
			<Routers />
		</Splitter>
	</RotationProvider>
)
export default Rotation
