import React, { useState } from 'react'
import { Box, Sheet } from '@mui/joy'
import { InputNumber, Select, Space } from 'antd'

import { renderChip } from '../../../../../handlers/Chips'
import { useTranslation } from 'react-i18next'
import { updateObjToArrItem } from '../utils'
import { useSelector } from 'react-redux'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const OperatingSystem = ({ rotation, current }) => {
	const { t } = useTranslation()

	const [name, setName] = useState([])
	const [condition, setCondition] = useState('')
	const [version, setVersion] = useState('')
	const os = useSelector(state => state.rotations.target.rows?.os)
	const targets = current.val.targets

	const arrayUniq = (def, arr1) => {
		const defaultMap = new Map(
			def.map(item => [`${item.name}-${item.condition}-${item.version}`, item])
		)

		const uniqueMap = new Map(
			arr1
				.map(item => [`${item.name}-${item.condition}-${item.version}`, item])
				.filter(item => !defaultMap.has(item[0]))
		)

		return Array.from(uniqueMap.values())
	}

	const updateOSList = target_name => {
		const newEntry = { name, condition, version }
		const existingEntries =
			targets && target_name in targets ? targets[target_name] : []
		const value = arrayUniq(existingEntries, [newEntry])

		return updateObjToArrItem({
			rotation,
			current,
			value,
			target_name,
		})
	}

	const items = os
		? Object.keys(os).map(i => ({ label: i, value: i, key: i }))
		: []

	return (
		<>
			<Sheet
				sx={theme => ({
					mt: '0',
					borderRadius: '8px',
					p: 2,
					display: 'flex',
					[theme.getColorSchemeSelector('light')]: {
						backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
					},
					[theme.getColorSchemeSelector('dark')]: {
						backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
					},
				})}
			>
				<Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							flex: 1,
							gridGap: 10,
							alignItems: 'center',
						}}
					>
						<Space.Compact block>
							<Select
								placeholder={t('os')}
								maxTagCount={'responsive'}
								allowClear
								style={{ width: '100%' }}
								defaultValue={name}
								options={items}
								onChange={val => setName(val ? val : '')}
							/>

							{!!name.length && (
								<>
									<Select
										key={'conditions'}
										style={{ minWidth: '60px' }}
										defaultValue={condition}
										onChange={setCondition}
										options={[
											{ label: '', value: '---' },
											{ label: '=', value: '=' },
											{ label: '>', value: '>' },
											{ label: '<', value: '<' },
											{ label: '>=', value: '>=' },
											{ label: '<=', value: '<=' },
										]}
									/>
									<InputNumber
										style={{ minWidth: '100px' }}
										min={0}
										max={1000}
										step='0.01'
										onChange={setVersion}
										placeholder={t('version')}
									/>
								</>
							)}
						</Space.Compact>
					</Box>
					<IncludeExcludeBtn
						include={() => updateOSList('os')}
						exclude={() => updateOSList('ex_os')}
					/>
				</Box>
			</Sheet>
			{renderChip({
				current: current,
				rotation: rotation,
				type: 'os',
				label: i =>
					`${i.name} ${i.condition} ${i.version > 0 ? i.version : ''}`,
			})}
		</>
	)
}

export default OperatingSystem
