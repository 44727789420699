import React from 'react'
import { List } from '@mui/joy'

import { listItemButtonClasses } from '@mui/joy/ListItemButton'
import { useAppDispatch } from '../../../../../store'
import { setActive, rotationDup } from '../../../../../store/rotations'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import Container from './Container'

const Rotations = () => {
	const rows = useSelector(state => state.rotations.getAllData?.rows)
	const dispatch = useAppDispatch()
	const [, setCookie] = useCookies(['reload'])

	const onSetActive = (id, active) => {
		dispatch(setActive({ active, id })).then(result => {
			if (result) {
				setCookie('reload', new Date().getTime(), { path: '/' })
			}
		})
	}

	const duplicate = id => {
		dispatch(rotationDup({ id })).then(result => {
			if (result) {
				setCookie('reload', new Date().getTime(), { path: '/' })
			}
		})
	}

	const listSx = () => ({
		// height: '100%',
		'.advertiser_item_name': {
			'--ListItem-plainActiveBg': 'transparent',
			[`& .${listItemButtonClasses.root}`]: {
				borderLeftColor: 'transparent',
			},
		},
		[`& .${listItemButtonClasses.root}`]: {
			borderRadius: 'var(--Button-radius, var(--joy-radius-sm))',
		},
		[`& .${listItemButtonClasses.root}.active`]: {
			backgroundColor:
				'var(--variant-plainActiveBg, var(--joy-palette-neutral-plainActiveBg, var(--joy-palette-neutral-200, #DDE7EE)))',
		},
		'--ListItem-paddingLeft': '16px',
		'--ListItem-startActionWidth': '0px',
		'--ListItem-startActionTranslateX': '-50%',
	})
	return (
		<List size='sm' sx={listSx}>
			{rows &&
				rows.map(item => {
					return (
						<Container
							key={item.id}
							title={item.name}
							path={`/rotation/edit/${item.id}`}
							content_id={item.id}
							duplicate={duplicate}
							active={item.active}
							activeBtnOnClick={onSetActive}
						/>
					)
				})}
		</List>
	)
}

export default Rotations
