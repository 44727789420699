import React from 'react'
import Routers from './Routers'
import { ZonesProvider } from '../../../hooks/zones'

const Zones = () => (
	<ZonesProvider>
		<Routers />
	</ZonesProvider>
)

export default Zones
