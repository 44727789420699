import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	IPublishersRemoveRequest,
	IPublishersRemoveResponse,
	IPublishersRemoveSharingRequest,
} from './types'

export const remove = (
	params: IPublishersRemoveRequest
): AxiosPromise<IPublishersRemoveResponse> =>
	axiosInstance.delete(Endpoints.PUBLISHERS.DELETE, { data: { ...params } })

export const removeSharing = (
	params: IPublishersRemoveSharingRequest
): AxiosPromise<IPublishersRemoveResponse> =>
	axiosInstance.delete(Endpoints.PUBLISHERS.SHARING_DEL, {
		data: { ...params },
	})
