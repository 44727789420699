import React from 'react'
import { Button } from 'antd'
import { SettingsRounded } from '@mui/icons-material'
import { useReport } from '../../../../../../hooks/report'

const Options = ({ name }) => {
	const { report, replaceOption } = useReport()
	const { options: { openOptions = false } = {} } = report[name] || {}

	const onClick = () =>
		replaceOption({ name, key: 'openOptions', value: !openOptions })

	return (
		<Button
			onClick={onClick}
			size='small'
			ghost
			style={{
				paddingLeft: '4px',
				paddingRight: '4px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<SettingsRounded sx={{ fontSize: 15 }} />
		</Button>
	)
}

export default Options
