import React from 'react'
import GlobalStyles from '@mui/joy/GlobalStyles'
import Background from './Background'
import Middle from './Middle'

const Css = () => {
	return (
		<GlobalStyles
			styles={{
				':root': {
					'--Collapsed-breakpoint': '769px',
					'--Form-maxWidth': '700px',
					'--Transition-duration': '0.4s',
				},
			}}
		/>
	)
}

const Login = () => {
	return (
		<>
			<Css />
			<Background />
			<Middle />
		</>
	)
}

export default Login
