import React, { useState } from 'react'

import { Button, List, ListItem, ListItemButton } from '@mui/joy'
import { KeyboardArrowDown } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'

import Duplicate from './Duplicate'
import Active from './Active'

const Container = ({
	getItems,
	templateItem,
	content_id,
	path,
	title,
	duplicate,
	activeBtnOnClick,
	active,
}) => {
	const [open, setOpen] = useState(false)
	const [template, setTemplate] = useState([])

	const handlerOnClick = () => {
		setOpen(bool => !bool)
		!open && !template.length && getItems && getItems(setTemplate, content_id)
	}

	const Content = () => {
		return (
			open &&
			(template.length || !Object.keys(template).length) && (
				<List
					sx={{
						borderLeft: '1px dashed',
						borderLeftColor: 'divider',
					}}
				>
					{template &&
						!!template.length &&
						!!templateItem &&
						templateItem(template, content_id)}
				</List>
			)
		)
	}

	return (
		<ListItem
			sx={{
				ml: '20px',
				'&:hover > div > button  svg': {
					opacity: 1,
				},
			}}
			nested
			endAction={
				<>
					<Duplicate
						opacity={0}
						duplicate={duplicate}
						content_id={content_id}
					/>
					<Active
						activeBtnOnClick={activeBtnOnClick}
						content_id={content_id}
						active={active}
					/>
				</>
			}
			startAction={
				<>
					{getItems ? (
						<Button
							variant='plain'
							color='neutral'
							size='sm'
							sx={{ p: '3px', px: '5px' }}
							onClick={handlerOnClick}
						>
							<KeyboardArrowDown
								sx={{
									transform: open ? 'initial' : 'rotate(-90deg)',
									transition: '.2s ease-in-out',
								}}
							/>
						</Button>
					) : (
						''
					)}
				</>
			}
		>
			<ListItem className={'advertiser_item_name'}>
				<ListItemButton
					sx={{
						ml: '-36px',
						borderRadius: '0!important',
						pl: getItems ? '30px' : '15px',
						fontSize: 12,
						[`&.active`]: {
							fontWeight: 'bold',
						},
					}}
					component={NavLink}
					to={path}
				>
					{title}
				</ListItemButton>
			</ListItem>
			<Content />
		</ListItem>
	)
}

export default Container
