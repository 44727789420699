import * as React from 'react'

import { Box } from '@mui/joy'
import VpnTorBtn from './Vpn'
import DateInputs from './DateInputs'
import Chips from './Chips'
import GroupBy from './GroupBy'
import ReportType from './ReportType'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'antd'

import { random } from '../../../../Rotation/Edit/Modal/utils'
import { useTab } from '../../../../../hooks/tab'
import { useFilter } from '../../../../../hooks/filter'
import { useReport } from '../../../../../hooks/report'
import { useAppDispatch } from '../../../../../../store'
import { filterRequest } from '../../../../../../store/statistic'

const Utility = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const { filter } = useFilter()
	const { updateReport } = useReport()
	const { updateTab, setCurrentTab } = useTab()
	const user_id = useSelector(state => state.auth.profileData.profile?.user_id)
	const role_id = useSelector(state => state.auth.profileData.profile?.role_id)

	const runReport = () => {
		const reportKey = random()
		const reportName = random()

		setCurrentTab(reportKey)

		dispatch(
			filterRequest({
				user_id,
				report_id: reportName,
				options: filter,
			})
		).then(item => {
			updateReport(reportName, item[reportName])
		})

		updateTab(reportKey, {
			key: reportKey,
			name: reportName,
			// vertical: true,
		})
	}

	return (
		<>
			<Chips />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					flex: 1,
					px: 3,
					alignItems: 'center',
				}}
			>
				<DateInputs />

				<Button
					onClick={runReport}
					type={'primary'}
					style={{ marginLeft: '8px' }}
				>
					{t('run_report')}
				</Button>
			</Box>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					flex: 1,
					alignItems: 'center',
					py: 2,
					px: 3,
				}}
			>
				<ReportType />
				<GroupBy />
			</Box>

			{role_id === 1 && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gridGap: 16,
						mb: 2,
						overflowX: 'auto',
					}}
				>
					<VpnTorBtn id='vpn' />
					<VpnTorBtn id='tor' />
					<VpnTorBtn id='unique' />
					{/* <VpnTorBtn id='new' /> */}
				</Box>
			)}
		</>
	)
}

export default Utility
