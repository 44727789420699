import React, { useState } from 'react'

import { DeleteRounded, DataObjectRounded } from '@mui/icons-material'
import { Button, Popconfirm, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import { toast } from '../../../../layout/JoyToaster'
import { useAppDispatch } from '../../../../../store'
import { reTargetDel } from '../../../../../store/retarget'

import Codes from './Codes'

const Body = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const [, setCookie] = useCookies(['reload'])
	const [targetId, setTargetId] = useState(false)
	const rows = useSelector(state => state.retarget.getAllData?.rows)

	const deleteRetarget = deleteCategoryId => {
		if (deleteCategoryId) {
			dispatch(reTargetDel({ id: deleteCategoryId })).then(result => {
				result
					? toast.success(t('delete_retarget_successfully'))
					: toast.error(t('error'))

				setCookie('reload', new Date().getTime(), { path: '/' })
			})
		}
	}

	const reTargetSetId = target_id => {
		setTargetId(target_id)
	}

	return (
		<>
			<Codes open={targetId} />

			<tbody>
				{rows &&
					rows.map(row => (
						<tr key={row.id} className='delete-container-retarget'>
							<td>{row.advertiser}</td>
							<td>{row.name}</td>
							<td>
								{parseInt(row.rcount) > 0 ? (
									<Tag color='#2db7f5'>
										{parseInt(row.rcount).toLocaleString('ru-RU')}
									</Tag>
								) : (
									''
								)}
							</td>
							<td>{row.target_id}</td>
							<td style={{ paddingLeft: 0, paddingRight: 0 }}>
								<Popconfirm
									placement='topRight'
									style={{ maxWidth: '300px' }}
									title={t('delete_dialog_title')}
									description={t('delete_dialog_remove_retarget')}
									okText={t('delete_dialog_confirm')}
									cancelText={t('delete_dialog_cancel')}
									onConfirm={() => {
										deleteRetarget(row.id)
									}}
								>
									<Button
										size='small'
										className='delete-btn-retarget'
										danger
										style={{ padding: 3 }}
									>
										<DeleteRounded style={{ fontSize: 16, color: '#dc4446' }} />
									</Button>
								</Popconfirm>
							</td>

							<td style={{ paddingLeft: 0, paddingRight: 0 }}>
								<Button
									size='small'
									style={{ padding: 3 }}
									onClick={e => reTargetSetId(row.target_id)}
								>
									<DataObjectRounded style={{ fontSize: 16 }} />
								</Button>
							</td>
						</tr>
					))}
			</tbody>
		</>
	)
}

export default Body
