import React, { useRef, useEffect } from 'react'
import { Sheet, Divider } from '@mui/joy'

import List from './List'
import Header from './Header'

import { useAppDispatch } from '../../../../store'
import { rotationGetAll } from '../../../../store/rotations'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'

const Sidebar = () => {
	const dispatch = useAppDispatch()
	const [cookie, setCookie] = useCookies(['rotationListActive', 'reload'])

	const onChangeActive = value => {
		setCookie('rotationListActive', value ? value : 'all', { path: '/' })
	}

	const isLoading = useSelector(state => state.rotations.getAllData?.isLoading)
	const isLoadingRef = useRef(isLoading)

	useEffect(() => {
		if (!isLoadingRef.current) {
			dispatch(rotationGetAll({ active: cookie.rotationListActive }))
		}
	}, [cookie.reload, isLoadingRef, cookie.rotationListActive, dispatch])

	return (
		<Sheet
			sx={{
				borderRight: '1px solid',
				borderColor: 'divider',
				transition: 'transform 0.4s',
				height: 'calc(100dvh - var(--Topbar-height))',
				top: 0,
				// p: 2,
				pt: 1,
				overflowY: 'auto',
				flexShrink: 0,
				display: 'flex',
				flexDirection: 'column',
				// gap: 2,
				zIndex: 999,
			}}
		>
			<Header
				active={{
					value: cookie.rotationListActive,
					set: onChangeActive,
				}}
			/>
			<Divider />
			<List />
		</Sheet>
	)
}

export default Sidebar
