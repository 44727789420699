import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CampaignsState {
	addData: {
		banner_id: number | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	editData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	deleteData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	getData: {
		advertiser_id: number | null
		banner_id: number | null
		name: string
		active: number | null
		url: string
		metadata: string
		creative: string

		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	getAllData: {
		rows: object | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	activeData: {
		isLoading: boolean
		banner_id: number | null
		active: number | null
		success: boolean | null
		error: string | boolean | null
	}

	trackerAddData: {
		tracking_id: number | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	trackerDeleteData: {
		success: boolean | null
		isLoading: boolean
		error: string | boolean | null
	}

	trackerGetAllData: {
		rows: object | null
		delete: boolean
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	cloud: {
		tree: object | null
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}

	upload: {
		url: string
		isLoading: boolean
		success: boolean | null
		error: string | boolean | null
	}
}

const initialState: CampaignsState = {
	addData: {
		banner_id: null,
		isLoading: false,
		success: false,
		error: null,
	},

	editData: {
		success: false,
		isLoading: false,
		error: null,
	},

	deleteData: {
		success: false,
		isLoading: false,
		error: null,
	},

	getData: {
		advertiser_id: null,
		banner_id: null,
		name: '',
		active: null,
		url: '',
		metadata: '',
		creative: '',

		isLoading: false,
		success: false,
		error: null,
	},

	getAllData: {
		rows: [],
		isLoading: false,
		success: false,
		error: null,
	},

	activeData: {
		isLoading: false,
		banner_id: null,
		active: null,
		success: false,
		error: null,
	},

	trackerAddData: {
		tracking_id: null,
		isLoading: false,
		success: false,
		error: null,
	},

	trackerDeleteData: {
		success: false,
		isLoading: false,
		error: null,
	},

	trackerGetAllData: {
		rows: [],
		delete: false,
		isLoading: false,
		success: false,
		error: null,
	},

	cloud: {
		tree: [],
		isLoading: false,
		success: false,
		error: null,
	},

	upload: {
		url: '',
		isLoading: false,
		success: false,
		error: null,
	},
}

export const reducer = createSlice({
	name: 'banners',
	initialState,
	reducers: {
		// Add advertiser
		bannerAddStart: (state): CampaignsState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		bannerAddSuccess: (state, action: PayloadAction<any>): CampaignsState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: false,
				success: true,
				error: false,
				banner_id: action.payload.banner_id,
			},
		}),
		bannerAddFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			addData: {
				...state.addData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Edit advertiser
		bannerEditStart: (state): CampaignsState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: true,
				success: false,
				error: null,
			},
		}),
		bannerEditSuccess: (
			state,
			action: PayloadAction<void>
		): CampaignsState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		bannerEditFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			editData: {
				...state.editData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Delete advertiser
		bannerDeleteStart: (state): CampaignsState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		bannerDeleteSuccess: (
			state,
			action: PayloadAction<void>
		): CampaignsState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		bannerDeleteFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			deleteData: {
				...state.deleteData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Get All advertiser
		bannerGetAllStart: (state): CampaignsState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		bannerGetAllSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: true,
				error: false,
				rows: action.payload.result.rows,
			},
		}),
		bannerGetAllFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			getAllData: {
				...state.getAllData,
				isLoading: false,
				success: false,
				error: action.payload,
				rows: [],
			},
		}),

		// Get advertiser by id
		bannerGetStart: (state): CampaignsState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		bannerGetSuccess: (state, action: PayloadAction<any>): CampaignsState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: true,
				error: false,
				...action.payload.result.rows,
			},
		}),
		bannerGetFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			getData: {
				...state.getData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Set active
		bannerSetActiveStart: (state): CampaignsState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		bannerSetActiveSuccess: (
			state,
			action: PayloadAction<void>
		): CampaignsState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		bannerSetActiveFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			activeData: {
				...state.activeData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Add campaign prices
		bannerTrackerAddStart: (state): CampaignsState => ({
			...state,
			trackerAddData: {
				...state.trackerAddData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		bannerTrackerAddSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			trackerAddData: {
				...state.trackerAddData,
				isLoading: false,
				success: true,
				error: false,
				tracking_id: action.payload.tracking_id,
			},
		}),
		bannerTrackerAddFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			trackerAddData: {
				...state.trackerAddData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Delete campaign prices
		bannerTrackerDeleteStart: (state): CampaignsState => ({
			...state,
			trackerDeleteData: {
				...state.trackerDeleteData,
				isLoading: true,
				success: false,
				error: false,
			},
		}),
		bannerTrackerDeleteSuccess: (
			state,
			action: PayloadAction<void>
		): CampaignsState => ({
			...state,
			trackerDeleteData: {
				...state.trackerDeleteData,
				isLoading: false,
				success: true,
				error: false,
			},
		}),
		bannerTrackerDeleteFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			trackerDeleteData: {
				...state.trackerDeleteData,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),

		// Get All advertiser
		bannerTrackerGetAllStart: (state): CampaignsState => ({
			...state,
			trackerGetAllData: {
				...state.trackerGetAllData,
				isLoading: true,
				success: false,
				delete: false,
				error: false,
			},
		}),

		bannerTrackerGetAllSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			trackerGetAllData: {
				...state.trackerGetAllData,
				isLoading: false,
				success: true,
				error: false,
				delete: action.payload.result.delete,
				rows: action.payload.result.rows,
			},
		}),

		bannerTrackerGetAllFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			trackerGetAllData: {
				...state.trackerGetAllData,
				isLoading: false,
				success: false,
				delete: false,
				error: action.payload,
				rows: [],
			},
		}),

		bannerCloudStart: (state): CampaignsState => ({
			...state,
			cloud: {
				...state.cloud,
				isLoading: true,
				success: false,
			},
		}),

		bannerCloudSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			cloud: {
				...state.cloud,
				tree: action.payload.result.tree,
				isLoading: false,
				success: true,
				error: false,
			},
		}),

		bannerCloudFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			cloud: {
				...state.cloud,
				isLoading: false,
				success: false,
				error: action.payload,
				tree: [],
			},
		}),

		bannerUploadStart: (state): CampaignsState => ({
			...state,
			upload: {
				...state.upload,
				isLoading: true,
				success: false,
			},
		}),

		bannerUploadSuccess: (
			state,
			action: PayloadAction<any>
		): CampaignsState => ({
			...state,
			upload: {
				...state.upload,
				url: action.payload.result.url,
				isLoading: false,
				success: true,
				error: false,
			},
		}),

		bannerUploadFailure: (
			state,
			action: PayloadAction<string>
		): CampaignsState => ({
			...state,
			upload: {
				...state.upload,
				isLoading: false,
				success: false,
				error: action.payload,
			},
		}),
	},
})

export const {
	bannerAddStart,
	bannerAddSuccess,
	bannerAddFailure,
	bannerEditStart,
	bannerEditSuccess,
	bannerEditFailure,
	bannerDeleteStart,
	bannerDeleteSuccess,
	bannerDeleteFailure,
	bannerGetAllSuccess,
	bannerGetAllFailure,
	bannerGetAllStart,
	bannerGetStart,
	bannerGetSuccess,
	bannerGetFailure,
	bannerSetActiveStart,
	bannerSetActiveSuccess,
	bannerSetActiveFailure,
	bannerTrackerAddStart,
	bannerTrackerAddSuccess,
	bannerTrackerAddFailure,
	bannerTrackerDeleteStart,
	bannerTrackerDeleteSuccess,
	bannerTrackerDeleteFailure,
	bannerTrackerGetAllStart,
	bannerTrackerGetAllSuccess,
	bannerTrackerGetAllFailure,

	bannerCloudStart,
	bannerCloudSuccess,
	bannerCloudFailure,

	bannerUploadStart,
	bannerUploadSuccess,
	bannerUploadFailure,
} = reducer.actions

export default reducer.reducer
