import React, { useState } from 'react'
import { Box, Sheet } from '@mui/joy'
import { Select, Tag, Space } from 'antd'

import { renderChip } from '../../../../../handlers/Chips'
import { useTranslation } from 'react-i18next'
import { updateItem } from '../utils'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const UserType = ({ rotation, current }) => {
	const { t } = useTranslation()
	const userTypesText = t('user_types', { returnObjects: true })
	const [value, setValue] = useState({})

	const handleIncludeExclude = target_name => {
		updateItem({ rotation, current, value: value, target_name })
	}

	const userTypes = [
		{ value: userTypesText['_business'], key: 'business' },
		{ value: userTypesText['_cafe'], key: 'cafe' },
		{ value: userTypesText['_cellular'], key: 'cellular' },
		{ value: userTypesText['_college'], key: 'college' },
		{
			value: userTypesText['_consumer_privacy_network'],
			key: 'consumer_privacy_network',
		},
		{
			value: userTypesText['_content_delivery_network'],
			key: 'content_delivery_network',
		},
		{ value: userTypesText['_dialup'], key: 'dialup' },
		{ value: userTypesText['_government'], key: 'government' },
		{ value: userTypesText['_hosting'], key: 'hosting' },
		{ value: userTypesText['_library'], key: 'library' },
		{ value: userTypesText['_military'], key: 'military' },
		{ value: userTypesText['_residential'], key: 'residential' },
		{ value: userTypesText['_router'], key: 'router' },
		{ value: userTypesText['_school'], key: 'school' },
		{
			value: userTypesText['_search_engine_spider'],
			key: 'search_engine_spider',
		},
		{ value: userTypesText['_traveler'], key: 'traveler' },
	]

	return (
		<>
			<Sheet
				sx={theme => ({
					mt: '0',
					borderRadius: '8px',
					p: 2,
					display: 'flex',
					[theme.getColorSchemeSelector('light')]: {
						backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
					},
					[theme.getColorSchemeSelector('dark')]: {
						backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
					},
				})}
			>
				<Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							flex: 1,
							gridGap: 10,
							alignItems: 'center',
						}}
					>
						<Select
							placeholder={t('target_1_user_type')}
							maxTagCount={'responsive'}
							allowClear
							mode='multiple'
							style={{ width: '100%' }}
							options={userTypes}
							fieldNames={{ label: 'value', value: 'key' }}
							tagRender={({ label, value, closable, onClose }) => {
								const onPreventMouseDown = event => {
									event.preventDefault()
									event.stopPropagation()
								}

								return (
									<Tag
										key={`${label}-${value}`}
										onMouseDown={onPreventMouseDown}
										closable={closable}
										onClose={onClose}
										style={{
											marginInlineEnd: 4,
											display: 'flex',
										}}
									>
										<Space style={{ gridGap: '4px', mr: '4px' }}>{label}</Space>
									</Tag>
								)
							}}
							onChange={newValue => {
								setValue(
									newValue.map(val => ({
										value: val,
									}))
								)
							}}
						/>
					</Box>

					<IncludeExcludeBtn
						include={() => {
							handleIncludeExclude('user_type')
						}}
						exclude={() => {
							handleIncludeExclude('ex_user_type')
						}}
					/>
				</Box>
			</Sheet>
			{renderChip({
				current: current,
				rotation: rotation,
				type: 'user_type',
				label: i => userTypesText[`_${i}`],
			})}
		</>
	)
}

export default UserType
