import React, { useState, useEffect, useRef } from 'react'
import {
	Tab,
	IconButton,
	Dropdown,
	Menu,
	MenuButton,
	MenuItem,
	Divider,
	Tooltip,
} from '@mui/joy'
import {
	CloseRounded,
	DragIndicatorRounded,
	VerticalSplitRounded,
	MoreVert,
	SummarizeRounded,
	ShareRounded,
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { useTab } from '../../../../hooks/tab'
import { toast } from '../../../../layout/JoyToaster'

const CustomTab = ({ setCurrentTabValue }) => {
	const { t } = useTranslation()
	const { tabs, changeTab, currentTab, setCurrentTab, deleteTab, replaceTabs } =
		useTab()

	const [modifierKeys, setModifierKeys] = useState({ shift: false, alt: false })

	useEffect(() => {
		const handleKeyDown = event => {
			if (event.key === 'Shift') {
				setModifierKeys(keys => ({ ...keys, shift: true }))
			}
			if (event.key === 'Alt') {
				setModifierKeys(keys => ({ ...keys, alt: true }))
			}
		}

		const handleKeyUp = event => {
			if (event.key === 'Shift') {
				setModifierKeys(keys => ({ ...keys, shift: false }))
			}
			if (event.key === 'Alt') {
				setModifierKeys(keys => ({ ...keys, alt: false }))
			}
		}

		window.addEventListener('keydown', handleKeyDown)
		window.addEventListener('keyup', handleKeyUp)

		return () => {
			window.removeEventListener('keydown', handleKeyDown)
			window.removeEventListener('keyup', handleKeyUp)
		}
	}, [])

	const previousChangeTab = useRef(changeTab)

	useEffect(() => {
		if (previousChangeTab.current !== changeTab) {
			if (tabs[changeTab]) {
				setCurrentTabValue(changeTab)
			} else if (currentTab === 'options') {
				setCurrentTabValue('options')
			}
			previousChangeTab.current = changeTab
		}
	}, [tabs, changeTab, currentTab, setCurrentTabValue])

	const handleCloseTab = number => {
		deleteTab(number)
	}

	const handleChangeTab = number => {
		if (tabs[number] || number === 'options') {
			setCurrentTab(number)
			setCurrentTabValue(number)
		}
	}

	const mergeTabs = ({ targetKey, sourceKey, isSourceLeft }) => {
		if (!sourceKey || !tabs[sourceKey] || !tabs[targetKey]) return

		const targetTab = tabs[targetKey]
		const sourceTab = tabs[sourceKey]

		if (targetTab.length > 2) return

		const newTab = isSourceLeft
			? [...sourceTab, ...targetTab]
			: [...targetTab, ...sourceTab]

		replaceTabs(targetKey, newTab)
		deleteTab(sourceKey)
		setCurrentTab(targetKey)
		setCurrentTabValue(targetKey)
	}

	const splitLeft = ({ prevKey, value }) => {
		mergeTabs({ targetKey: value, sourceKey: prevKey, isSourceLeft: true })
	}

	const splitRight = ({ nextKey, value }) => {
		mergeTabs({ targetKey: value, sourceKey: nextKey, isSourceLeft: false })
	}

	const shareReport = ({ name }) => {
		const uri = `http${
			window.location.host.indexOf('localhost') > -1 ? '' : 's'
		}://${window.location.host}/share/statistic/${name}`

		try {
			navigator.clipboard.writeText(uri)
			toast.success(t('copied'))
		} catch (err) {
			toast.error(t('error'))
		}
	}

	const CustomMenu = ({ prevKey, nextKey, value, name }) => (
		<Dropdown>
			<MenuButton
				size='sm'
				variant='plain'
				sx={{ minWidth: '12px', minHeight: '16px', px: '2px', py: 0 }}
				component='a'
				slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
			>
				<MoreVert sx={{ fontSize: 12 }} />
			</MenuButton>

			<Menu size='sm'>
				{tabs && tabs[value] && tabs[value].length < 3 && (
					<>
						<MenuItem
							sx={{ fontSize: 12 }}
							disabled={!prevKey}
							onClick={() => {
								splitLeft({ prevKey, value })
							}}
						>
							<VerticalSplitRounded sx={{ fontSize: 12 }} />
							{t('split_left')}
						</MenuItem>
						<MenuItem
							disabled={!nextKey}
							sx={{ fontSize: 12 }}
							onClick={() => {
								splitRight({ nextKey, value })
							}}
						>
							<VerticalSplitRounded
								sx={{ fontSize: 12, transform: 'rotate(180deg)' }}
							/>
							{t('split_right')}
						</MenuItem>
					</>
				)}
				{tabs && tabs[value] && tabs[value].length < 2 && (
					<MenuItem
						sx={{ fontSize: 12 }}
						onClick={() => {
							shareReport({ name })
						}}
					>
						<ShareRounded sx={{ fontSize: 12 }} />
						{t('share')}
					</MenuItem>
				)}

				{tabs && tabs[value] && tabs[value].length < 3 ? <Divider /> : ''}
				<MenuItem
					sx={{ fontSize: 12 }}
					onClick={() => {
						handleCloseTab(value)
					}}
				>
					<CloseRounded sx={{ fontSize: 12 }} />
					{t('close')}
				</MenuItem>
			</Menu>
		</Dropdown>
	)

	const CustomClose = ({ value }) => (
		<Tooltip
			arrow
			placement='right'
			size='sm'
			title={t('option_btn')}
			color='primary'
		>
			<IconButton
				onClick={() => {
					handleCloseTab(value)
				}}
				size='sm'
				variant='plain'
				component='a'
				sx={{ minWidth: '12px', minHeight: '16px' }}
			>
				<CloseRounded sx={{ fontSize: 12 }} />
			</IconButton>
		</Tooltip>
	)

	const CustomDrag = ({ value }) => (
		<IconButton
			size='sm'
			variant='plain'
			component='a'
			sx={{ minWidth: '12px', minHeight: '16px' }}
		>
			<DragIndicatorRounded sx={{ fontSize: 12 }} />
		</IconButton>
	)

	const MiniBtn = ({ prevKey, nextKey, value, name }) =>
		modifierKeys.alt ? (
			<CustomMenu
				prevKey={prevKey}
				nextKey={nextKey}
				value={value}
				name={name}
			/>
		) : modifierKeys.shift ? (
			<CustomDrag value={value} />
		) : (
			<CustomClose value={value} />
		)
	const Label = ({ name, value, prevKey, nextKey }) => (
		<Tab
			value={value}
			sx={{
				mt: '1px',
				...(value !== 'options' ? { pr: 0 } : {}),
				textTransform: 'uppercase',
				flex: 'none',
				scrollSnapAlign: 'start',
			}}
			indicatorInset
			indicatorPlacement='bottom'
		>
			{value !== 'options' &&
				tabs &&
				(tabs[value] && tabs[value].length > 1 ? (
					<VerticalSplitRounded sx={{ fontSize: 12 }} />
				) : (
					<SummarizeRounded sx={{ fontSize: 12 }} />
				))}
			{name}
			{value !== 'options' && (
				<MiniBtn
					prevKey={prevKey}
					nextKey={nextKey}
					value={value}
					name={name}
				/>
			)}
		</Tab>
	)

	return { Label, handleChangeTab, tabs, replaceTabs }
}

export default CustomTab
