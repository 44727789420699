import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
	IPublishersGetAllRequest,
	IPublishersGetAllResponse,
	IPublishersGetAllSharingRequest,
	IPublishersGetRequest,
	IPublishersGetResponse,
} from './types'

export const getAllSharings = (
	params: IPublishersGetAllSharingRequest
): AxiosPromise<IPublishersGetAllResponse> =>
	axiosInstance.get(
		`${Endpoints.PUBLISHERS.SHARING_GET}/${params.publisher_id}`
	)

export const getAll = (
	params: IPublishersGetAllRequest
): AxiosPromise<IPublishersGetAllResponse> =>
	axiosInstance.post(Endpoints.PUBLISHERS.GET_ALL, params)

export const get = (
	params: IPublishersGetRequest
): AxiosPromise<IPublishersGetResponse> =>
	axiosInstance.get(`${Endpoints.PUBLISHERS.GET}/${params.publisher_id}`)
