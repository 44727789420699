import React, { useState } from 'react'

import {
	Box,
	Sheet,
	Tooltip,
	Typography,
	IconButton,
	CircularProgress,
} from '@mui/joy'

import { SourceRounded, DeleteForeverRounded } from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../store'
import { useCookies } from 'react-cookie'
import { rotationDel } from '../../../../store/rotations'
import { useRtt } from '../../../hooks/rotations'
import DeleteDialog from '../../../layout/DeleteDialog'

const ControlBar = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const { rtt, replaceRotation } = useRtt()
	const [, setCookie] = useCookies(['reload'])
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

	const deleteRotation = () => {
		dispatch(rotationDel({ id: rtt.id })).then(result => {
			if (result) {
				replaceRotation({})
				setCookie('reload', new Date().getTime(), { path: '/' })
			}
		})

		setOpenDeleteDialog(false)
	}

	const Remove = () => (
		<Tooltip
			arrow
			color='primary'
			placement='top'
			title={t('remove_rotation')}
			variant='solid'
		>
			<IconButton size='sm' onClick={setOpenDeleteDialog}>
				<DeleteForeverRounded sx={{ fontSize: '20px' }} color='danger' />
			</IconButton>
		</Tooltip>
	)
	return (
		<>
			<DeleteDialog
				open={{ set: setOpenDeleteDialog, value: openDeleteDialog }}
				description={t('delete_dialog_remove_rotation')}
				onClick={() => deleteRotation()}
			/>
			<Sheet
				variant='outlined'
				sx={{
					border: 'none',

					transition: 'transform 0.4s',
					overflow: 'hidden',
					px: 2,
					py: 1.5,
					minHeight: '58px',
					display: 'flex',
					alignItems: 'center',
					backgroundColor:
						'var(--variant-softBg, var(--joy-palette-primary-softBg, var(--joy-palette-primary-100, #E3EFFB)))',
				}}
			>
				<Typography
					level='title-md'
					sx={{ flex: 1, display: 'flex', alignItems: 'center' }}
				>
					<SourceRounded sx={{ mr: '10px' }} />
					{!!rtt && !!rtt.name ? rtt.name : <CircularProgress size='sm' />}
				</Typography>

				<Box sx={{ gridGap: '10px', display: 'flex' }}>
					{!!rtt && !!rtt.name && <Remove />}
				</Box>
			</Sheet>
		</>
	)
}

export default ControlBar
