import React, { useEffect, useState } from 'react'
import { Box, Radio, RadioGroup, Sheet, Divider, Typography } from '@mui/joy'

import {
	SourceRounded,
	PermMediaRounded,
	TrackChangesRounded,
	InterestsRounded,
	LandscapeRounded,
	MediationRounded,
} from '@mui/icons-material/'

import { Input, InputNumber, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../../store'
import { rotationTarget } from '../../../../../store/rotations'
import { useRtt } from '../../../../hooks/rotations'

import TargetsTabs from './TargetsTabs'
import TreeView from '../../../../layout/TreeView'

const CaseTypes = ({ rotation, current }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const { rtt } = useRtt()
	const [index, setIndex] = useState(current.val.type || 'case')
	const [contentId, setContentId] = useState(current.val.content_id || '')
	const [name, setName] = useState(current.val.name || '')
	const [skip, setSkip] = useState(current.val.skip || 0)
	const [percent, setPercent] = useState(current.val.percent || 0)

	useEffect(() => {
		dispatch(rotationTarget({ advertiser: 1, template_id: rtt.template }))
	}, [dispatch, rtt.template])

	const campaignsDb = useSelector(
		state => state.rotations.target.rows?.campaigns
	)

	const bannersDb = useSelector(state => state.rotations.target.rows?.banners)
	const retargetDb = useSelector(
		state => state.rotations.target.rows?.retargets
	)

	const types = [
		{
			icon: <SourceRounded sx={{ fontSize: 20 }} />,
			name: 'case',
			text: t('case'),
		},
		{
			icon: <LandscapeRounded sx={{ fontSize: 20 }} />,
			name: 'banner',
			text: t('banner'),
		},
		{
			icon: <PermMediaRounded sx={{ fontSize: 20 }} />,
			name: 'advertiser',
			text: t('advertiser'),
		},
		{
			icon: <InterestsRounded sx={{ fontSize: 20 }} />,
			name: 'interests',
			text: t('interests'),
		},
		{
			icon: <TrackChangesRounded sx={{ fontSize: 20 }} />,
			name: 'retarget',
			text: t('retarget'),
		},
		{
			icon: <MediationRounded sx={{ fontSize: 20 }} />,
			name: 'wrapper',
			text: t('wrapper'),
		},
	]

	const interests = [
		{ name: t('category'), id: 1 },
		{ name: t('advertiser'), id: 2 },
		{ name: t('banner'), id: 3 },
	]

	const updateTargetType = (rotation, item_id, val, key) =>
		rotation.map(i => {
			if (i.item_id === item_id) i[key] = val
			if (i.items) i.items = updateTargetType(i.items, item_id, val, key)
			return i
		})

	const handler = e => {
		const { value } = e.target
		updateTargetType(rotation.val, current.val.item_id, value, 'type')
		current.val.type = value
		setIndex(value)
	}

	const handlerChangerType = (val, val2) => {
		updateTargetType(rotation.val, current.val.item_id, val2, 'name')
		setName(val2)

		updateTargetType(rotation.val, current.val.item_id, val, 'content_id')
		current.val.content_id = val
		setContentId(val)
	}

	const handlerChangerName = val => {
		updateTargetType(rotation.val, current.val.item_id, val, 'name')
		current.val.name = val
		setName(val)
	}

	const handlerChangerSkip = val => {
		updateTargetType(rotation.val, current.val.item_id, val, 'skip')
		current.val.skip = val
		setSkip(val)
	}
	const handlerChangerPercent = val => {
		val = isNaN(val) ? 0 : val
		const allPercents = getAllPercentInCurrentLevel(
			rotation.val,
			current.val.item_id
		)

		const currentValue = allPercents + val <= 100 ? val : 100 - allPercents

		updateTargetType(rotation.val, current.val.item_id, currentValue, 'percent')
		current.val.percent = currentValue
		setPercent(currentValue)
	}

	const getAllPercentInCurrentLevel = (rotation, item_id) => {
		let allPercents = 0
		rotation.map(i => {
			if (i.item_id === item_id) {
				allPercents = rotation
					.filter(ff => ff.item_id !== item_id)
					.map(mm => mm.percent)
					.reduce((a, b) => a + b, 0)
				return allPercents
			}

			if (i.items && allPercents === 0) {
				allPercents = getAllPercentInCurrentLevel(i.items, item_id)
			}
			return i
		})

		return allPercents
	}

	const renderInputBox = (label, value, onChange, isNumber, max) => (
		<Box sx={{ display: 'flex', alignItems: 'center', gridGap: 10, flex: 1 }}>
			<Typography>{label}</Typography>
			{isNumber ? (
				<InputNumber
					value={value}
					onChange={onChange}
					min={0}
					max={max}
					style={{ width: '100%' }}
				/>
			) : (
				<Input defaultValue={value} onChange={onChange} />
			)}
		</Box>
	)

	return (
		<>
			<Box sx={{ p: '5px' }}>
				<RadioGroup
					aria-labelledby='storage-label'
					value={current.val.type}
					size='lg'
					sx={{ gap: 1.5, display: 'flex', flexDirection: 'row' }}
				>
					{types.map(value => {
						const isDisabled =
							['banner', 'advertiser', 'interests', 'wrapper'].includes(
								value.name
							) &&
							current.val.items.length &&
							['case', 'retarget'].includes(current.val.type)
								? true
								: false

						const onDisable = isDisabled ? { opacity: 0.3 } : {}

						return (
							<Sheet
								key={value.name}
								sx={{
									px: 2,
									py: 1,
									borderRadius: 'md',
									display: 'flex',
									flexDirection: 'row',
									...onDisable,
								}}
							>
								<Radio
									label={
										<>
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													gridGap: 6,
												}}
											>
												{value.icon} {value.text}
											</Box>
										</>
									}
									overlay
									disableIcon
									variant='soft'
									onChange={handler}
									disabled={isDisabled}
									size='sm'
									value={value.name}
									slotProps={{
										label: ({ checked }) => ({
											sx: {
												fontWeight: 'lg',
												fontSize: 'md',
												color: checked ? 'text.primary' : 'text.secondary',
											},
										}),
										action: ({ checked }) => ({
											sx: theme => ({
												...{ border: '2px solid transparent' },
												...(checked && {
													'--variant-borderWidth': '2px',
													'&&': {
														borderColor: theme.vars.palette.primary[500],
													},
												}),
											}),
										}),
									}}
								/>
							</Sheet>
						)
					})}
				</RadioGroup>
			</Box>
			<Box sx={{ my: 1 }}>
				<Sheet
					sx={theme => ({
						mt: '0',
						borderRadius: '8px',
						p: 2,
						gridGap: 20,
						display: 'flex',
						[theme.getColorSchemeSelector('light')]: {
							backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
						},
						[theme.getColorSchemeSelector('dark')]: {
							backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
						},
					})}
				>
					{index === 'case' &&
						renderInputBox(t('name'), name, e =>
							handlerChangerName(e.target.value)
						)}
					{index === 'interests' && (
						<>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gridGap: 10,
									flex: 1,
								}}
							>
								<Typography>{t('interest')}</Typography>
								<Select
									style={{ width: '100%' }}
									options={interests}
									fieldNames={{ label: 'name', value: 'id' }}
									value={contentId || ''}
									onChange={(_, val) => handlerChangerType(val.id, val.name)}
								/>
							</Box>
						</>
					)}
					{index === 'wrapper' &&
						renderInputBox(t('wrapper'), contentId, e => {
							const n = e && 'target' in e ? e.target.value : ''
							handlerChangerType(n, n)
						})}
					{index !== 'wrapper' &&
						renderInputBox(
							t('skip'),
							skip,
							e => handlerChangerSkip(Math.min(60, +e)),
							true,
							60
						)}
					{['case', 'banner', 'advertiser', 'retarget'].includes(index) &&
						renderInputBox(
							t('percent'),
							percent,
							e => handlerChangerPercent(Math.min(100, +e)),
							true,
							100
						)}
				</Sheet>
			</Box>

			{index === 'advertiser' && (
				<TreeView
					items={campaignsDb}
					multiSelect={false}
					selectedKey={[...[current.val.content_id]]}
					onSelect={(e, meta) => {
						if (meta.advertiserName) {
							const n = `[${meta.advertiserName}] ${meta.campaignName}`
							handlerChangerType(meta.key, n)
						}
					}}
				/>
			)}

			{index === 'banner' && (
				<TreeView
					items={bannersDb}
					multiSelect={false}
					selectedKey={[...[current.val.content_id]]}
					onSelect={(e, meta) => {
						if (meta.campaignName) {
							const n = `[${meta.campaignName}] ${meta.bannerName}`
							handlerChangerType(meta.key, n)
						}
					}}
				/>
			)}

			{index === 'retarget' && (
				<TreeView
					items={retargetDb}
					multiSelect={false}
					selectedKey={[...[current.val.content_id]]}
					onSelect={(e, meta) => {
						if (meta.retargetName) {
							handlerChangerType(meta.key, meta.retargetName)
						}
					}}
				/>
			)}

			<Divider sx={{ my: 1 }} />
			<TargetsTabs rotation={rotation} current={current} type={index} />
		</>
	)
}

export default CaseTypes
