import React, { useState, useEffect, useCallback } from 'react'
import { Box } from '@mui/joy'
import { useSelector, useDispatch } from 'react-redux'
import { rotationTarget } from '../../../../../../store/rotations'
import { useFilter } from '../../../../../hooks/filter'
import TreeView from '../../../../../layout/TreeView'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const Advertisers = () => {
	const { updateFilter } = useFilter()
	const dispatch = useDispatch()
	const rows = useSelector(state => state.rotations.target.rows?.banners)

	const [advertisers, setAdvertisers] = useState([])
	const [campaigns, setCampaigns] = useState([])
	const [banners, setBanners] = useState([])

	useEffect(() => {
		if (!rows) {
			dispatch(rotationTarget({ advertiser: 1, template_id: 0 }))
		}
	}, [dispatch, rows])

	const handleIncludeExclude = useCallback(
		(action, type, items) => {
			if (items.length) {
				updateFilter(
					`${action}_${type}`,
					items.filter(item => item.value)
				)
			}
		},
		[updateFilter]
	)

	const handleSelectionChange = useCallback((_, meta) => {
		if (!meta) return

		const newAdvertisers = []
		const newCampaigns = []
		const newBanners = []

		meta.forEach(item => {
			const newItem = {
				name: item.bannerName || item.name || item.campaignName,
				value: item.key || item.advertiserId,
			}

			if (item.type === 'advertisers') newAdvertisers.push(newItem)
			else if (item.type === 'campaigns') newCampaigns.push(newItem)
			else if (item.type === 'banners') newBanners.push(newItem)
		})

		if (newAdvertisers.length) setAdvertisers(newAdvertisers)
		else if (newCampaigns.length) setCampaigns(newCampaigns)
		else if (newBanners.length) setBanners(newBanners)
	}, [])

	return (
		<>
			<TreeView
				items={rows}
				multiSelect={true}
				selectedBranch={true}
				onSelect={handleSelectionChange}
			/>
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					gap: '10px',
					justifyContent: 'end',
				}}
			>
				<IncludeExcludeBtn
					include={() => {
						handleIncludeExclude('in', 'advertiser', advertisers)
						handleIncludeExclude('in', 'campaign', campaigns)
						handleIncludeExclude('in', 'banner', banners)
					}}
					exclude={() => {
						handleIncludeExclude('ex', 'advertiser', advertisers)
						handleIncludeExclude('ex', 'campaign', campaigns)
						handleIncludeExclude('ex', 'banner', banners)
					}}
				/>
			</Box>
		</>
	)
}

export default Advertisers
