import React from 'react'

import FormContainer from './FormContainer'

import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'

import { toast } from '../../../../../layout/JoyToaster'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../../../store'
import { zoneEdit } from '../../../../../../store/zones'
import { useZone } from '../../../../../hooks/zones'

const BaseInformation = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const { zone } = useZone()
	const [, setCookie] = useCookies(['reload'])
	const pageId = parseInt(useParams().pageId)

	const handlerForm = event => {
		event.preventDefault()

		dispatch(
			zoneEdit({
				publisher_id: pageId,
				id: zone.id,
				active: zone.active,
				rotation_id: zone.rotation_id,
				name: zone.name,
			})
		).then(result => {
			result
				? toast.success(t('edit_zone_successfully'))
				: toast.error(t('error'))

			setCookie('reload', new Date().getTime(), { path: '/' })
		})
	}

	return (
		<form onSubmit={handlerForm}>
			<FormContainer />
		</form>
	)
}

export default BaseInformation
