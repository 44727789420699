import * as React from 'react'

import GlobalStyles from '@mui/joy/GlobalStyles'
import CssBaseline from '@mui/joy/CssBaseline'

const CssBase = () => (
	<>
		<GlobalStyles
			styles={{
				':root': {
					'--Topbar-height': '75px',
					'--Sidebar-width': '65px',
					// '--joy-palette-common-black': 'rgb(16, 20, 24)',
					// '--joy-palette-primary-800': 'rgb(16, 20, 24)',
					// '--joy-palette-divider': 'rgb(59, 74, 89, 0.2)',
				},
				'.MuiAccordionDetails-content:not(.Mui-expanded)': {
					paddingBlock: '0px!important',
				},
				'span.react-resizable-handle': {
					height: '10px',
					width: '25px',
					display: 'flex',
					alignSelf: 'center',
					borderBottom: '1px solid',
					cursor: 'ns-resize',
				},
				'ul.tree': {
					paddingRight: '10px',
					// maxHeight: '160px',
					overflowY: 'auto',
				},
				'ul.tree > li > div:before': {
					display: 'none',
				},
				'ul.tree, ul.tree-node-group': {
					listStyle: 'none',
					paddingLeft: '10px',
					// paddingRight: '10px',
					width: 'inherit',
					flex: 1,
				},
				'ul.tree-node-group:hover': {
					borderColor:
						'var(--variant-solidBg, var(--joy-palette-primary-solidBg, var(--joy-palette-primary-500, #0B6BCB)))',
				},
				'ul.tree-node-group': {
					listStyle: 'none',
					paddingLeft: '12px',
					marginLeft: '15px',
					borderLeftWidth: '1px',
					borderLeftStyle: 'dashed',
					borderColor: 'var(--joy-palette-divider)',
				},

				'ul.tree-node-group li': {
					position: 'relative',
				},
				'.react-datepicker-wrapper:first-child .react-datepicker__input-container input[type="text"]':
					{
						marginRight: 10,
					},
				'.react-datepicker__input-container input[type="text"]': {
					border: '1px solid var(--joy-palette-divider)',
					fontFamily: 'var(--joy-fontFamily-body)',
					borderRadius: 'var(--ListItem-radius)',

					lineHeight: '30px',
					padding: '0 10px',
					width: '110px',
					background: 'transparent',
					outline: 'none',
					opacity: 0.5,
				},

				'.react-datepicker__input-container input[type="text"]:hover, .react-datepicker__input-container input[type="text"]:focus, .react-datepicker__input-container input[type="text"]:active':
					{
						opacity: 1,
					},

				'.tooltip-custom': {
					background: 'var(--joy-palette-background-surface)',
					borderRadius: '8px',
					color: 'var(--joy-palette-text-primary)',
					opacity: 1,
					pointerEvents: 'none',
					position: 'absolute',
					transform: 'translate(-50%, 0)',
					transition: 'all .1s ease',
					border: '1px solid var(--joy-palette-divider)',
				},

				'.active-line': {
					background:
						'var(--TableRow-hoverBackground, var(--joy-palette-background-level2))',
				},

				'.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before':
					{
						borderColor: 'var(--joy-palette-text-primary)',
					},
				'.react-datepicker-popper': {
					zIndex: 9,
				},
				'.react-datepicker-wrapper': {
					marginRight: '16px',
				},
				'.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before':
					{
						borderBottomColor:
							'var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-200, #D8D8DF))',
					},
				'.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after':
					{
						borderBottomColor:
							'var(--joy-palette-primary-softBg, var(--joy-palette-primary-100, #DDF1FF))',
					},
				'.react-datepicker__header': {
					background:
						'var(--joy-palette-primary-softBg, var(--joy-palette-primary-100, #DDF1FF))',
					borderBottomColor:
						'var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-200, #D8D8DF))',
				},
				'.react-datepicker__header:not(.react-datepicker__header--has-time-select)':
					{
						borderTopLeftRadius: '8px',
						borderTopRightRadius: '8px',
					},

				'.react-datepicker__current-month': {
					color: 'var(--joy-palette-text-primary)',
				},

				'.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name':
					{
						color: 'var(--joy-palette-text-primary)',
					},

				'.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected, .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range,.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover':
					{
						background:
							'var(--joy-palette-primary-softBg, var(--joy-palette-primary-100, #DDF1FF))',
						borderRadius: '2px',
						color: 'var(--joy-palette-text-primary)',
					},

				'.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled':
					{
						color: 'var(--joy-palette-divider)',
					},

				'.react-datepicker': {
					borderRadius: '8px',
					border:
						'1px solid var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-200, #D8D8DF))',
					background: 'var(--joy-palette-background-surface)',
				},
				'.nestable-drag-layer': {
					display: 'none',
				},
				'.nestable > ol.nestable-list > li > div > ul :before, .nestable > ol.nestable-list > li > div > ul :after':
					{
						display: 'none',
					},
				'.nestable-item-name:hover ~ ol.nestable-list, .nestable-item-name:hover ~ ol.nestable-list > li > div > ul :before':
					{
						borderColor:
							'var(--variant-solidBg, var(--joy-palette-primary-solidBg, var(--joy-palette-primary-500, #0B6BCB)))',
					},
				'.nestable-item-name:hover ~ ol.nestable-list > li > div > ul :after': {
					backgroundColor:
						'var(--variant-solidBg, var(--joy-palette-primary-solidBg, var(--joy-palette-primary-500, #0B6BCB)))',
				},
				'.splitter-report-container': {
					height: 'calc(calc(100vh - var(--Topbar-height)) - 40px)',
					position: 'relative',
				},
				'.splitter-report-container .layout-splitter': {
					margin: '0',
				},
				'.splitter-main-container': {
					height: 'calc(100dvh - var(--Topbar-height))',
					width: 'calc(100% - var(--Sidebar-width))',
				},
				'.splitter-main-container > .layout-pane:first-child': {
					minWidth: 'clamp(280px, 25dvw, 250px)',
					// maxWidth: '550px',
				},
				'.splitter-layout .layout-splitter': {
					backgroundColor: 'var(--joy-palette-divider)',
				},
				'.splitter-layout .layout-splitter:hover': {
					backgroundColor:
						'var(--variant-softBg, var(--joy-palette-primary-softBg, var(--joy-palette-primary-100, #E3EFFB)))',
				},
				'.ant-select-dropdown, .ant-picker-dropdown': { zIndex: 9999 },
				'.delete-btn-retarget': {
					visibility: 'hidden',
					opacity: 0,
				},
				'.ant-popover.ant-popconfirm': { maxWidth: '300px' },
				'.delete-container-retarget:hover .delete-btn-retarget': {
					opacity: 1,
					visibility: 'visible',
				},
				'.custom-pagination-font-size .ant-pagination-next': {
					marginRight: '4px',
				},
				'.custom-pagination-font-size .ant-pagination-item-link .anticon-right, .custom-pagination-font-size .ant-pagination-item-link .anticon-left':
					{
						width: '8px',
					},
				'.custom-pagination-font-size .ant-pagination-item-link': {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				},
				'.custom-pagination-font-size, .custom-pagination-font-size .ant-pagination-item-link, .custom-pagination-font-size .ant-select-selection-item':
					{
						fontSize: '12px',
					},

				'html[data-joy-color-scheme="dark"]': {
					// '--joy-palette-divider': 'rgba(19 19 24 / 1)',
					'--joy-palette-neutral-plainHoverBg':
						'var(--variant-outlinedHoverBg, var(--joy-palette-primary-outlinedHoverBg, var(--joy-palette-primary-100, #E3EFFB)))',
					'--joy-palette-neutral-outlinedHoverBg': 'transparent',
					'--variant-outlinedBg': 'initial',
					'--variant-solidHoverColor': '#fff',
					'--joy-palette-primary-50': '#F4FAFF',
					'--joy-palette-primary-100': '#DDF1FF',
					'--joy-palette-primary-200': '#ADDBFF',
					'--joy-palette-primary-300': '#6FB6FF',
					'--joy-palette-primary-400': '#3990FF',
					'--joy-palette-primary-500': '#096BDE',
					'--joy-palette-primary-600': '#054DA7',
					'--joy-palette-primary-700': '#02367D',
					'--joy-palette-primary-800': '#072859',
					'--joy-palette-primary-900': '#00153C',
					'--joy-palette-primary-plainColor':
						'var(--joy-palette-primary-300, #6FB6FF)',
					'--joy-palette-primary-plainHoverBg':
						'var(--joy-palette-primary-800, #072859)',
					'--joy-palette-primary-plainActiveBg':
						'var(--joy-palette-primary-700, #02367D)',
					'--joy-palette-primary-plainDisabledColor':
						'var(--joy-palette-primary-800, #072859)',
					'--joy-palette-primary-outlinedColor':
						'var(--joy-palette-primary-200, #ADDBFF)',
					'--joy-palette-primary-outlinedBorder':
						'var(--joy-palette-primary-700, #02367D)',
					'--joy-palette-primary-outlinedHoverBg':
						'var(--joy-palette-primary-800, #072859)',
					'--joy-palette-primary-outlinedHoverBorder':
						'var(--joy-palette-primary-600, #054DA7)',
					'--joy-palette-primary-outlinedActiveBg':
						'var(--joy-palette-primary-900, #00153C)',
					'--joy-palette-primary-outlinedDisabledColor':
						'var(--joy-palette-primary-800, #072859)',
					'--joy-palette-primary-outlinedDisabledBorder':
						'var(--joy-palette-primary-800, #072859)',
					'--joy-palette-primary-softColor':
						'var(--joy-palette-primary-200, #ADDBFF)',
					'--joy-palette-primary-softBg':
						'var(--joy-palette-primary-900, #00153C)',
					'--joy-palette-primary-softHoverBg':
						'var(--joy-palette-primary-800, #072859)',
					'--joy-palette-primary-softActiveBg':
						'var(--joy-palette-primary-700, #02367D)',
					'--joy-palette-primary-softDisabledColor':
						'var(--joy-palette-primary-800, #072859)',
					'--joy-palette-primary-softDisabledBg':
						'var(--joy-palette-primary-900, #00153C)',
					'--joy-palette-primary-solidColor': '#fff',
					'--joy-palette-primary-solidBg':
						'var(--joy-palette-primary-600, #054DA7)',
					'--joy-palette-primary-solidHoverBg':
						'var(--joy-palette-primary-700, #02367D)',
					'--joy-palette-primary-solidActiveBg':
						'var(--joy-palette-primary-800, #072859)',
					'--joy-palette-primary-solidDisabledColor':
						'var(--joy-palette-primary-700, #02367D)',
					'--joy-palette-primary-solidDisabledBg':
						'var(--joy-palette-primary-900, #00153C)',
					'--joy-palette-primary-mainChannel': '57 144 255',
					'--joy-palette-primary-lightChannel': '173 219 255',
					'--joy-palette-primary-darkChannel': '7 40 89',
					'--joy-palette-neutral-50': '#F7F7F8',
					'--joy-palette-neutral-100': '#EBEBEF',
					'--joy-palette-neutral-200': '#D8D8DF',
					'--joy-palette-neutral-300': '#B9B9C6',
					'--joy-palette-neutral-400': '#8F8FA3',
					'--joy-palette-neutral-500': '#73738C',
					'--joy-palette-neutral-600': '#5A5A72',
					'--joy-palette-neutral-700': '#434356',
					'--joy-palette-neutral-800': '#25252D',
					'--joy-palette-neutral-900': '#131318',
					'--joy-palette-neutral-plainColor':
						'var(--joy-palette-neutral-200, #D8D8DF)',
					'--joy-palette-neutral-plainHoverColor':
						'var(--joy-palette-neutral-50, #F7F7F8)',
					'--joy-palette-neutral-plainActiveBg':
						'var(--joy-palette-neutral-700, #EBEBEF)',
					'--joy-palette-neutral-plainDisabledColor':
						'var(--joy-palette-neutral-200, #D8D8DF)',
					'--joy-palette-neutral-outlinedColor':
						'var(--joy-palette-neutral-300, #B9B9C6)',
					'--joy-palette-neutral-outlinedBorder':
						'var(--joy-palette-neutral-800, #73738C)',
					// '--joy-palette-neutral-outlinedHoverBg':
					// 	'var(--joy-palette-neutral-800, #25252D)',
					'--joy-palette-neutral-outlinedHoverBorder':
						'var(--joy-palette-neutral-600, #5A5A72)',
					'--joy-palette-neutral-outlinedActiveBg':
						'var(--joy-palette-neutral-900, #131318)',
					'--joy-palette-neutral-outlinedDisabledColor':
						'var(--joy-palette-neutral-300, #B9B9C6)',
					'--joy-palette-neutral-outlinedDisabledBorder':
						'var(--joy-palette-neutral-500, #73738C)',
					'--joy-palette-neutral-softColor':
						'var(--joy-palette-neutral-200, #D8D8DF)',
					'--joy-palette-neutral-softBg':
						'var(--joy-palette-neutral-800, #25252D)',
					'--joy-palette-neutral-softHoverBg':
						'var(--joy-palette-neutral-700, #434356)',
					'--joy-palette-neutral-softActiveBg':
						'var(--joy-palette-neutral-600, #5A5A72)',
					'--joy-palette-neutral-softDisabledColor':
						'var(--joy-palette-neutral-300, #B9B9C6)',
					'--joy-palette-neutral-softDisabledBg':
						'var(--joy-palette-neutral-800, #25252D)',
					'--joy-palette-neutral-solidColor': '#fff',
					'--joy-palette-neutral-solidBg':
						'var(--joy-palette-neutral-500, #73738C)',
					'--joy-palette-neutral-solidHoverBg':
						'var(--joy-palette-neutral-600, #5A5A72)',
					'--joy-palette-neutral-solidActiveBg':
						'var(--joy-palette-neutral-700, #434356)',
					'--joy-palette-neutral-solidDisabledColor':
						'var(--joy-palette-neutral-300, #B9B9C6)',
					'--joy-palette-neutral-solidDisabledBg':
						'var(--joy-palette-neutral-800, #25252D)',
					'--joy-palette-neutral-mainChannel': '115 115 140',
					'--joy-palette-neutral-lightChannel': '216 216 223',
					'--joy-palette-neutral-darkChannel': '19 19 24',
					'--joy-palette-danger-50': '#FFF0F1',
					'--joy-palette-danger-100': '#FFE4E6',
					'--joy-palette-danger-200': '#FFB8BC',
					'--joy-palette-danger-300': '#FF8A93',
					'--joy-palette-danger-400': '#FF5C6C',
					'--joy-palette-danger-500': '#E31920',
					'--joy-palette-danger-600': '#B21016',
					'--joy-palette-danger-700': '#820B10',
					'--joy-palette-danger-800': '#59080B',
					'--joy-palette-danger-900': '#330406',
					'--joy-palette-danger-plainColor':
						'var(--joy-palette-danger-50, #FFF0F1)',
					'--joy-palette-danger-plainHoverBg':
						'var(--joy-palette-danger-800, #59080B)',
					'--joy-palette-danger-plainActiveBg':
						'var(--joy-palette-danger-700, #820B10)',
					'--joy-palette-danger-plainDisabledColor':
						'var(--joy-palette-danger-800, #59080B)',
					'--joy-palette-danger-outlinedColor':
						'var(--joy-palette-danger-200, #FFB8BC)',
					'--joy-palette-danger-outlinedBorder':
						'var(--joy-palette-danger-700, #820B10)',
					'--joy-palette-danger-outlinedHoverBg':
						'var(--joy-palette-danger-800, #59080B)',
					'--joy-palette-danger-outlinedHoverBorder':
						'var(--joy-palette-danger-600, #B21016)',
					'--joy-palette-danger-outlinedActiveBg':
						'var(--joy-palette-danger-900, #330406)',
					'--joy-palette-danger-outlinedDisabledColor':
						'var(--joy-palette-danger-800, #59080B)',
					'--joy-palette-danger-outlinedDisabledBorder':
						'var(--joy-palette-danger-800, #59080B)',
					'--joy-palette-danger-softColor':
						'var(--joy-palette-danger-200, #FFB8BC)',
					'--joy-palette-danger-softBg':
						'var(--joy-palette-danger-900, #330406)',
					'--joy-palette-danger-softHoverBg':
						'var(--joy-palette-danger-800, #59080B)',
					'--joy-palette-danger-softActiveBg':
						'var(--joy-palette-danger-700, #820B10)',
					'--joy-palette-danger-softDisabledColor':
						'var(--joy-palette-danger-800, #59080B)',
					'--joy-palette-danger-softDisabledBg':
						'var(--joy-palette-danger-900, #330406)',
					'--joy-palette-danger-solidColor': '#fff',
					'--joy-palette-danger-solidBg':
						'var(--joy-palette-danger-600, #B21016)',
					'--joy-palette-danger-solidHoverBg':
						'var(--joy-palette-danger-700, #820B10)',
					'--joy-palette-danger-solidActiveBg':
						'var(--joy-palette-danger-800, #59080B)',
					'--joy-palette-danger-solidDisabledColor':
						'var(--joy-palette-danger-700, #820B10)',
					'--joy-palette-danger-solidDisabledBg':
						'var(--joy-palette-danger-900, #330406)',
					'--joy-palette-danger-mainChannel': '227 25 32',
					'--joy-palette-danger-lightChannel': '255 180 188',
					'--joy-palette-danger-darkChannel': '51 4 6',
				},
			}}
		/>
		<CssBaseline />
	</>
)

export default CssBase
