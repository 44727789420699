import React, { useState } from 'react'
import { Box, Sheet } from '@mui/joy'
import { Select, Tag, Space } from 'antd'
import { renderChip } from '../../../../../handlers/Chips'
import { useTranslation } from 'react-i18next'
import { updateItem } from '../utils'
import { useSelector } from 'react-redux'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

const DeviceVendor = ({ rotation, current }) => {
	const { t } = useTranslation()

	const [value, setValue] = useState([])
	const rows = useSelector(state => state.rotations.target.rows?.vendor)
	const items = rows
		? Object.values(rows).map(i => ({ label: i, value: i, key: i }))
		: []

	const handleIncludeExclude = target_name => {
		updateItem({ rotation, current, value, target_name })
	}

	return (
		<>
			<Sheet
				sx={theme => ({
					mt: '0',
					borderRadius: '8px',
					p: 2,
					display: 'flex',
					[theme.getColorSchemeSelector('light')]: {
						backgroundColor: 'var(--joy-palette-neutral-100, #F0F4F8)',
					},
					[theme.getColorSchemeSelector('dark')]: {
						backgroundColor: 'var(--joy-palette-neutral-800, #171A1C)',
					},
				})}
			>
				<Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							flex: 1,
							gridGap: 10,
							alignItems: 'center',
						}}
					>
						<Select
							placeholder={t('target_1_device_vendor')}
							maxTagCount={'responsive'}
							allowClear
							mode='multiple'
							style={{ width: '100%' }}
							options={items}
							defaultValue={value}
							tagRender={({ label, value, closable, onClose }) => {
								const onPreventMouseDown = event => {
									event.preventDefault()
									event.stopPropagation()
								}

								return (
									<Tag
										key={`${label}-${value}`}
										onMouseDown={onPreventMouseDown}
										closable={closable}
										onClose={onClose}
										style={{
											marginInlineEnd: 4,
											display: 'flex',
										}}
									>
										<Space style={{ gridGap: '4px', mr: '4px' }}>{label}</Space>
									</Tag>
								)
							}}
							onChange={(_, newValue) => {
								setValue(newValue)
							}}
						/>
					</Box>
					<IncludeExcludeBtn
						include={() => {
							handleIncludeExclude('vendor')
						}}
						exclude={() => {
							handleIncludeExclude('ex_vendor')
						}}
					/>
				</Box>
			</Sheet>
			{renderChip({
				current: current,
				rotation: rotation,
				type: 'vendor',
				label: i => i,
			})}
		</>
	)
}

export default DeviceVendor
