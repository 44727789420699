import React, { useEffect, useState } from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Input, Select } from 'antd'
import { Add, BookmarksRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../store'
import { getAllAdvertisers } from '../../../../store/advertisers'

import FormItem from '../../../layout/FormItem'

const Basic = ({ values }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [index, setIndex] = useState(0)
	const { arr, set } = values

	const rows = useSelector(state => state.advertisers.getAllData.rows)
	const isLoading = useSelector(state => state.advertisers.getAllData.isLoading)

	useEffect(() => {
		dispatch(
			getAllAdvertisers({
				active: 'all',
				category: 0,
			})
		)
	}, [dispatch])

	return (
		<Accordion
			sx={{ borderBottom: 0 }}
			expanded={index === 0}
			onChange={(_, expanded) => {
				setIndex(expanded ? 0 : null)
			}}
		>
			<AccordionSummary indicator={<Add />} onClick={e => e.preventDefault()}>
				<BookmarksRounded />
				<Box sx={{ flex: 1, fontSize: '13px' }}>{t('add_category_basic')}</Box>
			</AccordionSummary>

			<AccordionDetails>
				<FormItem
					text={'name'}
					component={
						<Input
							style={{ width: '100%' }}
							max={180}
							onChange={e => {
								const { value: name } = e.target
								set({
									...arr,
									...{ name },
								})
							}}
						/>
					}
				/>
				<FormItem
					text={'advertiser'}
					component={
						<Select
							maxTagCount={'responsive'}
							allowClear
							style={{ width: '100%' }}
							loading={!!isLoading}
							fieldNames={{ label: 'name', value: 'advertiser_id' }}
							options={rows}
							onChange={advertiser_id => {
								set({
									...arr,
									...{ advertiser_id },
								})
							}}
						/>
					}
				/>
			</AccordionDetails>
		</Accordion>
	)
}

export default Basic
