import React from 'react'
import { Card, CardContent, Typography } from '@mui/joy'

const CustomCard = ({ value, title }) => {
	return (
		<Card
			sx={{
				flex: 1,
				pl: 3,
				borderRadius: 0,
				borderLeft: 'none',
				borderTop: 'none',
			}}
			variant='outlined'
		>
			<CardContent
				orientation='horizontal'
				sx={{
					alignItems: 'center',
					gap: 3,
				}}
			>
				<CardContent>
					<Typography level='body-md'>{title}</Typography>
					<Typography level='h3'>{value}</Typography>
				</CardContent>
			</CardContent>
		</Card>
	)
}

export default CustomCard
