import React, { useState } from 'react'

import { Box } from '@mui/joy'
import { Select, Tag, Space } from 'antd'
import IncludeExcludeBtn from '../../../../../handlers/IncludeExcludeBtn'

import { useFilter } from '../../../../../hooks/filter'
import { useTranslation } from 'react-i18next'

const DeviceFormat = () => {
	const { t } = useTranslation()
	const { updateFilter } = useFilter()
	const [value, setValue] = useState({})
	const deviceText = t('device', { returnObjects: true })

	const include = () => updateFilter('in_device_format', value)
	const exclude = () => updateFilter('ex_device_format', value)

	const deviceFormat = [
		{ value: deviceText['_1'], key: '1' },
		{ value: deviceText['_2'], key: '2' },
		{ value: deviceText['_3'], key: '3' },
		{ value: deviceText['_4'], key: '4' },
		{ value: deviceText['_5'], key: '5' },
		{ value: deviceText['_6'], key: '6' },
		{ value: deviceText['_7'], key: '7' },
		{ value: deviceText['_8'], key: '8' },
	]

	return (
		<>
			<Box sx={{ display: 'flex', width: '100%', gridGap: '10px' }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						flex: 1,
						gridGap: 10,
						alignItems: 'center',
					}}
				>
					<Select
						placeholder={t('target_1_device')}
						maxTagCount={'responsive'}
						allowClear
						mode='multiple'
						style={{ width: '100%' }}
						defaultValue={value.value}
						options={deviceFormat}
						fieldNames={{ label: 'value', value: 'key' }}
						tagRender={({ label, value, closable, onClose }) => {
							const onPreventMouseDown = event => {
								event.preventDefault()
								event.stopPropagation()
							}

							return (
								<Tag
									key={`${label}-${value}`}
									onMouseDown={onPreventMouseDown}
									closable={closable}
									onClose={onClose}
									style={{
										marginInlineEnd: 4,
										display: 'flex',
									}}
								>
									<Space style={{ gridGap: '4px', mr: '4px' }}>{label}</Space>
								</Tag>
							)
						}}
						onChange={newValue => {
							setValue(
								newValue.map(val => ({
									value: val,
								}))
							)
						}}
					/>
				</Box>

				<IncludeExcludeBtn include={include} exclude={exclude} />
			</Box>
		</>
	)
}

export default DeviceFormat
