import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import { ITemplatesAddRequest } from '../../api/templates/add/types'
import { ITemplatesEditRequest } from '../../api/templates/edit/types'
import { ITemplatesRemoveRequest } from '../../api/templates/delete/types'
import { ITemplatesGetRequest } from '../../api/templates/get/types'

import {
	templateAddStart,
	templateAddSuccess,
	templateAddFailure,
	templateGetAllStart,
	templateGetAllSuccess,
	templateGetAllFailure,
	templateEditStart,
	templateEditSuccess,
	templateEditFailure,
	templateDeleteStart,
	templateDeleteSuccess,
	templateDeleteFailure,
	templateGetStart,
	templateGetSuccess,
	templateGetFailure,
} from './reducer'

export const templateAdd =
	(data: ITemplatesAddRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(templateAddStart())
			const result = await api.templatesAdd.create(data)
			dispatch(templateAddSuccess({ result: result.data }))
			return true
		} catch (e: any) {
			dispatch(templateAddFailure(e.message))
			return false
		}
	}

export const templateEdit =
	(data: ITemplatesEditRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(templateEditStart())
			await api.templatesEdit.update(data)
			dispatch(templateEditSuccess())
			return true
		} catch (e: any) {
			dispatch(templateEditFailure(e.message))
			return false
		}
	}

export const templateDel =
	(data: ITemplatesRemoveRequest) =>
	async (dispatch: Dispatch<any>): Promise<boolean> => {
		try {
			dispatch(templateDeleteStart())
			await api.templatesDel.remove(data)
			dispatch(templateDeleteSuccess())
			return true
		} catch (e: any) {
			dispatch(templateDeleteFailure(e.message))
			return false
		}
	}

export const templateGet =
	(data: ITemplatesGetRequest) =>
	async (dispatch: Dispatch<any>): Promise<any> => {
		try {
			dispatch(templateGetStart())
			const result = await api.templatesGet.get(data)
			dispatch(templateGetSuccess({ result: result.data }))
			return result.data
		} catch (e: any) {
			dispatch(templateGetFailure(e.message))
			return false
		}
	}

export const templateGetAll =
	() =>
	async (dispatch: Dispatch<any>): Promise<void> => {
		try {
			dispatch(templateGetAllStart())
			const result = await api.templatesGet.getAll()
			dispatch(templateGetAllSuccess({ result: result.data }))
		} catch (e: any) {
			dispatch(templateGetAllFailure(e.message))
		}
	}
